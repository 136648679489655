/*
React class for rendering a Score object.
*/

import React, { useLayoutEffect } from "react";
import { ScoreFactory } from "../core/score-factory";
import Score from "../core/score";

const DEFAULT_OPTIONS = {
  type: "simple",
  isGrandStaff: false,
  metrics: {
    width: 800
  },
  showDynamics: false,
  showStaveConnectors: true
};

const ScoreComponent = (props) => {

  let { data, init, postRender, id, options, forwardedRef: scoreRef } = props;

  useLayoutEffect(() => {
    if (scoreRef.current) {
      updateScore();
    }
    else {
      scoreRef.current = createScore();
    }
  }, [data]);

  const createScore = () => {
    //console.log("Creating #"+id);
    let el = document.querySelector("#"+id);
    let scoreFactory = new ScoreFactory();
    let score = scoreFactory.createScore({
      el: el,
      data: data,
      ...DEFAULT_OPTIONS,
      ...options
    });
    init && init(score);
    score.render();
    postRender && postRender(score);
    return score;
  }

  const updateScore = () => {
    //console.log("Updating #"+id);
    let { current: score } = scoreRef;
    score.data = data;
    score.systems = [];
    Score.build(score).render({
      clear: true
    });
  }

  return (
    <div className="score" id={id} onClick={props.onClick}></div>
  );
  
}

export default ScoreComponent;