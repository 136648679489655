/*
Notation Selector Component.
*/

import React, { Fragment, useState, useContext } from "react";
import classNames from 'classnames';
import { Utils } from "../utils";
import { Tables } from "../tables";
import Select from "./widgets/select";
import FlatButton from './widgets/flat-button';
import { Box, Paper, Grid, Popper, ClickAwayListener, Container } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from '@material-ui/core/styles';
import { Mobile, NotMobile } from "./hooks/use-media-query";
import { useSelector } from "react-redux";
import { MainMenuContext } from "./app";


const styles = ({ palette }) => ({
  h5: {
    fontSize: "1rem",
    fontWeight: 400,
    letterSpacing: ".04em",
    lineHeight: 1.5,
    fontFamily: "inherit",
    color: "rgba(0,0,0,0.87)"
  },
  notationTypeSelectorHeader: {
    padding: ".5rem",
  },
  prevNextAlphabetBtn: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: ".59375rem",
    paddingBottom: ".59375rem",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    //marginLeft: "-20px",
    //marginRight: "-20px",
  },
  col: {
    flexBasis: 0,
    flexGrow: 1,
    position: "relative",
    maxWidth: "100%",
    textAlign: "center",
    cursor: "default",
    fontSize: ".8125rem",
    fontWeight: 400,
    height: "3rem",
    paddingBottom: ".919643rem",
    paddingTop: ".919643rem",
  },
  notationTypeTableCell: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  notationTypeTableCellSelected: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  notationTypeSelectContainer: {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
  },
  notationTypeSelect: {
    minWidth: 22,
    width: "100%",
    minHeight: "auto",
    height: "auto",
    boxSizing: "content-box",
    backgroundColor: "#f5f5f5",
    color: "rgba(0,0,0,.87)",
    //fontSize: ".875rem",
    fontWeight: 500,
    lineHeight: "14px",
    textAlign: "start",
    cursor: "pointer",
    paddingLeft: '16px',
    paddingRight: '36px',
    paddingTop: '11px',
    paddingBottom: '11px',
    border: 0,
    borderRadius: "2px",
    boxShadow: "0 0 4px 0 rgba(0,0,0,.14),0 3px 4px 0 rgba(0,0,0,.12),0 1px 5px 0 rgba(0,0,0,.2)",
    //overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  expandMoreLessIcon: {
    right: "12px",
    overflow: "hidden",
    textAlign: "start",
    position: "absolute",
    display: "inline-block",
    color: "rgba(0,0,0,.87)",
    pointerEvents: "none",
  },
  notationSelector: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: "auto !important",
    marginBottom: 0,
    paddingLeft: 0,
    listStyle: "none",
  }
});

const useStyles = makeStyles(styles);


const ALPHABETS = ["A-Z", "AA-AZ"]; //["A-Z", "AA-AZ", "BA-BZ", "CA-CF"];

const NOTATION_TYPE_GRID_WIDTH = 7;

const buildGridRows = (labels, type, handleOnClick) => {
  const classes = useStyles();
  const rows = [];
  for (let i = 0; i < labels.length; i += NOTATION_TYPE_GRID_WIDTH) {
    let slice = labels.slice(i, i + NOTATION_TYPE_GRID_WIDTH);
    let cells = slice.map(label => {
      let span = <span className={ classes.col }></span>;
      if ( !(label===null) ) {
        const _classNames = classNames(classes.col, classes.notationTypeTableCell, label===type && classes.notationTypeTableCellSelected);
        span = <span onClick={ handleOnClick } className={ _classNames }><a>{ label }</a></span>;
      }
      return span;
    });
    let row = <div className={ classes.row }>{ cells }</div>;
    rows.push(row);
  }
  return rows;
}

const buildNotationTypeGrid = (lChar, end, type, handleOnClick) => {
  var labels = [];
  for (let i = 0; i < Utils.ceil(end, NOTATION_TYPE_GRID_WIDTH); i++) {
    if (i < end) {
      var rCharCode = i + 65;
      var rChar = String.fromCharCode(rCharCode);
      var label = lChar + rChar;
      labels.push(label);
    }
    else {
      labels.push(null);
    }
  }
  return buildGridRows(labels, type, handleOnClick);
}

const NotationTypeSelectorButton = (props) => {

  const { type, clicked, onClick } = props;
  const classes = useStyles();

  return (
    <div className={ classes.notationTypeSelectContainer } onClick={onClick}>
      <div className={ classes.notationTypeSelect }>{ type }</div>
      { clicked
        ? <ExpandLessIcon className={ classes.expandMoreLessIcon }/>
        : <ExpandMoreIcon className={ classes.expandMoreLessIcon }/>
      }
    </div>
  );

}

const NotationTypeControl = (props) => {
  let { type } =  props;
  let { handleCellOnClick, ...restProps } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  //const [btnTitle, setBtnTitle] = useState("A");

  const handleTogglePopper = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const open = Boolean(anchorEl);

  const _handleCellOnClick = e => {
    props.handleCellOnClick(e);
    setAnchorEl(null);
  };

  const handleClickAway = (e) => {
    setAnchorEl(null)
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className="mr-2">
        <label htmlFor="notation-type" className="mr-2">Notation:</label>
        <NotationTypeSelectorButton type={type} onClick={handleTogglePopper} clicked={Boolean(anchorEl)}/>
        <Popper
          id="notation-type-dropdown-popper"
          open={open}
          placement="bottom-start"
          anchorEl={anchorEl}
          style={ { zIndex: 1500 } }
        >
          <NotationTypeSelectorContents handleCellOnClick={_handleCellOnClick} { ...restProps }/>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

const NotationTypeSelectorContents = (props) => {
  let { currentAlphabet: index, type, handleGridOnClick, handleCellOnClick } = props;
  let alphabet = ALPHABETS[ index ];
  let lChar = alphabet==="A-Z" ? "" : alphabet[ 0 ];
  let end = alphabet==="CA-CF" ? 6 : 26;
  let grid = buildNotationTypeGrid(lChar, end, type, handleCellOnClick);
  const classes = useStyles();
  return (
    <Paper elevation={8}>
      <Grid container className={classes.notationTypeSelectorHeader} justifyContent="space-between" alignItems="center" direction="row">
        <Grid item>
          <FlatButton name="prev" className={classes.prevNextAlphabetBtn} onClick={ handleGridOnClick }>
            <ChevronLeftIcon />
          </FlatButton>
        </Grid>
        <Grid item>
          <Container className={ classes.h5 }> { alphabet } </Container>
        </Grid>
        <Grid item>
          <FlatButton name="next" className={classes.prevNextAlphabetBtn} onClick={ handleGridOnClick }>
            <ChevronRightIcon />
          </FlatButton>
        </Grid>
      </Grid>
      <Box id="notation-type-table" style={ { borderTop: "1px solid rgba(0,0,0,.12)" } }>
        { grid }
      </Box>
    </Paper>
  );
}

const PageControl = (props) => {
  let { page, pageList, handleOnChange } = props;
  return (
    <div className="mr-2" style={ { float: "left" } }>
      <label htmlFor="page" className="mr-2">Page:</label>
      <Select id="page" value={ page } width="fit" items={ pageList } onChange={ handleOnChange }/>
    </div>
  )
}

const OrderInPageControl = (props) => {
  let { order, orderInPageList=[ 1 ], handleOnChange } = props;
  return (
    <div className="mr-2" style={ { float: "left" } }>
      <label htmlFor="order" className="mr-2" id="order-label">Order:</label>
      <Select id="order" value={ order } width="fit" items={ orderInPageList } onChange={ handleOnChange }/>
    </div>
  )
}

const PageControlMobile = (props) => {
  let { page, pageList, handleOnChange } = props;
  let options = pageList.map(item => <option>{ item }</option> );
  let size = Math.max(pageList.length, 3);
  return (
    <div style={ { minWidth: "100%" } }>
      <label className="ml-2">Page</label>
      <select class="custom-select" id="page" value={ page } size={ size } onChange={ handleOnChange }>
        { options }
      </select>
    </div>
  )
}

const OrderInPageControlMobile = (props) => {
  let { order, orderInPageList=[ 1 ], handleOnChange } = props;
  let options = orderInPageList.map(item => <option>{ item }</option> );
  return (
    <div style={ { minWidth: "100%" } }>
      <label className="ml-2" id="order-label">Order</label>
      <select class="custom-select" id="order" value={ order } size={ 2 } onChange={ handleOnChange }>
        { options }
      </select>
    </div>
  )
}

const findAlphabetForNotation = (type) => {
  if (type.length===1) {
    return 0;
  }
  let found = ALPHABETS.slice(1).find(alphabet => type[0]===alphabet[0]);
  return ALPHABETS.indexOf(found);
}

const NotationSelectorInline = (props) => {
  let { type, page, order, alphabet, handlers } = props;
  let { notationTypeGridChange, notationTypeChange, pageChange, orderChange } = handlers;
  let pageList = Tables.notationMap[ type ];
  let orderInPageList = Tables.pageMap[ type ] && [ 1, 2 ];
  let shouldRenderOrderInPageList = Tables.pageMap[ type ] && Tables.pageMap[ type ][ page ];
  const classes = useStyles();
  return (
    <div className={classes.notationSelector} id="notation-selector">
      <NotationTypeControl
        currentAlphabet={ alphabet }
        type={ type }
        handleGridOnClick={ notationTypeGridChange }
        handleCellOnClick={ notationTypeChange }
      />
      <PageControl type={ type } page={ page } pageList={ pageList } handleOnChange={ pageChange }/>
      {
        shouldRenderOrderInPageList &&
        <OrderInPageControl order={ order } orderInPageList={ orderInPageList } handleOnChange={ orderChange }/>
      }
    </div>
  );
}

const NotationSelectorDropdown = (props) => {
  let { type, page, order, alphabet, handlers } = props;
  let { notationTypeGridChange, notationTypeChange, pageChange, orderChange } = handlers;
  let pageList = Tables.notationMap[ type ];
  let orderInPageList = Tables.pageMap[ type ] && [ 1, 2 ];
  let shouldRenderOrderInPageList = Tables.pageMap[ type ] && Tables.pageMap[ type ][ page ];
  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown" id="notation-selector-hamburger">
        <a className="nav-link" data-toggle="dropdown" id="notation-type" title="Notation Selector">
          <i className="glyphicon glyphicon-menu-hamburger" />
        </a>
        <div className="dropdown-menu dropdown-menu-sm" role="menu" id="notation-selector-dropdown">
          <label className="ml-2">Notation</label>
          <NotationTypeSelectorContents
            currentAlphabet={ alphabet }
            type={ type }
            handleGridOnClick={ notationTypeGridChange }
            handleCellOnClick={ notationTypeChange }
          />
          <PageControlMobile page={ page } pageList={ pageList } handleOnChange={ pageChange }/>
          {
            shouldRenderOrderInPageList &&
            <OrderInPageControlMobile order={ order } orderInPageList={ orderInPageList } handleOnChange={ orderChange }/>
          }
        </div>
      </li>
    </ul>
  );
}

const NotationSelector = () => {

  let { type, page, order } = useSelector(state => ({
    type: state.notation.type,
    page: state.notation.page,
    order: state.notation.order
  }));

  const context = useContext(MainMenuContext);
  const { update } = context;

  const [alphabet, setAlphabet] = useState(
    findAlphabetForNotation(type));

  const notationTypeGridChange = (e) => {
    // First check which button we clicked...
    var direction = e.currentTarget.getAttribute("name");
    // If we clicked "next", increment; if "prev", decrement...
    if (direction==="next" && alphabet<1) {
      setAlphabet(alphabet+1);
    } else if (direction==="prev" && alphabet>0) {
      setAlphabet(alphabet-1);
    }
  }

  const notationTypeChange = (e) => {
    let elem = e.currentTarget;
    let _type = elem.querySelector("a").textContent;
    let _page = Tables.notationMap[ _type ][ 0 ];
    update(_type, _page, 1);
  }

  const pageChange = (e) => {
    let _page = parseInt(e.target.value);
    update(type, _page, 1);
  }

  const orderChange = (e) => {
    let _order = parseInt(e.target.value);
    update(type, page, _order);
  }

  let handlers = { notationTypeGridChange, notationTypeChange, pageChange, orderChange };
    
  return (
    <Fragment>
      <NotMobile><NotationSelectorInline { ...{ type, page, order, alphabet, handlers } }/></NotMobile>
      <Mobile><NotationSelectorDropdown { ...{ type, page, order, alphabet, handlers } }/></Mobile>
    </Fragment>
  );

}

export default NotationSelector;
