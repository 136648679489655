import React, { Fragment, useContext } from "react";
import ButtonGroup from "../widgets/button-group";
import GlyphIconButton from "../widgets/glyphicon-button";
import { useSelector } from "react-redux";
import { RealisationContainerContext } from "../realisation-container";


const Refresh = (props) => {
  const { refresh } = props;
  const displayMode = useSelector(state => state.realisation.displayMode);
  const disabled = displayMode==="Performance" ? "disabled" : "";
  return (
    <GlyphIconButton classNames={disabled} id="refresh" title="Refresh" icon="refresh" onClick={ refresh }/>
  );
}

const EditControls = () => {
  const context = useContext(RealisationContainerContext);
  const { refresh, undo, redo } = context;
  const iconStyle = { transform: "scale(-1, 1)" };
  return (
    <Fragment>
      <Refresh refresh={ refresh }/>
      <div className="btn-group mr-2" id="edit-controls">
        <GlyphIconButton id="undo" title="Undo" icon="share-alt" onClick={ undo } iconStyle={ iconStyle }/>
        <GlyphIconButton id="redo" title="Redo" icon="share-alt" onClick={ redo }/>
      </div>
    </Fragment>
  );
}

export default EditControls;

