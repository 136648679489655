import { Utils, findVoiceMaxY, findVoiceMinY } from '../utils';
import Glyph from './glyph';

const Graphics = function() {};

Graphics.windowXYToElemXY = function(elem, x, y) {
  var bbox = elem.getBoundingClientRect();
  return {
    x: x - bbox.left * (elem.offsetWidth  / bbox.width),
    y: y - bbox.top  * (elem.offsetHeight / bbox.height)
  };
};

// For this function see http://www.petercollingridge.co.uk/tutorials/svg/interactive/dragging/
Graphics.getMousePosition = function(ctx, e) {
  const { svg } = ctx;
  const CTM = svg.getScreenCTM();
  return {
    x: (e.clientX - CTM.e) / CTM.a,
    y: (e.clientY - CTM.f) / CTM.d
  };
};

Graphics.getAttributes = function(elem, attrs) {
  return attrs.map(attr => elem.getAttribute(attr));
};

Graphics.setAttributes = function(elem, attrs) {
  for (var key in attrs) {
    elem.setAttribute(key, attrs[key]);
  }
};

Graphics.setElemColor = function(obj, color) {
  Vex.forEach(obj.find("*"), function(child) {
    child.setAttribute("fill", color);
    child.setAttribute("stroke", color);
  });
};

// https://attacomsian.com/blog/javascript-dom-get-all-siblings-of-an-element#filter-siblings
Graphics.siblings = function(elem, filter) {
  // create an empty array
  let siblings = [];

  // if no parent, return empty list
  if (!elem.parentNode) {
      return siblings;
  }

  // first child of the parent node
  let sibling = elem.parentNode.firstElementChild;

  // loop through next siblings until `null`
  do {
      // push sibling to array
      if (sibling != elem && (!filter || filter(sibling))) {
          siblings.push(sibling);
      }
  } while (sibling = sibling.nextElementSibling);

  return siblings;
};

Graphics.offset = function(elem) {
  const rect = elem.getBoundingClientRect();
  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft
  };
};

Graphics.drawLine = function(ctx, fromX, fromY, toX, toY, options) {
  var options = options || {};
  ctx.save();
  ctx.beginPath();
  var lineWidth = options.lineWidth || 1;
  ctx.setLineWidth(lineWidth);
  ctx.moveTo(fromX, fromY);
  ctx.lineTo(toX, toY);
  ctx.stroke();
  ctx.closePath();
  ctx.restore();
};

// The following is taken directly from the VexFlow original in vexflow/src/renderer.js.
// Draw a dashed line (horizontal, vertical or diagonal
// dashPattern = [3,3] draws a 3 pixel dash followed by a three pixel space.
// setting the second number to 0 draws a solid line.
Graphics.drawDashedLine = function(context, fromX, fromY, toX, toY, dashPattern) {
  context.beginPath();
  var dx = toX - fromX;
  var dy = toY - fromY;
  var angle = Math.atan2(dy, dx);
  var x = fromX;
  var y = fromY;
  context.moveTo(fromX, fromY);
  var idx = 0;
  var draw = true;
  while (!((dx < 0 ? x <= toX : x >= toX) && (dy < 0 ? y <= toY : y >= toY))) {
    var dashLength = dashPattern[idx++ % dashPattern.length];
    var nx = x + (Math.cos(angle) * dashLength);
    x = dx < 0 ? Math.max(toX, nx) : Math.min(toX, nx);
    var ny = y + (Math.sin(angle) * dashLength);
    y = dy < 0 ? Math.max(toY, ny) : Math.min(toY, ny);
    if (draw) {
      context.lineTo(x, y);
    } else {
      context.moveTo(x, y);
    }
      draw = !draw;
  }
  context.closePath();
  context.stroke();
};

Graphics.drawCurve = function(ctx, startX, endX, startY, endY, cps, direction, thickness) {
  var cpSpacing = (endX - startX) / (cps.length + 2);
  ctx.beginPath();
  ctx.moveTo(startX, startY);
  ctx.bezierCurveTo(startX + cpSpacing + cps[0].x,
                    startY + (cps[0].y * direction),
                    endX - cpSpacing + cps[1].x,
                    endY + (cps[1].y * direction),
                    endX, endY);
  ctx.bezierCurveTo(endX - cpSpacing + cps[1].x,
                    endY + ((cps[1].y + thickness) * direction),
                    startX + cpSpacing + cps[0].x,
                    startY + ((cps[0].y + thickness) * direction),
                    startX, startY);
  ctx.stroke();
  ctx.closePath();
  ctx.fill();
};

Graphics.getLineAngle = function(x1, y1, x2, y2) {
  return Math.atan2(y2-y1,x2-x1);
};

// The following code for drawing arrows is adapted from here: http://www.dbp-consulting.com/tutorials/canvas/CanvasArrow.html

/*
// These functions were originally in utils.js. They are no longer used anywhere, but are included
// here just for reference, and in case we need to revive them. Note the differences between
// Graphics.drawArrowHead and drawHead, even though they produce identical results (the 'angle'
// parameter is meant to set the angle of the head, even though it interacts oddly with the 'h' parameter,
// which sets the size of the head - the size will change depending on the angle). The drawTail function
// clearly has too many parameters, even though when it was being used it did work!

Graphics.drawHead = function(ctx, x, y, angle, lineangle, h) {
  var angle1 = lineangle+angle;
  var topx = x+Math.cos(angle1)*h;
  var topy = y+Math.sin(angle1)*h;
  var angle2 = lineangle-angle;
  var botx = x+Math.cos(angle2)*h;
  var boty = y+Math.sin(angle2)*h;
  //ctx.save();
  ctx.beginPath();
  ctx.moveTo(topx, topy);
  ctx.lineTo(x,y);
  ctx.lineTo(botx, boty);
  ctx.stroke();
  //ctx.restore();
};

Graphics.drawTail = function(ctx, x0, y0, x1, y1, x2, y2, angle, lineangle, h) {
  drawHead(ctx, x0, y0, x1, y1, x2, y2, angle, lineangle, -h);
};
*/

Graphics.drawArrowHead = function(ctx, x, y, angle, d) {
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x-d*Math.cos(angle-Math.PI/6),y-d*Math.sin(angle-Math.PI/6));
  ctx.moveTo(x, y);
  ctx.lineTo(x-d*Math.cos(angle+Math.PI/6),y-d*Math.sin(angle+Math.PI/6));
  ctx.stroke();
};

Graphics.drawArrowTail = function(ctx, x, y, lineangle, d) {
  Graphics.drawArrowHead(ctx, x, y, lineangle, -d);
};

Graphics.drawArrow = function(ctx, x1, y1, x2, y2, type) {
  'use strict';

  // Angle of the head and tail sides
  var angle = Math.PI/6;
  // Length of the head and tail sides.
  var d = 10;

  // Draw the shaft of the arrow
  ctx.beginPath();
  ctx.moveTo(x1,y1);
  ctx.lineTo(x2,y2);
  ctx.stroke();

  // Calculate the angle of the line
  var lineangle = Graphics.getLineAngle(x1, y1, x2, y2);
  // h is the line length of a side of the arrow head
  var h = Math.abs(d/Math.cos(angle));

  var arrowType = {
    UP: 1,
    DOWN: 2,
    VERTICAL_DOUBLE_HEAD: 3,
    VERTICAL_DOUBLE_TAIL: 4,
    RIGHT: 5,
    LEFT: 6,
    HORIZONTAL_DOUBLE_HEAD: 7,
    HORIZONTAL_DOUBLE_TAIL: 8
  }

  switch (type) {
    case arrowType.LEFT:
    case arrowType.UP:
    Graphics.drawArrowHead(ctx, x1, y1, lineangle+Math.PI, h);
    Graphics.drawArrowTail(ctx, x2, y2, lineangle, h);
    break;
    case arrowType.RIGHT:
    case arrowType.DOWN:
    Graphics.drawArrowHead(ctx, x1, y1, lineangle, h);
    Graphics.drawArrowTail(ctx, x2, y2, lineangle+Math.PI, h);
    break;
    case arrowType.VERTICAL_DOUBLE_HEAD:
    case arrowType.HORIZONTAL_DOUBLE_HEAD:
    Graphics.drawArrowTail(ctx, x1, y1, lineangle, h);
    Graphics.drawArrowHead(ctx, x2, y2, lineangle, h);
    break;
    case arrowType.VERTICAL_DOUBLE_TAIL:
    case arrowType.HORIZONTAL_DOUBLE_TAIL:
    Graphics.drawArrowHead(ctx, x1, y1, lineangle, h);
    Graphics.drawArrowTail(ctx, x2, y2, lineangle, h);
    break;
  }
};

Graphics.drawRawSVGPath = function(ctx, d, attributes) {
  var path = ctx.create("path");
  if (typeof attributes === "undefined") attributes = {
    fill: "none",
    "stroke-width": ctx.lineWidth,
    stroke: "black"
  };

  Utils.merge(attributes, {d: d});
  ctx.applyAttributes(path, attributes);

  ctx.add(path);
  return path;
};

/*
Graphics.getBestStemStartPosition = function(note, orientation) {
  var noteHeads = note.getNoteheads();
  var best = 0;
  if (orientation=="up") {
    for (var i=best; i<noteHeads.length; i++) {
      var noteHead = noteHeads[i];
      if (noteHead.displaced===true) best = i;
        break;
      }
  }
  else if (orientation=="down") {
    best = noteHeads.length-1;
    for (var i=best; i>=0; i--) {
      var noteHead = noteHeads[i];
      if (noteHead.displaced===true) best = i;
        break;
      }
  }
  return {
    x: noteHeads[best].x,
    y: noteHeads[best].y //note.getYs()[best]
  }
};
*/

Graphics.drawBeam = function(ctx, notes, options) {

  // Config options.

  var defaults = {
    orientation: "up",
    beamWidth: 5,
    stroke: "black"
  };
  var options = Utils.merge(defaults, options || {});
  var orientation = options.orientation;

  // Draw the beam;

  var beamAttr = {
    "stroke-width": options.beamWidth,
    stroke: options.stroke
  };

  var firstNote = notes[0];
  var lastNote = notes[notes.length-1];

  var closestY;
  var beamY;
  var beamStart;
  var beamEnd;
  var minStemLength = 40;

  if (orientation=="up") {
    closestY = findVoiceMaxY(notes);
    beamY = closestY-minStemLength;
    beamStart = firstNote._vfnote.getStemX()-1;
    beamEnd = lastNote._vfnote.getStemX()+1;
  }
  else if (orientation=="down") {
    closestY = findVoiceMinY(notes);
    beamY = closestY+minStemLength;
    beamStart = firstNote.getAbsoluteX()-1;
    beamEnd = lastNote.getAbsoluteX()+1;
  }

  ctx.line(beamStart, beamY, beamEnd, beamY, beamAttr);

  // Draw the note stems.

  var stemAttr = {
    "stroke-width": 1,
    stroke: options.stroke
  };

  notes.forEach(function(note) {
    var stemX;
  var stemY = note.getYs()[0];
    if (orientation=="up") {
      stemX = note._vfnote.getStemX();
    }
    else if (orientation=="down") {
      stemX = note.getAbsoluteX();
  stemY = note.getYs()[note.numPitches()-1];
    }
    //var stemY = note.getYs()[0];
    var stemLength = stemY-beamY;
    ctx.line(stemX, stemY, stemX, stemY-stemLength, stemAttr);
  });

/*
notes.forEach(function(note) {
    var stemPosition = this.getBestStemStartPosition(note, orientation);;
  var stemX = stemPosition.x, stemY = stemPosition.y;
    var stemLength = stemY-beamY;
    ctx.line(stemX, stemY, stemX, stemY-stemLength, stemAttr);
  }, this);
*/

};

// Note stem drawing (used by notation X and AA).

Graphics.drawNoteStem = function(ctx, note, direction, stemHeight) {
  var direction = direction || "up";
  var stemHeight = stemHeight || 42;
  var xBegin = note._vfnote.getNoteHeadBeginX();
  var xEnd = note._vfnote.getNoteHeadEndX();
  var stemX, stemY;
  var stemWidth = 1;
  if (direction == "down") {
    // Down stems are rendered to the left of the head.
    stemX = xBegin + (stemWidth / 2);
    stemY = note._vfnote.getYs()[0] + 2;
  } else {
    // Up stems are rendered to the right of the head.
    stemX = xEnd + (stemWidth / 2);
    stemY = note._vfnote.getYs()[0] - stemHeight - 2;
  }
  ctx.line(stemX, stemY, stemX, stemY + stemHeight);
};

Graphics.drawNoteFlag = function(ctx, note, stemDirection, stemHeight) {
  var stemDirection = stemDirection || "up";
  var stemHeight = stemHeight || 42;
  var flagUpstem = "v54";
  var flagDownstem = "v9a";
  var xBegin = note._vfnote.getNoteHeadBeginX();
  var xEnd = note._vfnote.getNoteHeadEndX();
  var flagX, flagY, flagCode;
  if (stemDirection === "down") {
    // Down stems have flags on the left.
    flagX = xBegin + 1;
    flagY = note._vfnote.getYs()[0] + stemHeight + 2;
    flagCode = flagDownstem; //glyph.code_flag_downstem;
  } else {
    // Up stems have flags on the left.
    flagX = xEnd + 1;
    flagY = note._vfnote.getYs()[0] - stemHeight - 2;
    flagCode = flagUpstem; //glyph.code_flag_upstem;
  }
  Glyph.renderRawGlyph(ctx, flagX, flagY, flagCode, 35);
};

// This function is adapted from the Vexflow GraceNote class. Compare it
// to the original under vexflow/src/gracenote.js to see what's changed.
Graphics.drawSlash = function(ctx, note, direction, stemHeight) {
  var stemHeight = stemHeight || 42;
  ctx.beginPath();
  var x = note.getAbsoluteX();
  var y = note._vfnote.getYs()[0];
  if (direction === "up") {
    x += 4;
  y -= (stemHeight - 15);
    ctx.moveTo(x, y);
    ctx.lineTo(x + 13, y - 9);
  } else if (direction === "down") {
    x -= 4;
    y += (stemHeight - 15);
    ctx.moveTo(x, y);
    ctx.lineTo(x + 13, y + 9);
  }
  ctx.closePath();
  ctx.stroke();
};

Graphics.drawCurlyBrace = function(ctx, x, y, height) {
  var width = 12;
  var x1 = x - 2;
  var y1 = y;
  var x3 = x1;
  var y3 = y + height;
  var x2 = x1 - width;
  var y2 = y1 + height/2.0;
  var cpx1 = x2 - (0.90 * width);
  var cpy1 = y1 + (0.2 * height);
  var cpx2 = x1 + (1.10 * width);
  var cpy2 = y2 - (0.135 * height);
  var cpx3 = cpx2;
  var cpy3 = y2 + (0.135 * height);
  var cpx4 = cpx1;
  var cpy4 = y3 - (0.2 * height);
  var cpx5 = x2 - width;
  var cpy5 = cpy4;
  var cpx6 = x1 + (0.40 * width);
  var cpy6 = y2 + (0.135 * height);
  var cpx7 = cpx6;
  var cpy7 = y2 - (0.135 * height);
  var cpx8 = cpx5;
  var cpy8 = cpy1;
  ctx.beginPath();
  ctx.moveTo(x1, y1);
  ctx.bezierCurveTo(cpx1, cpy1, cpx2, cpy2, x2, y2);
  ctx.bezierCurveTo(cpx3, cpy3, cpx4, cpy4, x3, y3);
  ctx.bezierCurveTo(cpx5, cpy5, cpx6, cpy6, x2, y2);
  ctx.bezierCurveTo(cpx7, cpy7, cpx8, cpy8, x1, y1);
  ctx.fill();
  ctx.stroke();
};

export { Graphics };
