/*
Base Modal Component.
*/

import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const appendClassnames = (classNames) => {
  let className = "modal";
  classNames.forEach(name => { className = className+" "+name; });
  return className;
}


export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={ { m: 0, p: 2 } } { ...other }>
      { children }
      {
        onClose ? (
          <IconButton
            aria-label="close"
            onClick={ onClose }
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null
      }
    </DialogTitle>
  );
};

const BaseModal = (props) => {

  let { id, title, children, open, handleClose, hasFooter=true, isFullScreen=false, buttons="default", ...other } = props;
  let fullScreen = isFullScreen ? " modal-full-screen" : "";

  const DialogCloseButton = () => (<Button onClick={handleClose}>CLOSE</Button>);

  return (
    <Dialog id={id} open={open} onClose={handleClose} { ...other }>
      <BootstrapDialogTitle onClose={handleClose}>
        { title }
      </BootstrapDialogTitle>
      <DialogContent dividers>
        { children }
      </DialogContent>
      {
        hasFooter===true &&
        <DialogActions>
          { buttons==="default" ? <DialogCloseButton /> : buttons }
        </DialogActions>
      }
    </Dialog>
  );
}

export default BaseModal;