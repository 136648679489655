import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiSelect from '@material-ui/core/Select';
import { InputLabel, MenuItem, FormControl } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const styles = ({ palette }) => ({
  label: {
    marginLeft: '4px',
    color: palette.grey[500],
    "&.Mui-focused":{
      color: palette.grey[500], // to overwrite the default behaviour
    }
  },
  selectBtnLight: {
    minWidth: props => props.minWidth,
    minHeight: "auto",
    backgroundColor: "#f5f5f5",
    color: "rgba(0,0,0,.87)",
    fontSize: ".875rem",
    fontWeight: 500,
    lineHeight: "14px",
    border: 0,
    borderRadius: "2px",
    boxShadow: "0 0 4px 0 rgba(0,0,0,.14),0 3px 4px 0 rgba(0,0,0,.12),0 1px 5px 0 rgba(0,0,0,.2)",
    paddingLeft: '16px',
    paddingRight: '36px !important',
    paddingTop: '11px',
    paddingBottom: '11px',
    "&:hover": {
      backgroundImage: "linear-gradient(180deg,rgba(0,0,0,.12),rgba(0,0,0,.12))",
      borderColor: palette.grey[400],
    },
    "&:focus": {
      backgroundColor: "rgb(0,0,0,0)",
      borderColor: "1px solid rgba(0,0,0,0)",
    },
  },
  selectIconBtn: {
    minWidth: props => props.minWidth,
    minHeight: "auto",
    backgroundColor: "rgb(0,0,0,0)",
    color: "rgba(0,0,0,.87)",
    fontSize: ".875rem",
    fontWeight: 500,
    lineHeight: "14px",
    border: "1px solid rgba(0,0,0,0)",
    borderRadius: "0%",
    boxShadow: "none",
    paddingLeft: '16px',
    paddingRight: '36px !important',
    paddingTop: '11px',
    paddingBottom: '11px',
    "&:hover": {
      backgroundImage: "linear-gradient(180deg,rgba(0,0,0,.12),rgba(0,0,0,.12))",
      borderColor: "1px solid rgba(0,0,0,0)",
    },
    "&:focus": {
      backgroundColor: "rgb(0,0,0,0)",
      borderColor: "1px solid rgba(0,0,0,0)",
    },
  },
  icon: {
    color: "rgba(0,0,0,.87)",
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
  },
  formControlNoMargin: {
    verticalAlign: "middle", // We need this otherwise the label is misaligned.
    "& .MuiInput-formControl": {
      marginTop: "0px"
    }
  }
});

const useStyles = makeStyles(styles);

// Adapted from: https://github.com/siriwatknp/mui-treasury/issues/541

const Select = (props) => {

  const { items=[], id, value, label, minWidth="22px", buttonClass="btnLight", onChange } = props;

  const classes = useStyles({ minWidth });

  // moves the menu below the select input
  const menuProps = {
    classes: {
      list: classes.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon className={props.className + " " + classes.icon}/>
    )
  };

  const options = items.map(item => <MenuItem value={ item }>{item}</MenuItem> );

  return (
    <FormControl className={classes.formControlNoMargin}>
      <InputLabel className={classes.label} id="inputLabel">{ label }</InputLabel>
      <MuiSelect
        id={ id }
        disableUnderline
        classes={{ root: buttonClass=="btnLight" ? classes.selectBtnLight : classes.selectIconBtn, }}
        labelId="inputLabel"
        IconComponent={ iconComponent }
        MenuProps={ menuProps }
        value={ value }
        onChange={ onChange }
      >
        { options }
      </MuiSelect>
    </FormControl>
  );
};

export default Select;