/*
Realisation List Dialog.
*/

import React, { Fragment, useState, useRef } from "react";
import BaseModal from "./base-modal";
import { Button } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";


// Table row.
const TableRow = (props) => {

  const { item, index, selected, setSelected, prevSelected, setPrevSelected, isEditingName, setIsEditingName } = props;

  const { name, date } = item;

  const highlight = selected===index && "selected";

  const onClick = (e) => {
    const row = parseInt(e.target.closest("tr").getAttribute("data-index"));
    setSelected(row);
    if (e.target.closest(".btn#edit-name-btn")) {
      if (row==prevSelected) {
        setIsEditingName(!isEditingName);
      } else {
        setIsEditingName(true);
      }
    }
    else if (!e.target.closest("input[type='text']")) {
      setIsEditingName(false);
    }
    setPrevSelected(row);
  };

  return (
    <tr className={highlight} data-index={index}>
      <td onClick={onClick}>
        {
          (isEditingName && highlight)
          ? <input type="text"></input>
          : <a>{ name }</a>
        }
        <a className="btn flat-button ml-2" id="edit-name-btn" style={ { padding: ".3rem .5rem" } }>
          {
            (isEditingName && highlight)
            ? <i className="glyphicon glyphicon-floppy-disk" title="Save" style={ { fontSize: "14px" } }/>
            : <i className="glyphicon glyphicon-pencil" title="Rename" style={ { fontSize: "14px" } }/>
          }
        </a>
      </td>
      <td onClick={onClick} style={ { verticalAlign: "middle" } }><a>{ date }</a></td>
      <td onClick={onClick}>
        {
          highlight &&
          <a className="btn flat-button ml-2" title="Delete" style={ { padding: ".3rem .5rem", color: "red" } }>
            <span aria-hidden="true">&times;</span>
          </a>
        }
      </td>
    </tr>
  );
}

// Table main.
const RealisationListModal = () => {

  const { type, page, order } = useSelector((state) => state.notation);
  const { showRealisationList } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  
  const [pagination, setPagination] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(0);
  const [isEditingName, setIsEditingName] = useState(false);

  const prevSelected = useRef(0);

  const setPrevSelected = (row) => {
    prevSelected.current = row;
  }

  let realisations = JSON.parse( localStorage.getItem("realisations") ) || [];
  realisations = realisations.filter(obj => obj.type==type && obj.page==page && obj.order==order);

  const rows = realisations && realisations.map((item, index) => {
    return <TableRow { ...{item, index, selected, setSelected, isEditingName, setIsEditingName} } prevSelected={prevSelected.current} setPrevSelected={setPrevSelected}/>
  });

  const handleClose = () => dispatch({ type: "SHOW_REALISATION_LIST" });

  const loadSelected = () => {
    const { data } = realisations[selected];
    dispatch({ type: "SET_REALISATION_DATA", payload: data });
    handleClose();
  };

  const Buttons = (props) => {
    let { selected, loadSelected } = props;
    let disabled = selected===undefined;
    return (
      <Fragment>
        <Button onClick={handleClose} color="secondary">Close</Button>
        <Button onClick={loadSelected} color="primary" disabled={Boolean(disabled)}>Load Selected</Button>
      </Fragment>
    );
  }

  return (
    <BaseModal
      id="realisation-list-modal"
      title={"Saved Realisations"}
      open={showRealisationList}
      handleClose={handleClose}
      buttons={<Buttons selected={selected} loadSelected={loadSelected}/>}
      fullWidth={true}
      maxWidth="sm"
    >
      <div>
        {
          realisations &&
          <table className="table table-hover" id="realisations-list" style={ {width: "100%"} }>
            <thead className="thead-light">
              <tr>
                <th>Name:</th>
                <th>Date Saved:</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{ rows }</tbody>
          </table>
        }
      </div>
    </BaseModal>
  );

}

export default RealisationListModal;