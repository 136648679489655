/*
Realisation Toolbar Component.
*/

import React, { Fragment, useState, useRef, useCallback } from "react";
import FileControls from "./file-controls";
import EditControls from "./edit-controls";
import ZoomControls from "./zoom-controls";
import DisplayModeControls from "./display-mode-controls";
import ViewControls from "./view-controls";
import DisplayBar from "../display-bar";
import { Desktop, NotDesktop } from "../hooks/use-media-query";
import { useSelector, useDispatch } from "react-redux";

const PerformanceModeToolbar = (props) => {
  let style = {display: "flex", padding: "4px 4px 3px 4px", backgroundColor: "#f5f5f5"};
  return (
    <div id="performance-mode-toolbar" style={style} ref={ props.callbackRef }>
      <ViewControls />
    </div>
  );
}

const PerformanceModeToolbarToggler = () => {
  let dispatch = useDispatch();
  let onClick = () => dispatch({ type: "SHOW_PERFORMANCE_MODE_TOOLBAR" });
  return (
    <a className="btn flat-button ml-auto" onClick={onClick}>
      <i className="glyphicon glyphicon-option-vertical" title="Toggle Performance Mode Controls..."></i>
    </a>
  );
}

export default function RealisationToolbar(props) {
  let { displayMode, showPerformanceModeToolbar } = useSelector(state => ({
    displayMode: state.realisation.displayMode,
    showPerformanceModeToolbar: state.realisation.showPerformanceModeToolbar
  }));

  const initialDisplayBarTop = useRef();
  const [ displayBarTop, setDisplayBarTop ] = useState();

  const realisationToolbarRef = useCallback(node => {
    if (node!==null) {
      let top = node.offsetHeight;
      setDisplayBarTop(top);
      initialDisplayBarTop.current = top;
    }
  }, []);
  const performanceModeToolbarRef = useCallback(node => {
    let top = node!==null ? node.offsetTop+node.offsetHeight : initialDisplayBarTop.current;
    setDisplayBarTop(top);
  });
  return (
    <Fragment>
      <div id="realisation-toolbar" ref={realisationToolbarRef}>
        <FileControls />
        <EditControls />
        <ZoomControls />
        <DisplayModeControls />
        <Desktop><ViewControls displayMode={ displayMode }/></Desktop>
        {
          displayMode==="Performance" &&
          <NotDesktop><PerformanceModeToolbarToggler /></NotDesktop>
        }
      </div>
      {
        displayMode==="Performance" &&
        showPerformanceModeToolbar &&
        <NotDesktop><PerformanceModeToolbar callbackRef={ performanceModeToolbarRef }/></NotDesktop>
      }
      <DisplayBar top={displayBarTop}/>
    </Fragment>
  );
}
