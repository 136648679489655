import React, { useContext } from "react";
//import useDidUpdate from "../../hooks/use-did-update";
import Grid from "@material-ui/core/Grid";
import { SettingsContext } from "./dialog";
import { RadioButtonGroup } from "../../widgets/radio-button";
import { Tables } from "../../../tables";

const SelectionTypeRadioGroup = () => {
  let { defaultNotationSelectionType: type, dispatch } = useContext(SettingsContext);
  const onChange = (e) => dispatch({
    type: "SET_DEFAULT_NOTATION_SELECTION_TYPE",
    payload: { defaultNotationSelectionType: e.target.value }
  });
  const items = [
    { id: "use-selected-radio", value: "selected", checked: (type==="selected"), label: "Use selected", onChange: onChange },
    //{ id: "use-current-radio", value: "current", checked: (type==="current"), label: "Use current", onChange: onChange },
    { id: "random-selection-radio", value: "none", checked: (type==="none"), label: "None (random selection)", onChange: onChange }
  ];
  return (
    <RadioButtonGroup items={ items }/>
  );
}

const SelectedNotation = (props) => {
  let { type: currentType, page: currentPage } = props;
  let { defaultNotation, defaultNotationSelectionType, dispatch } = useContext(SettingsContext);

  const setType = (e) => {
    let type = e.target.value.toLowerCase()==="current" ? currentType : e.target.value;
    dispatch({
      type: "SET_DEFAULT_NOTATION",
      payload: {
        defaultNotation: {
          type: type,
          page: parseInt(Tables.notationMap[ type ][0])
        }
      }
    });
  };

  const setPage = (e) => {
    let page = e.target.value.toLowerCase()==="current" ? currentPage : parseInt(e.target.value);
    dispatch({
      type: "SET_DEFAULT_NOTATION",
      payload: { defaultNotation: { ...defaultNotation, page: page } }
    });
  };

  let { type, page } = defaultNotation;
  let notations = Object.keys(Tables.notationMap).slice(0, 52); // Just the first two alphabets, until everything's complete.
  let pages = Tables.notationMap[ type ];

  const items = [
    { id: "notation-input", label: "Type", value: type, onChange: setType, options: notations },
    { id: "page-input", label: "Page", value: page, onChange: setPage, options: pages }
  ];

  const inputs = items.map(item => {
    const { id, label, value, onChange } = item;
    let options = (label==="Type" || (label==="Page" && type===currentType))
      ? [ <option >Current</option>, <option disabled="disabled">——————————————</option> ]
      : [];
    options = [ ...options, ...item.options.map(option => (<option>{option}</option>)) ];
    const disabled = !(defaultNotationSelectionType==="selected");
    return (
      <Grid item xs={6} className="mb-3">
        <label htmlFor={id}>{label}</label>
        <select
          className="form-control"
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          { options }
        </select>
      </Grid>
    )
  });

  return (
    <Grid container direction="row" spacing={2}>
      { inputs }
    </Grid>
  );
};

const DefaultNotationSettings = () => {
  return (
    <div>
      <legend>Default Notation</legend>
      <p>Set the notation to be displayed when loading the application.</p>
      <SelectedNotation />
      <SelectionTypeRadioGroup />
    </div>
  );
}

export default DefaultNotationSettings;