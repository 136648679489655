import ScoreObject from './score-object';
import { Utils } from '../utils';
import { NoteTools } from "./tools/scoretools/note-tools";
import Voice from './voice';

/**
 * Class representing a stave. A stave may contain zero or more voice objects.
 * @class CC.Core.Stave
 * @extends CC.Core.ScoreObject
 * @param {Object} options - Stave object options.
 * @param {string} options.clef The clef for the stave.
 * @param {number} options.x The x coordinate for the stave.
 * @param {number} options.y The y coordinate for the stave.
 * @param {number} options.width The width in pixels for the stave.
 * @param {number} options.numLines The number of lines for the stave.
 * @param {number} options.lineSpacing The spacing between stave lines.
 * @param {number} options.spaceAboveStaffLine Number of lines above the stave.
 * @param {number} options.spaceBelowStaffLine Number of lines below the stave.
 * @param {CC.Core.Voice[]} options.voices Array of voices to be added to the stave.
 * @param {string} options.text Text to place to the left of the stave.
 * @param {Object[]} options.extensionLines Array of option objects for setting extension lines.
 * @param {number} options.clefXShift Amount to shift the clef in the x direction.
 * @param {boolean} options.renderToGroup Set to 'true' to enable rendering of the stave inside an SVG Group.
 */
export default class Stave extends ScoreObject {

  constructor(options) {

    super(options);

    this.clef = options.clef || "treble";
    this.x = options.x || 10;
    this.y = options.y || 60;
    this.width = options.width || 300;
    this.numLines = options.numLines || Stave.DEFAULT_NUM_LINES;
    this.lineSpacing = options.lineSpacing || 10;
    this.spaceAboveStaffLine = options.spaceAboveStaffLine===0 ? 0 : (options.spaceAboveStaffLine || 4);
    this.spaceBelowStaffLine = options.spaceBelowStaffLine===0 ? 0 : (options.spaceBelowStaffLine || 4);

    this.voices = [];

    this.hideClef = options.hideClef;
    this.hideEndBarline = options.hideEndBarline;

    this.text = options.text;
    this.extensionLines = options.extensionLines || [];
    this.clefXShift = options.clefXShift || 0;

    this.renderToGroup = options.renderToGroup || false;

    this.type = options.type || "normal"; // One of 'noise' or 'normal'.
    if (this.type==="noise") {
      Vex.Flow.Clef.types.percussion.line = 0;
      this.clef = "percussion";
      this.numLines = 1;
    }

    this.octava = options.octava;

  }

  static build(data) {
    var stave = new Stave(data);
    data.voices.forEach(voiceOpts => {
      var voice = Voice.build(voiceOpts);
      //stave.addVoice(voice);
      stave.voices.push(voice);
    });
    return stave;
  }

  getVoices() {
    return this.voices;
  }

  getVoiceCount() {
    return this.getVoices().length;
  }

  getNthVoice(index) {
    return this.getVoices()[index];
  }

  getClef() {
    return this.clef;
  }

  addClef(clef) {
    this.clef = clef;
    this._vfstave.addClef(clef);
    return this;
  }

  getX() {
    return this._vfstave.x;
  }

  setX(x) {
    this.x = x;
  }

  setY(y) {
    this.y = y;
  }

  getWidth() {
    return this._vfstave.width;
  }

  setWidth(width) {
    this.width = width;
  }

  getNumLines() {
    return this.numLines;
  }

  getBoundingBox() {
    return this._vfstave.getBoundingBox();
  }

  drawBoundingBox() {
    var ctx = this.getRenderContext();
    var bbox = this.getBoundingBox();
    var attr = {
      fill: "none",
      stroke: "red",
      "stroke-width": this.lineWidth
    };
    //console.log(bbox);
    ctx.rect(bbox.x, bbox.y, bbox.w, bbox.h, attr);
  }

  getSpaceAboveStaffLine() {
    return this.spaceAboveStaffLine;
  }

  setSpaceAboveStaffLine(val) {
    this.spaceAboveStaffLine = val;
  }

  getSpaceBelowStaffLine() {
    return this.spaceBelowStaffLine;
  }

  setSpaceBelowStaffLine(val) {
    this.spaceBelowStaffLine = val;
  }

  getClefXShift() {}

  setClefXShift(val) {
    this._vfstave.glyphs[0].setXShift(val);
    return this;
  }

  /*
  getYForLine(line) {
    return this._vfstave.getYForLine(line);
  }
  */

 getYForLine(line) {
  const { lineSpacing, spaceAboveStaffLine } = this;
  const { STAVE_LINE_THICKNESS } = Vex.Flow;
  const THICKNESS = (STAVE_LINE_THICKNESS > 1 ? STAVE_LINE_THICKNESS : 0);
  const y = this.y + ((line * lineSpacing) + (spaceAboveStaffLine * lineSpacing)) - (THICKNESS / 2);
  return y;
}

  // This adapts the actual code from VexFlow's getYForNote Stave method.
  // previously we were calling that VexFlow method itself, but the VexFlow
  // Stave object won't exist until we render the stave, which is too late.
  // Luckily it seems we can just use the passed-in stave options, which
  // seem to map to the VexFlow Stave object's own options directly.
  getYForNote(line) {
    //return this._vfstave.getYForNote(line); // <- The old version.
    var spacing = this.lineSpacing;
    var headroom = this.spaceAboveStaffLine;
    var y = this.y + (headroom * spacing) + (5 * spacing) - (line * spacing);
    return y;
  }

  getYForTopLine() {
    return this.getYForLine(0);
  }

  getYForBottomLine() {
    return this.getYForLine(this.getNumLines());
  }

  // These two methods return the number for the top and bottom
  // ledger lines, repsectively. In both cases line 0 is the top
  // line of the stave (F5 in the treble, A3 in the bass).
  getTopLedgerLine() {
    var line, clef = this.getClef();
    if (clef=="treble") {
      line = -9;
    } else if (clef=="bass") {
      line = -15;
    }
    return line;
  }

  // Bottom A (A0) is line 16.5 in the treble clef, line 10.5 in the bass.
  getBottomLedgerLine() {
    var line, clef = this.getClef();
    if (clef=="treble") {
      line = 16.5;
    } else if (clef=="bass") {
      line = 10.5;
    }
    return line;
  }

  getYForTopLedgerLine() {
    var line = this.getTopLedgerLine();
    return this.getYForLine(line);
  }

  getYForBottomLedgerLine() {
    var line = this.getBottomLedgerLine();
    return this.getYForLine(line);
  }

  getYForBottomText(line) {
    return this._vfstave.getYForBottomText(line);
  }

  // The 'effective width' is the width of the portion of
  // the stave where musical activity occurs, i.e: the region
  // where notes can be meaningfully positioned.
  getEffectiveWidth() {
    var startX = this.getNoteStartX();
    var endX = this.getNoteEndX();
    return endX-startX;
  }

  // The 'effective height' is the vertical distance between the
  // top and bottom ledger lines possible for the stave.
  getEffectiveHeight() {
    var top = this.getYForTopLedgerLine();
    var bottom = this.getYForBottomLedgerLine();
    return bottom-top;
  }

  getWidthToNoteStartX() {
    return this.getNoteStartX()-this.getX();
  }

/*
  getMaxNoteLine() {
    var maxLine = -10000;
    this.eachVoice(function(voice) {
      var line = voice.findVoiceMaxLine();
      if (line>maxLine) maxLine = line;
    });
    return maxLine;
  }

  getMinNoteLine() {
    var minLine = 10000;
    this.eachVoice(function(voice) {
      var line = voice.findVoiceMinLine();
      if (line<minLine) minLine = line;
    });
    return minLine;
  }
*/

  getMaxNoteLine() {
    var voices = this.getVoices();
    var line = Math.max.apply(Math, voices.map(function(voice) {
      return voice.findVoiceMaxLine();
    }));
    return line;
  }

  getMinNoteLine() {
    var voices = this.getVoices();
    var line = Math.min.apply(Math, voices.map(function(voice) {
      return voice.findVoiceMinLine();
    }));
    return line;
  }

  getMaxNoteY() {
    var maxLine = this.getMaxNoteLine();
    return this.getYForNote(maxLine);
  }

  getMinNoteY() {
    var minLine = this.getMinNoteLine();
    return this.getYForNote(minLine);
  }

/*
  getLineForY(y) {
    var line = -12; // -11.5 is A0, lowest piano key.
    while (this.getYForNote(line+0.5)>y || line==20) {
      line += 0.5;
    }
    return line;
  }
*/

  getLineForY(y) {
    var line = -11.5; // A0, lowest piano key.
    while (true) {
      var lineY = this.getYForNote(line);
      if (Math.abs(lineY-y) <= 2.5 || line == 20) break
      else line += 0.5;
    }
    return line;
  }

  /*
  // Called by the Score class to accommodate the highest and lowest notes of the stave.
  adjustMetricsForMinMaxNotes() {
    // Adjust the position of the stave to accommodate the highest note.
    var lineMax = this.getMaxNoteLine();
    if (lineMax >= this.getNumLines()+this.getSpaceAboveStaffLine()) {
      this.setSpaceAboveStaffLine(lineMax+1-this.getNumLines());
    }
    // Adjust the height of the stave to accommodate the lowest note.
    var lineMin = this.getMinNoteLine();
    if (lineMin <= -this.getSpaceBelowStaffLine()) {
      this.setSpaceBelowStaffLine(Math.abs(lineMin-1));
    }
  }
  */

  // Called by the Score class to accommodate the highest and lowest notes of the stave.
  adjustMetricsForMinMaxNotes(index, isGrandStaff) {
    if (!isGrandStaff || (isGrandStaff && index===0)) {
      // Adjust the position of the stave to accommodate the highest note.
      var lineMax = this.getMaxNoteLine();
      if (lineMax >= this.getNumLines()+this.getSpaceAboveStaffLine()) {
        this.setSpaceAboveStaffLine(lineMax+1-this.getNumLines());
      }
    }
    if (!isGrandStaff || (isGrandStaff && index===1)) {
      // Adjust the height of the stave to accommodate the lowest note.
      var lineMin = this.getMinNoteLine();
      if (lineMin <= -this.getSpaceBelowStaffLine()) {
        this.setSpaceBelowStaffLine(Math.abs(lineMin-1));
      }
    }
  }

  getNoteForLine(line, addRandomAccidental=true) {
    var clef = this.getClef();
    // Correction shifts 0 to C0 (16 for bass, 28 for treble).
    var correction = clef==="bass" ? 16 : 28;
    var step = (line*2)+correction;
    var octave = Math.floor(step/7);
    var steps = {
      0: "C",
      1: "D",
      2: "E",
      3: "F",
      4: "G",
      5: "A",
      6: "B"
    };
    var acc = addRandomAccidental ? Utils.getRandElem(["", "B", "#"]) : "";
    return steps[step%7]+acc+"/"+octave;
  }

  /*
  getPitchForY(y) {
    let clef = this.getClef();
    let bottomLine = clef==="treble" ? 16.5 : 10.5;
    let bottomY = this.getYForLine(bottomLine);
    let pitch = "a/0"; 
    if (y<bottomY-1) {
      let incr = 3;
      let midi = 22;
      for (let currentY=bottomY-2, currentMidi=midi; y<currentY-incr; currentY-=incr, currentMidi++) {
        midi=currentMidi;
      }
      pitch = NoteTools.midiToNoteString(midi+3);
    }
    return pitch;
  }
  */

  getPitchForY(y) {
    let clef = this.getClef();
    let bottomLine = clef==="treble" ? 17 : 11;
    let line = bottomLine;
    // If we have a point that strays below the possible piano range (which can happen with
    // certain biomorphic shapes) clamp it to a/0 and return that.
    let bottomY = this.getYForLine(bottomLine-0.5);
    if (y>=bottomY) return "a/0";
    // First get the closest line (stave line or ledger line) below the point.
    for (let currentLine=bottomLine-0.5, i=0; y<this.getYForLine(currentLine); currentLine--, i++) {
      line = currentLine;
    }
    // Convert the line to a midi number and iterate from slightly below the line in steps
    // of 3 pixels. At the same time increment the midi number.
    let startPitch = this.getNoteForLine(this.getLineForY(this.getYForLine(line)), false);
    let midi = NoteTools.noteStringToMidi(startPitch);
    let incr = 3;
    let startY = this.getYForLine(line)+2;
    for (let currentY=startY, currentMidi=midi; y<currentY; currentY-=incr, currentMidi++) {
      midi=currentMidi;
    }
    return NoteTools.midiToNoteString(midi);
  }

  getRange() {}

  getNoteStartX() {
    return this._vfstave.getNoteStartX();
  }

  setNoteStartX(x) {
    this._vfstave.setNoteStartX(x);
    return this;
  }

  getNoteEndX() {
    return this._vfstave.getNoteEndX();
  }

  addVoice(voice) {
    new Vex.Flow.Formatter()
      .joinVoices([voice._vfvoice])
      .formatToStave([voice._vfvoice], this._vfstave);
    return this.getVoices().push(voice);
  }

  removeVoice() {}

  empty() {}

  mapVoices(fn) {
    var voices = this.getVoices();
    return voices.map(fn);
  }

  eachVoice(fn) {
    var voices = this.getVoices();
    voices.forEach(fn);
    return voices;
  }

  setRenderContext(ctx) {
    this.renderContext = ctx;
    return this;
  }

  serialize() {
    var voices = this.getVoices().map(function(voice) {
      return voice.serialize();
    });
    return {
      clef: this.getClef(),
      voices: voices
    }
  }

  drawText(options) {
    var options = Utils.merge({font: "Arial", size: 24}, options);
    var ctx = this.renderContext;
    var text = this.text;
    ctx.save();
    ctx.setFont(options.font, options.size);
    var staveX = this.getX();
    var staveCenterY = this.getYForLine(2);
    var th = ctx.measureText(text).height;
    var tw = ctx.measureText(text).width;
    // I don't know why dividing th by 3 below works to centre the text - which it
    // does, whatever the font height. It would seem logical to divide by 2, but
    // that never works. Might be a platform issue, need to check that.
    ctx.fillText(text, staveX-tw-10, staveCenterY+th/3);
    ctx.restore();
  }

  /**
   * Occasionally Cage draws extra lines above and/or below the stave, on either side
   * (or both). For want of established terminology, we're calling these 'extension lines'
   * Add extension lines to a stave by passing in an array of option objects to the
   * 'extensionLines' argument of the constructor.
   */
  // {
  //   numLines: 5,           // Number of lines in the set.
  //   position: "above",     // Position of the set, whether above the stave or below it.
  //   length: 12,            // Horizontal length of the lines in the set.
  //   side: "left",          // Determines the horizontal position of the set, right or left.
  //   xAdjust: 10,           // Amount by which the line set is shifted away from its default position.
  //   attributes: undefined
  // }
  drawExtensionLines() {
    var extLines = this.extensionLines;
    if (extLines.length>0) {
      var ctx = this.getRenderContext();
      var staveX = this.x;
      var staveWidth = this.width;
      extLines.forEach(obj => {
        let { x, numLines=5, position="above", length=12, side="left", xAdjust=0, attributes={} } = obj;
        //let x = side==="left" ? staveX : staveX+staveWidth-length;
        (typeof x==="undefined") && (x = side==="left" ? staveX : staveX+staveWidth-length);
        (typeof x==="string") && (x = staveWidth * ( parseFloat(x)/100 ));
        x = x + xAdjust; // Adjust the horizontal position by the required amount.
        var attr = {
          stroke: "#999999",
          "stroke-width": 1.5,
          fill: "#999999",
          ...attributes
        };
        var start = position==="above" ? 6 : -numLines+1;
        var end = position==="above" ? start+numLines : 1;
        for (var line = start; line < end; line++) {
          var y = this.getYForNote(line);
          ctx.rect(x, y, length, 1, attr);
        }
      });
    }
  }

  drawOctava() {
    const ctx = this.renderContext;
    const clefElem = this.getClefElem();
    const { octava } = this;
    let [ , posn ] = octava.split("v");
    const { x, y, width, height } = clefElem.getBBox();
    let { width: txtW, height: txtH } = ctx.measureText(octava);
    let txtX = x + (width/2) - (txtW/2);
    let txtY = posn==="a" ? y : posn==="b" && y+height+txtH-4;
    ctx.save();
    ctx.setFont("gonville", 12, "italic bold");
    ctx.fillText(octava, txtX, txtY);
    ctx.restore();
  }

  getClefElem() {
    return this.elem.getElementsByClassName("clef")[0];
  }

  render() {
    var ctx = this.renderContext;
    var clef = this.clef;
    var x = this.x;
    var y = this.y;
    var width = this.width;
    var numLines = this.numLines;
    var lineSpacing = this.lineSpacing;
    var spaceAboveStaffLine = this.spaceAboveStaffLine;
    var spaceBelowStaffLine = this.spaceBelowStaffLine;

    this._vfstave = new Vex.Flow.Stave(x, y, width, {
      num_lines: numLines,
      spacing_between_lines_px: lineSpacing,
      space_above_staff_ln: spaceAboveStaffLine,
      space_below_staff_ln: spaceBelowStaffLine
    });

    if (!this.hideClef) {
      this._vfstave.addClef(clef);
    }

    if (this.clefXShift && this.clefXShift!=0) {
      this.setClefXShift(this.clefXShift);
    }
    this._vfstave.setContext(ctx);

    if (this.hideEndBarline) {
      var vfstave = this._vfstave;
      vfstave.modifiers.forEach(mod => {
        if (mod.barline && mod.barline===1) {
          // 7 is the Vex.Flow.Barline enum value for no barline (or at least it doesn't draw
          // itself then). This seems the only viable way to avoid having barlines, which is a
          // requirement when printing. See vexflow/src/stavebarline.js.
          mod.barline = 7;
        }
      });
    }

    this.elem = ctx.openGroup();
    this.elem.setAttribute("class", "stave");
    this._vfstave.draw();
    var clef = this.elem.querySelector("path");
    clef.setAttribute("class", "clef");
    if (this.type==="noise") {
      clef.setAttribute("fill", "rgba(0,0,0,0)");
    }
    if (this.text) {
      this.drawText();
    }
    if (this.octava) {
      this.drawOctava();
    }
    var extLines = this.extensionLines;
    if (extLines && extLines.length>0) {
      this.drawExtensionLines();
    }
    ctx.closeGroup();
    return this;
  }

}

Stave.DEFAULT_NUM_LINES = 5;
