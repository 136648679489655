const Tables = {

  velocityScale: [15, 31, 47, 63, 79, 95, 111, 127], // ppp, pp, p, mp, mf, f, ff, fff

  velocityToExpression: {
    15: "ppp",
    31: "pp",
    47: "p",
    63: "mp",
    79: "mf",
    95: "f",
    111: "ff",
    127: "fff"
  },

  pitchIndices: {
    "c": 0,
    "d": 1,
    "e": 2,
    "f": 3,
    "g": 4,
    "a": 5,
    "b": 6
  },

  staveLimits: {
    standardLimits: { treble: "a/3",  bass: "e/4" },
    grandStaffLimits: { treble: "c/4",  bass: "b/3" }
  },

  enharmonics: {
    "CBB": ["A#", "BB"],
    "CB": ["A##", "B"],
    "C": ["B#", "DBB"],
    "C#": ["B##", "DB"],
    "C##": ["D", "EBB"],
    "DBB": ["B#", "C"],
    "DB": ["B##", "C#"],
    "D": ["C##", "EBB"],
    "D#": ["EB", "FBB"],
    "D##": ["E", "FB"],
    "EBB": ["C##", "D"],
    "EB": ["D#", "FBB"],
    "E": ["D##", "FB"],
    "E#": ["F", "GBB"],
    "E##": ["F#", "GB"],
    "FBB": ["D#", "EB"],
    "FB": ["D##", "E"],
    "F": ["E#", "GBB"],
    "F#": ["E##", "GB"],
    "F##": ["G", "ABB"],
    "GBB": ["E#", "F"],
    "GB": ["E##", "F#"],
    "G": ["F##", "ABB"],
    "G#": ["AB"],
    "G##": ["A", "BBB"],
    "ABB": ["F##", "G"],
    "AB": ["G#"],
    "A": ["G##", "BBB"],
    "A#": ["BB", "CBB"],
    "A##": ["B", "CB"],
    "BBB": ["G##", "A"],
    "BB": ["A#", "CBB"],
    "B": ["A##", "CB"],
    "B#": ["C", "DBB"],
    "B##": ["C#", "DB"]
  },

  accidentalToUnicode: {
    "#" : String.fromCharCode(parseInt('266F', 16)),
    "b" : String.fromCharCode(parseInt('266D', 16)),
    // We probably don't need these, and they don't seem to render properly anyway.
    //"##" : String.fromCharCode(parseInt('1D12A', 16)),
    //"bb" : String.fromCharCode(parseInt('1D12B', 16))
    // The workaround is simply to join the two unicodes for sharp or flat.
    "##" : String.fromCharCode(parseInt('266F', 16))+String.fromCharCode(parseInt('266F', 16)),
    "bb" : String.fromCharCode(parseInt('266D', 16))+String.fromCharCode(parseInt('266D', 16))
  },

  // This table is to be used by the notation selector widget. The key is simply the notation type, and the
  // value is an array of page numbers where that particular notation type occurs. A separate pageMap table
  // contains entries where the key corresponds again to a specific notation type, but the value is a hash
  // whose entries take the form {<page>: <number-of-occurences>}.
  notationMap: {
    "A": [1, 5, 45, 49, 53],
    "B": [1, 9, 23, 34, 53, 55],
    "C": [1],
    "D": [2, 4, 37],
    "E": [2, 20, 49],
    "F": [2],
    "G": [4, 9, 11, 21],
    "H": [4, 36, 50],
    "I": [5], //[5, 12, 29, 46],
    "J": [5, 25],
    "K": [8, 9, 43],
    "L": [10],
    "M": [9, 19, 22, 30, 43],
    "N": [9],
    "O": [10, 42], //[10, 27, 42, 58],
    "P": [9],
    "Q": [10, 27],
    "R": [9],
    "S": [12, 34, 42],
    "T": [12, 16, 41],
    "U": [16],
    "V": [18],
    "W": [18, 26, 36],
    "X": [19],
    "Y": [19, 38],
    "Z": [19, 37],
    "AA": [21, 29, 43],
    "AB": [20],
    "AC": [21, 31],
    "AD": [20, 33, 37],
    "AE": [21, 56],
    "AF": [20],
    "AG": [20],
    "AH": [23],
    "AI": [24, 36],
    "AJ": [26],
    "AK": [25, 49],
    "AL": [26, 45],
    "AM": [26],
    "AN": [27, 47],
    "AO": [30, 47],
    "AP": [28],
    "AQ": [30],
    "AR": [31],
    "AS": [31, 56],
    "AT": [39, 43],
    "AU": [38],
    "AV": [37],
    "AW": [38],
    "AX": [38],
    "AY": [40],
    "AZ": [42],
		"BA": [42, 56],
    "BB": [45, 53],
    "BC": [47],
    "BD": [47],
    "BE": [47],
    "BF": [47],
    "BG": [47, 63],
    "BH": [50],
    "BI": [50],
    "BJ": [50],
    "BK": [52, 53], //[49, 52, 53],
    "BL": [51],
    "BM": [50, 60],
    "BN": [50],
    "BO": [52],
    "BP": [51],
    "BQ": [55],
    "BR": [51],
    "BS": [52],
    "BT": [54],
    "BU": [55],
    "BV": [53],
    "BW": [53],
    "BX": [56],
    "BY": [54],
    "BZ": [55],
		"CA": [55],
    "CB": [55],
    "CC": [57],
    "CD": [57],
    "CE": [59],
    "CF": [62]
  },

  pageMap: {
    "A": {49: 2},
    "C": {1: 2},
		"BX": {56: 2}
  },

  samePageRepeatNotations: [
    {
      type: "A",
      page: 49
    },
    {
      type: "C",
      page: 1
    },
    {
      type: "BX",
      page: 56
    }
  ],

  noteToInteger: {
    "c": 0,
    "d": 1,
    "e": 2,
    "f": 3,
    "g": 4,
    "a": 5,
    "b": 6
  },

  integerToNote: {
    0: "c",
    1: "d",
    2: "e",
    3: "f",
    4: "g",
    5: "a",
    6: "b"
  },

  // Overtone structure scale for BB, BV and related notations, adapted by Philip from
  // Tudor's sketches (it also reflect's Philip's own practice):
  overtoneStructureTable: {
    0:  "Keyboard",
    1:  "Pluck",
    3:  "Mute",
    4:  "Construction (of piano)",
    5:  "Auxiliary sound",
    6:  "Noise"
  },

  // Noises used by Tudor for notation P:
  noiseTable: {
    0: {
         "description": "Ruler on strings",
         "abbreviation": "Rl/S"
    },
    1: {
         "description": "Buzzer",
         "abbreviation": "Bz"
    },
    2: {
         "description": "Rachet",
         "abbreviation": "R"
    },
    3: {
         "description": "Toy",
         "abbreviation": "T"
    },
    4: {
         "description": "Alarm",
         "abbreviation": "A"
    },
    5: {
         "description": "Balloon",
         "abbreviation": "Bl"
    },
    6: {
         "description": "Slinky",
         "abbreviation": "Sl"
    },
    7: {
         "description": "Plastic on strings",
         "abbreviation": "Pl/S"
    },
    8: {
         "description": "Rubber on strings",
         "abbreviation": "Rb/S"
    },
    9: {
         "description": "Percussion",
         "abbreviation": "Pc"
    }
  },

};

export { Tables };
