import ScoreObject from './score-object';
import GraceNote from './grace-note';

export default class GraceNoteGroup extends ScoreObject {

  constructor(options) {
    super(options);

    this.note = options.note;
    this._graceNotes = options.graceNotes || [];
    this.stave = options.stave;
    this.isBeamed = options.isBeamed;
  }

  static drawGraceNotes(ctx, note, graceNotes, isBeamed) {
    const stave = note.getStave();
    return new GraceNoteGroup({
      note: note,
      graceNotes: graceNotes,
      stave: stave,
      isBeamed: isBeamed
    })
    .setRenderContext(ctx)
    .render();
  }

  createGraceNoteFromStruct(struct) {
    let { pitches, hasStem, stemOptions } = struct;
    return new GraceNote({
      pitches: pitches,
      note: this.note,
      stave: this.stave,
      hasStem: hasStem,
      stemOptions: stemOptions
    });
  }

  drawBeam() {}

  render() {
    var ctx = this.getRenderContext();
    var formatter = new Vex.Flow.Formatter();

    var vfVoice = new Vex.Flow.Voice({
      num_beats: 4,
      beat_value: 4,
      resolution: Vex.Flow.RESOLUTION
    })
    .setStrict(false);

    this.graceNotes = this._graceNotes.map(item => {
      var graceNote;
      if (typeof item==='string' || Array.isArray(item)) {
        graceNote = new GraceNote({
          pitches: item,
          note: this.note,
          stave: this.stave
        });
      }
      else {
        graceNote = this.createGraceNoteFromStruct(item)
      }
      return graceNote.setRenderContext(ctx).render();
    });

    const vfGraceNotes = this.graceNotes.map(gn => gn._vfnote);
    vfVoice.addTickables(vfGraceNotes);
    formatter.joinVoices([vfVoice]).format([vfVoice], 0);

    const modCtx = this.note._vfnote.modifierContext;
    var x = this.note._vfnote.getTickContext().getX()-modCtx.width;

    for (let i=vfGraceNotes.length-1; i>=0; i--) {
      let graceNote = this.graceNotes[i];
      let vfGN = vfGraceNotes[i];
      vfGN.getTickContext().setX(x-12);
      vfGN.setContext(ctx).draw();
      x = vfGN.getTickContext().getX();
      let elem = vfGN.elem;
      elem.setAttribute("id", graceNote.id);
      graceNote.elem = elem;
    };

    this.graceNotes.forEach(gn => gn.hasStem===true && gn.drawStem());

    //this.isBeamed && this.drawBeam();

    return this;
  }

}
