import { Utils } from "../utils";
import Canvas from './canvas';
import GeneralizedEvent from './generalized-event';
import { NoteTools } from "./tools/scoretools/note-tools";

class GridCell {

  constructor(options) {
    this.column = options.column;
    this.row = options.row;
    this.x = options.x;
    this.y = options.y;
    this.width = options.width;
    this.height = options.height || 20;
    this.data = options.data;
    this.id = options.id || Utils.guid();
    this.renderOptions = options.renderOptions || {};
    this.renderCallback = options.renderCallback || this.renderCellEvents;
    let { x, y, width, height } = this;
    this.initialMetrics = { x, y, width, height };
  }

  getRenderContext() {
    return this.ctx;
  }

  setRenderContext(ctx) {
    this.ctx = ctx;
    return this;
  }

  getBoundingBox() {
    return {
      x: this.x,
      y: this.y,
      w: this.width,
      h: this.height
    }
  }

  getElem() {
    return this.elem;
  }

  renderCellEvents() {
    const ctx = this.getRenderContext();
    let { row, column, x: cellX, y: cellY, width: cellWidth, height: cellHeight } = this;
    let { events } = this.data;
    this.eventObjects = events.map(event => {
      let { x, y, type="notehead", size } = event;
      const cellEvent = new GeneralizedEvent({
        x: cellX+(cellWidth*x),
        y: cellY+(cellHeight*y),
        duration: 60, // Just hard-coding this for now; doesn't mean anything.
        type: type,
        size: size
      });
      cellEvent.cell = { row, column };
      return cellEvent.setRenderContext(ctx).render();
    });
    return this;
  }

  render() {
    var ctx = this.getRenderContext();
    ctx.rect(this.x, this.y, this.width, this.height, {
      stroke: "none",
      fill: "rgba(0,0,0,0)"
    });
    var elem = ctx.parent.lastChild;
    elem.setAttribute("class", "grid-score-cell");
    elem.setAttribute("id", "vf-"+this.id);
    this.elem = elem;
    if (this.data) this.renderCallback(); //this.renderCellEvents();
    return this;
  }

}

var DEFAULT_METRICS = {
  cellWidth: 20,
  cellHeight: 20,
  marginTop: 20,
  marginRight: 20,
  marginBottom: 20,
  marginLeft: 20
};

var DEFAULT_RENDER_OPTIONS = {
  lineWidth: 1.5,
  borderStyle: "full",
  borderWidth: 1.5,
  lineType: "solid",
  cellWidth: 40,
  cellHeight: 40,
  cellRenderCallback: null
};

function createNullCells(numRows, numCols) {
  var nulls = Utils.repeatN(numRows, () => {
    return Utils.repeatN(numCols, () => {
      return null;
    });
  });
  return nulls;
}

export default class GridScore extends Canvas {

  constructor(options) {

    super(options);

    Vex.Flow.DURATION_FORMAT = "absolute";
    Vex.Flow.durationToGlyph.duration_codes.absolute.type.h.code_head = "v46";

    var numRows = options.data.rows || 1;
    var numCols = options.data.columns || 1;
    var cells = options.data.cells;

    if (!cells || Utils.isEmptyArray(cells)) {
      this.cells = createNullCells(numRows, numCols);
    }

    else if (Utils.isArray(cells)) {
      if (Utils.isObject(cells[0])) {
        this.cells = createNullCells(numRows, numCols);
        cells.forEach((cellDef, index) => {
          var target = cellDef.target || [index,0];
          var row = target[0];
          var col = target[1];
          var obj = {};
          for (var key in cellDef) {
            if (key!="target") {
              obj[key] = cellDef[key];
            }
          }
          this.cells[row][col] = obj;
        });
      }
      else {
        cells.forEach((row, rowIndex) => {
          var rowLength = row.length;
          if (rowLength>numCols) numCols = rowLength;
          if (rowIndex+1>numRows) numRows = rowIndex+1;
        });
        this.cells = Utils.repeatN(numRows, rowIndex => {
          var row = cells[rowIndex];
          return Utils.repeatN(numCols, colIndex => {
            var cell;
            if (!row || !row[colIndex]) {
              cell = null;
            }
            else {
              cell = row[colIndex];
            }
            return cell;
          });
        });
      }
    }

    //this.cells = options.data.cells || [];
    this.columnDefs = options.data.columnDefs || [];
    this.rowDefs = options.data.rowDefs || [];

    this.metrics = { ...DEFAULT_METRICS };
    if (typeof options.metrics == "object") {
      Utils.merge(this.metrics, options.metrics);
    }

    this.metrics.x = this.metrics.marginLeft || 0;
    this.metrics.y = this.metrics.marginTop || 0;

    this.renderOptions = { ...DEFAULT_RENDER_OPTIONS };
    if (typeof options.data.renderOptions == "object") {
      Utils.merge(this.renderOptions, options.data.renderOptions);
    }

    this._cells = [];
    this.createCells();

    var topRight = this.getCell(0, this.numCols()-1);
    var bottomLeft = this.getCell(this.numRows()-1, 0);
    this.metrics.width = topRight.x+topRight.width-this.metrics.x;
    this.metrics.height = bottomLeft.y+bottomLeft.height-this.metrics.y;

    this.zoomLevel = 100;

    this.isSpaceTimeGrid = options.isSpaceTimeGrid;
    this.isSpaceTimeGrid && (this.duration = this.metrics.width);

  }

  getRow(row) {
    return this._cells[row];
  }

  getColumn(col) {
    var column = this._cells.map(row => row[col]);
    return column;
  }

  numRows() {
    return this._cells.length;
  }

  numCols() {
    return this._cells[0].length;
  }

  getRowForCell(cell) {
    return this.getRow(cell.row);
  }

  getColumnForCell(cell) {
    return this.getColumn(cell.column);
  }

  getRowInfo(row) {
    const { rowDefs } = this;
    return rowDefs && rowDefs[row].info;
  }

  getColumnInfo(col) {
    const { columnDefs } = this;
    return columnDefs && columnDefs[col].info;
  }

  getCell(row, column) {
    return this._cells[row][column];
  }

  getCellByID(id) {
    var cell;
    for (var i=0; i<this.numRows(); i++) {
      var row = this.getRow(i);
      for (var j=0; j<this.numCols(); j++) {
        if (row[j].id==id) {
          cell = row[j];
          break;
        }
      }
    }
    return cell;
  }

  forCells(fn) {
    for (let i=0; i<this.numRows(); i++) {
      for (let j=0; j<this.numCols(); j++) {
        let cell = this.getCell(i, j);
        fn(cell, i, j);
      }
    }
  }

  flatten() {
    const flat = [];
    this.forCells(cell => flat.push(cell));
    return flat;
  }

  eventObjectToDynamic(eventObject) {
    let { y, cell } = eventObject;
    let { row, column } = cell;
    let { height: cellHeight, y: cellY } = this.getCell(row, column);
    return NoteTools.numberToDynamic(Math.floor(cellHeight-(y-cellY)), { max: cellHeight });
  }

  getMetrics() {
    return this.metrics;
  }

  getDuration() {
    return this.duration;
  }

  getZoomLevel() {
    return this.zoomLevel;
  }

  setZoomLevel(pcnt) {
    this.zoomLevel = pcnt;
    return this;
  }

  zoom(pcnt) {
    this.setZoomLevel(pcnt).clear().render();
    return this;
  }

  calculateZoomWidth() {
    var width = this.getMetrics().width;
    var zoomLevel = this.zoomLevel/100;
    var zoomWidth = Math.floor(width*zoomLevel);
    return zoomWidth;
  }

  // This calls renderer.resize, and so sets the dimensions of the entire SVG 'canvas'. Perhaps shouldn't be a public method.
  resize(width, height) {
    this.renderer.resize(width, height);
    // We also need to then rescale. This seems to fix the distortion that's sometimes been occurring when we move between notations in the
    // app. This is due, it seems, to the viewBox not being scaled to the new viewport, and scaling here appears to fix this.
    var scaleX = this.scaleX || 1;
    var scaleY = this.scaleY || 1;
    this.getRenderContext().scale(scaleX, scaleY);
    return this;
  }

  drawColumnInfos() {
    var ctx = this.getRenderContext();
    this.columnDefs.forEach((colDef, colIndex) => {
      var { info } = colDef;
      if (info) {
        var col = this.getColumn(colIndex);
        var colLeft = col[0].x, colRight = colLeft+col[0].width,
            colTop = this.metrics.y, colBottom = this.metrics.y+this.metrics.height;
        // These info properties are specific to Notation Y, but we need to
        // generalize this method. One nice way would be to let the client
        // define a render callback for each info (either per info as an
        // extra property or a single callback passed in as a separate option).
        var tw1 = ctx.measureText(info.duration).width;
        var tw2 = ctx.measureText(info.numRowDivisions).width;
        ctx.save();
        ctx.setFont("Arial", 12);
        ctx.fillText(info.duration, colRight-(tw1/3), colTop-5);
        ctx.setFont("Arial", 16);
        ctx.fillText(info.numRowDivisions, colLeft-(tw2/3), colBottom+20);
        ctx.restore();
      }
    });
  }

  drawRowInfos() {
    var ctx = this.getRenderContext();
    this.rowDefs.forEach((rowDef, rowIndex) => {
      var { info } = rowDef;
      if (info) {
        var row = this.getRow(rowIndex);
        var { soundType, xAdjust=22, alignCentre=true, font={ family: "Arial", size: 12 } } = info;
        var { family, size } = font;
        // These info properties are specific to Notation AC, but we need to
        // generalize this method. One nice way would be to let the client
        // define a render callback for each info (either per info as an
        // extra property or a single callback passed in as a separate option).
        var { width: tw, height: th } = ctx.measureText(soundType);
        var alignment = alignCentre ? tw/3 : 0;
        var x = this.metrics.x - xAdjust - alignment;
        var y = row[0].y + th + 5;
        ctx.save();
        ctx.setFont(family, size);
        ctx.fillText(soundType, x, y);
        ctx.restore();
      }
    });
  }

  drawCells() {
    var ctx = this.getRenderContext();
    var attr = {
      fill: "none",
      "stroke-width": this.renderOptions.lineWidth,
      stroke: "black"
    };
    if (this.renderOptions.lineType=="dash") attr["stroke-dasharray"] = [12,6];
    // Draw the column lines;
    var bottomRow = this.getRow(this.numRows()-1);
    var y1 = this.metrics.y;
    var y2 = this.metrics.y+this.metrics.height;
    for (var i=1; i<bottomRow.length; i++) {
      var x = bottomRow[i].x;
      ctx.line(x, y1, x, y2, attr);
    }
    // Draw the row lines;
    var rightCol = this.getColumn(this.numCols()-1);
    var x1 = this.metrics.x;
    var x2 = this.metrics.x+this.calculateZoomWidth(); //this.metrics.width;
    for (var i=1; i<rightCol.length; i++) {
      var y = rightCol[i].y;
      ctx.line(x1, y, x2, y, attr);
    }
  }

  drawFullBorder(top, right, bottom, left) {
    var ctx = this.getRenderContext();
    ctx.beginPath();
    ctx.moveTo(left, top);
    ctx.lineTo(right, top);
    ctx.lineTo(right, bottom);
    ctx.lineTo(left, bottom);
    ctx.closePath();
    ctx.stroke();
  }

  drawBorder() {
    var ctx = this.getRenderContext();
    // Saving and later restoring the attribute doesn't seem to work with this method,
    // so we have to save and restore it locally.
    let oldLineWidth = ctx.lineWidth; //ctx.save();
    ctx.lineWidth = this.renderOptions.borderWidth;
    let { x: left, y: top, width, height } = this.metrics;
    let right = left + this.calculateZoomWidth(); //width;
    let bottom = top + height;
    const { borderStyle } = this.renderOptions;
    if (borderStyle==="full" || borderStyle===undefined) {
      this.drawFullBorder(top, right, bottom, left);
    }
    else {
      const segPositions = borderStyle.split(",");
      segPositions.forEach(posn => {
        switch (posn) {
          case "t":
            ctx.line(left, top, right, top);
            break;
          case "r":
            ctx.line(right, top, right, bottom);
            break;
          case "b":
            ctx.line(left, bottom, right, bottom);
            break;
          case "l":
            ctx.line(left, top, left, bottom);
            break;
        }
      });
    }
    ctx.lineWidth = oldLineWidth; //ctx.restore();
  }

  createCells() {
    var x = this.metrics.x;
    var y = this.metrics.y;
    var i = 0;
    if (this.rowDefs) {
      this.rowDefs.sort((a,b) => {
        return a.target-b.target;
      });
    }
    if (this.columnDefs) {
      this.columnDefs.sort((a,b) => {
        return a.target-b.target;
      });
    }
    this.cells.forEach((row, rowIndex) => {
      var rowDef = this.rowDefs[i];
      var height = this.renderOptions.cellHeight; //40;
      var data;
      if (rowDef && rowDef.target===rowIndex) {
        height = rowDef.height;
        data = rowDef.data;
        i+=1;
      }
      this._cells.push([]);
      var j = 0;
      row.forEach((col, colIndex) => {
        var colDef = this.columnDefs[j];
        var width = this.renderOptions.cellWidth; //40;
        if (colDef && colDef.target===colIndex) {
          width = colDef.width;
          j+=1;
        }
        var cell = new GridCell({
          x: x,
          y: y,
          width: width,
          height: height,
          column: colIndex,
          row: rowIndex,
          data: data ? data[colIndex] : col,
          renderCallback: this.renderOptions.cellRenderCallback
        });
        this._cells[rowIndex].push(cell);
        x += width;
      });
      x = this.metrics.x;
      y += height;
    });
  }

  preRender() {}

  postRender() {}

  render() {
    var ctx = this.getRenderContext();
    this.preRender();
    var zoomWidth = this.calculateZoomWidth();
    var marginLeft = this.metrics.marginLeft;
    var marginRight = this.metrics.marginRight;
    var diff = Math.floor((this.el.offsetWidth-zoomWidth)/2);
    marginLeft = Math.max(this.metrics.marginLeft, diff);
    marginRight = Math.max(this.metrics.marginRight, diff);
    var newWidth = zoomWidth+marginLeft+marginRight; //this.metrics.width+marginLeft+marginRight;
    var h = this.metrics.marginTop+this.metrics.height+this.metrics.marginBottom;
    this.resize(newWidth, h);
    this.metrics.x = marginLeft;
    this._cells.forEach(row => {
      row.forEach(cell => {
        let cellNewX = ((cell.initialMetrics.x-this.metrics.marginLeft)/this.metrics.width)*zoomWidth;
        cell.x = cellNewX; //-this.metrics.marginLeft; //cell.x-this.metrics.marginLeft;
        cell.x += this.metrics.x;
        cell.width = (cell.initialMetrics.width/this.metrics.width)*zoomWidth;
        cell.setRenderContext(ctx).render();
      });
    });
    this.drawBorder();
    this.drawCells();
    this.drawColumnInfos();
    if (this.rowDefs) this.drawRowInfos();
    this.postRender();
    return this;
  }

}
