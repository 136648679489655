import { Utils } from '../utils';
import System from './system';
import Stave from './stave';
import Dynamic from './dynamic';
import { SpannerFactory } from './spanners/spanner-factory';
import { Graphics } from './graphics';
import { NoteTools } from './tools/scoretools/note-tools';
import { bindAll } from 'lodash';

/**
 * Class representing a space-time score.
 * @class CC.Core.Score
 * @param {Object} options - Score options.
 * @param {string} options.title The score title.
 * @param {Object} options.el The DOM element (a div) to which to attach and render the score.
 * @param {Object} options.data The input data, defining the staves, voices and pitch content for the score.
 * @param {Object} options.metrics An object of options defining the metrics for the score (width, height, margins, etc.).
 * @param {boolean} options.isGrandStaff Determines whether or not the score should be of the 'Grand Staff' type.
 */

var DEFAULT_METRICS = {
  width: 0,
  height: 0,
  marginTop: 40, //6,
  marginRight: 20,
  marginBottom: 30, //80,
  marginLeft: 20,
  staveLineSpacing: 10,
  spaceAboveStaffLine: 4,
  spaceBelowStaffLine: 4
};

// Helper function for drag filament drag handlers.
const isLine = (elem) => {
  return elem.nodeName.toLowerCase() === "line";
};


export default class Score {

  constructor(options) {

    this.title = options.title;
    this.el = options.el;
    this.data = options.data;
    // The metrics object represents the metrics of the musical system, not the 'canvas'.
    this.metrics = Utils.merge({}, DEFAULT_METRICS);
    if (typeof options.metrics == "object") {
      Utils.merge(this.metrics, options.metrics);
    }

    this.autoCentre = options.hasOwnProperty("autoCentre") ?  options.autoCentre : true;
    this.autoSpaceStaves = options.hasOwnProperty("autoSpaceStaves") ?  options.autoSpaceStaves : true;

    this.clusterRenderType = options.clusterRenderType || "bar"; // One of 'bar' (line between top and bottom notes), or 'wedge' (short, thick line above top note).

    this.isGrandStaff = options.isGrandStaff || false;

    if (this.isGrandStaff) {
      this.metrics.spaceAboveStaffLine = 1;
      this.metrics.spaceBelowStaffLine = 1;
    }

    this.zoomLevel = 100;
    this.systems = [];

    this.spanners = {
      "spanner": [],
      "noteGroup": [],
      "pedalMark": [],
      "dynamics": []
    };

    // Reinstating this here for now. Should really be in ScoreContainerView, as the Score
    // module should only be concerned with rendering things, not handling user interaction.
    this.currentSelection;

    Vex.Flow.DURATION_FORMAT = "absolute";
    Vex.Flow.durationToGlyph.duration_codes.absolute.type.h.code_head = "v46";

    if (this.el) {
      this.ensureRenderContext();
    }

    this.showStaveConnectors = (typeof options.showStaveConnectors==="undefined") ? true : options.showStaveConnectors;

    this.showTimeGrid = false;
    this.numTimeGridDivisions = 3;
    this.showDynamics = options.showDynamics || false;
    this.trackSimultaneities = true; //options.trackSimultaneities;

    bindAll(this, "startDrag", "drag", "endDrag");

    if (options.onStartDrag) { this.onStartDrag = options.onStartDrag };
    if (options.onDrag) { this.onDrag = options.onDrag };
    if (options.onDragEnd) { this.onDragEnd = options.onDragEnd };
    
    this.addEventHandlers();

    // For now we're just going to let the Score object build itself. Too much code relies on
    // the old initialization format (options object) to be worth changing it yet.
    Score.build(this);

  }

  addEventHandlers() {
    this.el.addEventListener("mousedown", this.startDrag);
    this.el.addEventListener("mousemove", this.drag);
    this.el.addEventListener("mouseup", this.endDrag);
    this.el.addEventListener("mouseleave", this.endDrag);
  }

  removeEventHandlers() {
    this.el.removeEventListener("mousedown", this.startDrag);
    this.el.removeEventListener("mousemove", this.drag);
    this.el.removeEventListener("mouseup", this.endDrag);
    this.el.removeEventListener("mouseleave", this.endDrag);
  }

  /*
    static build(options) {
      var score = new Score(options);
      var data = score.data;
      var system = new System({});
      data.staves.forEach(function(staveOpts) {
        var stave = Stave.build(staveOpts);
        system.addStave(stave);
      });
      score.addSystem(system);
      return score;
    }
  */

  static build(score) {
    const { data } = score;
    const { staves } = data;
    const system = new System({});
    staves.forEach(staveOpts => {
      const stave = Stave.build({
        lineSpacing: score.metrics.staveLineSpacing,
        spaceAboveStaffLine: score.metrics.spaceAboveStaffLine,
        spaceBelowStaffLine: score.metrics.spaceBelowStaffLine,
        ...staveOpts
      });
      system.addStave(stave);
    });
    score.addSystem(system);
    score.showDynamics===true && score.toggleDynamics();
    data.spanners && score.buildSpanners(data.spanners);
    score.trackSimultaneities===true && score.buildSimultaneities();
    return score;
  }

  buildSpanners(optsArray) {
    var spannerFactory = new SpannerFactory();
    optsArray.forEach(function(opts) {
      var type = opts.type
      var spanner = spannerFactory.createSpanner(opts);
      spanner.setTarget(this).attach();
      this.spanners[type].push(spanner);
    }, this);
    return this.spanners;
  }

  getSpanners(type) {
    var spanners = type ? this.spanners[type] : this.spanners;
    return spanners;
  }

  buildSimultaneities() {
    const notes = this.getNotes();
    const simultaneities = NoteTools.getSimultaneities(notes);
    const vals = Object.values(simultaneities);
    if (vals.length>0) {
      vals.forEach(notes => {
        notes.forEach(note => {
          note.isSimultaneityPart = true;
        });
        if (NoteTools.isCrossStaffCluster(notes)) {
          /*
          notes.forEach(note => {
            note.isCrossStaffClusterPart=true;
            //console.log(note);
          });
          */
         notes[0].crossStaffClusterPart="upper";
         notes[1].crossStaffClusterPart="lower";
        }
      });
      this.simultaneities = simultaneities;
    }
    return this;
  }

  /********* START OF EVENT HANDLERS SECTION *********/

  toggleSelection(note) {
    var sel = this.getCurrentSelection();
    //sel && !(sel===note) && Graphics.setElemColor($(sel.getElem()), "black");
    sel && Graphics.setElemColor($(sel.getElem()), "black");
    this.setCurrentSelection(note);
  }

  // The following drag & drop code is adapted from here:
  // http://www.petercollingridge.co.uk/tutorials/svg/interactive/dragging/

  getSVGMousePosition(svg, e) {
    const CTM = svg.getScreenCTM();
    return {
      x: (e.clientX - CTM.e) / CTM.a,
      y: (e.clientY - CTM.f) / CTM.d
    };
  }

  startDrag(e, config) {
    const ctx = this.getRenderContext();
    const { svg } = ctx;
    const elem = e.target.closest(".draggable");    
    if (elem) {
      this.dragging = {
        elem: elem,
        svg: svg,
        offset: this.getSVGMousePosition(svg, e),
        transform: null,
        ...config
      };
      // Get all the transforms currently on this element.
      var transforms = elem.transform.baseVal;
      // Ensure the first transform is a 'translate' transform.
      if (transforms.length === 0 || transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE) {
        // Create a transform that translates by (0, 0).
        var translate = svg.createSVGTransform();
        translate.setTranslate(0, 0);
        // Add the translation to the front of the transforms list.
        elem.transform.baseVal.insertItemBefore(translate, 0);
      }
      // Get initial translation amount.
      this.dragging.transform = transforms.getItem(0);
      this.dragging.offset.x -= this.dragging.transform.matrix.e;
      this.dragging.offset.y -= this.dragging.transform.matrix.f;
      // Run onStartDrag callback.
      this.onStartDrag && this.onStartDrag(e);
    }
  }

  drag(e) {
    if (this.dragging) {
      e.preventDefault();
      const { svg } = this.dragging;
      let { x, y } = this.getSVGMousePosition(svg, e);
      const { offset, transform, minX, maxX, minY, maxY, axis } = this.dragging;
      // Compute dx and dy values (0 for single axis)...
      let dx = axis==="y" ? 0 : x-offset.x;
      let dy = axis==="x" ? 0 : y-offset.y;
      // Handle drag region constraints...
      // x constraint...
      if (dx < minX) { dx = minX; }
      else if (dx > maxX) { dx = maxX; }
      // y constraint...
      if (dy < minY) { dy = minY; }
      else if (dy > maxY) { dy = maxY; }
      // Update the transform...
      transform.setTranslate(dx, dy);
      // Run onDrag callback.
      this.onDrag && this.onDrag(e);
    }
  }

  endDrag(e) {
    // Run onDragEnd first in case it needs to access drag data.
    this.onDragEnd && this.onDragEnd(e);
    this.dragging = null;
  }

  onStartDrag(e) {
    const elem = e.target;
    if (elem.matches(".dynamic")) {
      const dragFilament = Graphics.siblings(elem, isLine)[0];
      dragFilament.setAttribute("stroke-opacity", 1);
    }
  }

  onDrag(e) {
    const elem = e.target;
    const $el = this.getElem();
    if (elem.matches(".dynamic")) {
      let { top, left } = Graphics.offset($el);
      let documentScrollLeft = document.body.scrollLeft;
      let documentScrollTop = document.body.scrollTop;
      let scoreScrollLeft = $el.scrollLeft;
      let scoreScrollTop = $el.scrollTop;
      let { x, y } = elem.getBoundingClientRect();
      let dragFilament = Graphics.siblings(elem, isLine)[0];
      let offsetX = documentScrollLeft + scoreScrollLeft - left;
      let offsetY = documentScrollTop + scoreScrollTop - top;
      dragFilament.setAttribute("x1", x + offsetX);
      dragFilament.setAttribute("y1", y + offsetY);
    }
  }

  onDragEnd(e) {
    const elem = e.target;
    if (elem.matches(".dynamic")) {
      const dragFilament = Graphics.siblings(elem, isLine)[0];
      dragFilament.setAttribute("stroke-opacity", 0);
    }
  }

  /********* END OF EVENT HANDLERS SECTION *********/

  getTitle() {
    return this.title;
  }

  setTitle(string) {
    title = string;
  }

  getElem() {
    return this.el;
  }

  setElem(elem) {
    this.el = elem;
    return this;
  }

  getRenderContext() {
    return this.ctx;
  }

  ensureRenderContext() {
    if (!this.ctx) {
      this.renderer = new Vex.Flow.Renderer(this.getElem(), Vex.Flow.Renderer.Backends.SVG);
      this.ctx = this.renderer.getContext();
    }
    return this.ctx;
  }

  getMetrics() {
    return this.metrics;
  }

  getScrollWidth() {
    let width = this.getRenderContext().svg.getAttribute("width");
    return parseInt(width);
  }

  getScrollHeight() {
    let height = this.getRenderContext().svg.getAttribute("height");
    return parseInt(height);
  }

  getData() {
    return this.data;
  }

  setData(data) {
    this.data = data;
    return this;
  }

  toggleDynamics() {
    var notes = this.getNotes();
    var val = this.showDynamics;
    // If we want to show dynamics, set the 'showDynamic' property of any notes with dynamics to 'true'.
    notes.forEach(note => {
      if (note.getDynamic()) {
        note.showDynamic = val;
      }
    });
    return this;
  }

  getDuration() {
    var notes = this.getNotes();
    var lastNote = notes[0];
    notes.forEach(note => {
      var onset = note.getOnset();
      if (onset>lastNote.getOnset()) {
        lastNote = note;
      }
    });
    var lastNoteOnset = lastNote.getOnset();
    var lastNoteDuration = parseInt(lastNote.getDuration());
    return lastNoteOnset+lastNoteDuration;
  }

  getZoomLevel() {
    return this.zoomLevel;
  }

  setZoomLevel(pcnt) {
    this.zoomLevel = pcnt;
    return this;
  }

  getCurrentSelection() {
    return this.currentSelection;
  }

  setCurrentSelection(val) {
    this.currentSelection = val;
  }

  getSystems() {
    return this.systems;
  }

  getSystem(index) {
    return this.systems[index];
  }

  addSystem(sys) {
    return this.systems.push(sys);
  }

  getStaves() {
    return this.getSystems()[0].getStaves();
  }

  getVoices() {
    var voices = [];
    this.getStaves().forEach(function(stave) {
      stave.getVoices().forEach(function(voice) {
        voices.push(voice);
      });
    });
    return voices;
  }

  getNotes() {
    var notes = [];
    this.getVoices().forEach(function(voice) {
      voice.getNotes().forEach(function(note) {
        notes.push(note);
      });
    });
    return notes;
  }

  setDynamics() {
    var notes = this.getNotes();
    var exprs = ["ppp", "pp", "p", "mp", "mf", "f", "ff", "fff"];
    notes.forEach(function(note) {
      var expr = Utils.getRandElem(exprs);
      var dynamic = new Dynamic({
        text: expr
      });
      note.setDynamic(dynamic);
    });
    return this;
  }

  /*
  getNoteFromID(id) {
    var notes = this.getNotes();
    var match = null;
    var id = id.substring(3); // Since VexFlow adds a 'vf-' prefix to the id we need to strip it.
    for (var i = 0; i < notes.length; i++) {
      var note = notes[i];
      if (note.getID() === id) {
        match = note;
      }
    }
    return match;
  }
  */

  getNoteFromID(id) {
    var notes = this.getNotes();
    var match = null;
    for (let i=0; i<notes.length; i++) {
      var note = notes[i];
      // Since VexFlow adds a 'vf-' prefix to the id we need to strip it.
      if (note.getID() === id.substring(3)) {
        match = note;
      }
      else if (note.graceNoteGroup) {
        let { graceNotes } = note.graceNoteGroup;
        for (let j=0; j<graceNotes.length; j++) {
          var graceNote = graceNotes[j];
          if (graceNote.getID() === id) {
            match = graceNote;
          }
        }
      }
    }
    return match;
  }

  numStaves() {
    return this.getStaves().length;
  }

  zoom(pcnt) {
    this.setZoomLevel(pcnt).clear().render();
    return this;
  }

  calculateZoomWidth() {
    var width = this.getMetrics().width;
    var zoomLevel = this.zoomLevel/100;
    var zoomWidth = Math.floor(width*zoomLevel);
    return zoomWidth;
  }

  timepointForX(x) {
    var totalTime = this.getDuration();
    var stave = this.getStaves()[0];
    var width = stave.getEffectiveWidth();
    x = x-stave.getNoteStartX();
    var timePoint = Math.floor((x/width)*totalTime);
    return timePoint;
  }

  xForTimePoint(tp) {
    var totalTime = this.getDuration();
    var stave = this.getStaves()[0];
    var width = stave.getEffectiveWidth();
    var startX = stave.getNoteStartX();
    var x = startX+Math.floor((tp/totalTime)*width);
    return x;
  }

  clear() {
    var ctx = this.getRenderContext();
    ctx.clear();
    //It seems we need a call to preserveAspectRatio after clearing. For more see: https://groups.google.com/d/msg/vexflow/mrCN4grzAUs/vD9yNA1HUhcJ
    ctx.svg.setAttribute("preserveAspectRatio", "xMinYMin meet"); // or "xMinYMin slice"?
    return this;
  }

  // This calls renderer.resize, and so sets the dimensions of the entire SVG 'canvas'. Perhaps shouldn't be a public method.
  resize(width, height) {
    this.renderer.resize(width, height);
    // We also need to then rescale. This seems to fix the distortion that's sometimes been occurring when we move between notations in the
    // app. This is due, it seems, to the viewBox not being scaled to the new viewport, and scaling here appears to fix this.
    var scaleX = this.scaleX || 1;
    var scaleY = this.scaleY || 1;
    this.getRenderContext().scale(scaleX, scaleY);
    return this;
  }

  scale(x, y) {
    this.getRenderContext().scale(x, y);
  }

  getWidth() {
    return this.getMetrics().width;
  }

  setWidth(width) {
    this.getMetrics().width = width;
    this.clear().render();
    return this;
  }

  // We currently set the height dynamically on rendering, so this does nothing.
  setHeight(height) {
    this.getMetrics().height = height;
    this.clear().render();
    return this;
  }

  getMargins() {
    return {
      marginTop: this.metrics.marginTop,
      marginRight: this.metrics.marginRight,
      marginBottom: this.metrics.marginBottom,
      marginLeft: this.metrics.marginLeft
    }
  }

  setMargins(top, right, bottom, left) {
    this.setMetrics({
      marginTop: top,
      marginRight: right,
      marginBottom: bottom,
      marginLeft: left
    });
  }

  // Set metrics by passing in key value pairs.
  setMetrics(obj) {
    if (typeof obj == "object") {
      Utils.merge(this.metrics, obj);
    }
    this.clear().render();
    return this;
  }

  drawPedalMark(options) {
    const ctx = this.getRenderContext();
    let totalDur = this.getDuration();
    let stave = this.getStaves()[this.numStaves()-1];
    let width = stave.getEffectiveWidth();
    let noteStartX = stave.getNoteStartX();
    let bottomY = stave.getYForLine(4);
    let attr = { stroke: "black", "stroke-width": 1.5 }
    let { startX=0, endX=totalDur, yShift=10, dashStyle=[4,3] } = options;
    let x1 = noteStartX+(width*(startX/totalDur));
    let x2 = noteStartX+(width*(endX/totalDur));
    let y = bottomY+yShift;
    ctx.line(x1, y, x2, y, { ...attr, "stroke-dasharray": dashStyle });
    ctx.line(x1, y-10, x1, y, attr);
    ctx.line(x2, y-10, x2, y, attr);
  }

  drawTimeGrid() {
    var ctx = this.getRenderContext();
    var numDivisions = this.numTimeGridDivisions;
    var staves = this.getStaves();
    var topStave = staves[0];
    var bottomStave = staves[this.numStaves()-1];
    var startX = topStave.getNoteStartX();
    var endX = topStave.getNoteEndX();
    var width = topStave.getEffectiveWidth();
    var dx = width/numDivisions;
    var y1 = topStave.getYForLine(0)+2;
    var y2 = bottomStave.getYForLine(topStave.numLines-1);
    var attr = { "stroke": "black", "stroke-width": 1, "stroke-dasharray": [2,3] };
    for (var i = startX; i < endX; i += dx) {
      var x = Math.round(i)+1;
      //Graphics.drawDashedLine(ctx, x, y1, x, y2, [2,3]);
      let line = ctx.line(x, y1, x, y2, attr).parent.lastChild;
      line.setAttribute("class", "time-grid-line");
    }
  }

  removeTimeGrid() {
    Utils.removeElementsByClass("time-grid-line");
  }

  toggleTimeGrid() {
    let { showTimeGrid } = this;
    this.showTimeGrid = !showTimeGrid;
    this.showTimeGrid===true ? this.drawTimeGrid() : this.removeTimeGrid();
  }

  drawSpanners() {
    var ctx = this.getRenderContext();
    var spanners = this.spanners;
    for (var key in spanners) {
      var _spanners = spanners[key];
      if (_spanners) {
        _spanners.forEach(spanner => {
          spanner.setRenderContext(ctx).render();
       });
      }
    }
  }

  serialize() {
    var staves = this.getStaves().map(function(stave) {
      return stave.serialize();
    });
    return {
      staves: staves
    }
  }

  drawDynamicStaves(x, width) { // = marginLeft, zoomWidth
    const ctx = this.getRenderContext();
    const staves = this.getStaves();
    let yMin = this.metrics.marginTop;

    staves.forEach((stave, i) => {

      stave.setX(x);
      stave.setY(yMin);
      stave.setWidth(width);

      stave.adjustMetricsForMinMaxNotes(i);

      stave.setRenderContext(ctx).render();

      // Render each voice in the stave.
      stave.getVoices().forEach(voice => {
        voice.setStave(stave);
        voice.format().render(ctx);
      });

      yMin = Math.max(stave.getMinNoteY(), stave.getYForBottomLine());

    });

    return yMin;
  }

  drawGrandStaff(x, width) {
    const ctx = this.getRenderContext();
    const staves = this.getStaves();

    // Sometimes (as in notation AK) the realisation for a grand staff
    // notation might return just a single stave. In that circumstance
    // we default to dynamic stave rendering.
    if (staves.length===1) {
      return this.drawDynamicStaves(x, width);
    }

    let yMin = this.metrics.marginTop;

    const formatter = new Vex.Flow.Formatter();
    const formatOpts = {formatter: formatter, formatToGrandStaff: true};
    let justifyWidth;
    const vfvoices = [];

    staves.forEach((stave, i) => {

      stave.setX(x);
      stave.setY(yMin);
      stave.setWidth(width);

      stave.adjustMetricsForMinMaxNotes(i, this.isGrandStaff);

      stave.setRenderContext(ctx).render();
      !justifyWidth && (justifyWidth = stave.getNoteEndX()-stave.getNoteStartX()-10);
      //!justifyWidth && (justifyWidth = width-(stave.getNoteStartX()-x));

      // Format each voice in the stave.
      stave.getVoices().forEach(voice => {
        voice.setStave(stave);
        const vfvoice = voice.format(formatOpts)._vfvoice;
        vfvoices.push(vfvoice);
      });

      yMin = i===0 ? stave.getYForBottomLine()+1 : Math.max(stave.getMinNoteY(), stave.getYForBottomLine());

    });

    const [ stave1, stave2 ] = staves;
    const startX = Math.max(stave1.getNoteStartX(), stave2.getNoteStartX());
    stave1.setNoteStartX(startX);
    stave2.setNoteStartX(startX);

    formatter.formatToGrandStaff(vfvoices, justifyWidth);
    staves.forEach(stave => {
      stave.getVoices().forEach(voice => voice.render(ctx));
    });

    return yMin;
  }

  format() {}

  preRender() {}

  postRender() {}

  render(options) {

    this.ensureRenderContext();

    // We propably want more render options than just this.
    var renderOpts = {
      clear: false
    };

    if (typeof options == "object") {
      Utils.merge(renderOpts, options);
    }

    // If the 'clear' renderOpt is true then preclear the score before drawing.
    // Note that there are some VexFlow viewbox issues if calling this on the initial draw.
    if (renderOpts.clear===true) {
      this.clear();
    }

    this.preRender();

    var ctx = this.getRenderContext();
    ctx.setFont("Arial", 10, ""); //.setBackgroundFillStyle(bg);

    // We're assuming just one system now, so the input data still maps to a set of staves.
    var system = this.systems[0];

    var zoomWidth = this.calculateZoomWidth(); //Math.floor(this.getMetrics().width*this.zoomLevel/100);

    var marginLeft = this.metrics.marginLeft;
    var marginRight = this.metrics.marginRight;

    this.resize(zoomWidth+marginLeft+marginRight, 300);

    if (this.autoCentre===true) {
      var diff = Math.floor((this.el.offsetWidth-zoomWidth)/2);
      marginLeft = Math.max(this.metrics.marginLeft, diff);
      marginRight = Math.max(this.metrics.marginRight, diff);
    }

    // Until we can resolve the remaining issues with grand staff rendering we'll continue to draw dynamic staves.
    //var yMin = this.drawDynamicStaves(marginLeft, zoomWidth);
    var yMin = this.isGrandStaff===true ? this.drawGrandStaff(marginLeft, zoomWidth) : this.drawDynamicStaves(marginLeft, zoomWidth);

    // Now resize the 'canvas'. We compute the height from the y position of the lowest note, plus the bottom margin.
    var newWidth = zoomWidth+marginLeft+marginRight;
    var newHeight = yMin+this.metrics.marginBottom;
    this.resize(newWidth, newHeight);

    // We move all stave nodes to the start of the DOM tree so that all other score
    // elements (notes, connectors, etc.) are above them. This allows us to insert all
    // connectors underneath all notes, so that they don't interfere with click events.
    const staveNodes = ctx.svg.querySelectorAll(".stave");
    if (staveNodes.length > 1) {
      const nextNode = staveNodes.item(0).nextSibling;
      staveNodes.forEach((node, i) => {
        i > 0 && !(node===nextNode) && ctx.svg.insertBefore(node, nextNode);
      });
    }

    // Draw stave connectors if needed.
    if (this.showStaveConnectors && system.getStaveCount()>1) {
      system.drawConnectors(ctx);
    }

    if (this.showTimeGrid) {
      this.drawTimeGrid();
    }

    // Draw any spanners attached to the score.
    this.drawSpanners();

    // This (and preRender()) will be removed as we'll be doing it all through external decorators.
    this.postRender();

    return this;
  }

}
