import { Utils } from './utils';
import Score from './core/score';
import { Graphics } from './core/graphics';
import Segmentation from './core/score-segmentation';
import { NoteTools } from './core/tools/scoretools/note-tools';

export default class PrintManager {

  constructor(options) {
    this.score = options.score;
    this.title = this.score.title || "Untitled";
    this.prettyPrint = options.prettyPrint;
    this.margins = Utils.merge({
      top: 20,
      right: 30,
      bottom: 35,
      left: 30
    }, options.margins, {});
    this.pageSize = options.pageSize || "A4";
  }

  getPageDimensions() {
    // All page dimensions given in pixels (1cm = 37.8px).
    var pageSizes = {
      "A4": {
        width: 793.7,
        height: 1122.52
      },
      "A3": {
        width: 1122.52,
        height: 1587.4
      },
      "A5": {
        width: 559.367,
        height: 793.7
      }
    };
    return pageSizes[this.pageSize];
  }

  getPrintWidth() {
    var pageDimensions = this.getPageDimensions();
    var pageWidth = pageDimensions.width;
    var marginLeft = this.margins.left;
    var marginRight = this.margins.right;
    return pageWidth-(marginLeft+marginRight);
  }

  findMaxNoteOverlap() {
    var maxOverlap = 0;
    var voices = this.score.getVoices();
    voices.forEach(voice => {
      var notes = voice.getNotes();
      for (var i=1; i<notes.length; i++) {
        var bbox0 = notes[i-1].getBoundingBox();
        var bbox1 = notes[i].getBoundingBox();
        var diff = bbox0.right-bbox1.left;
        if (diff>maxOverlap) {
          if (bbox0.bottom>bbox1.top && bbox0.bottom<bbox1.bottom ||
              bbox0.top<bbox1.bottom && bbox0.top>bbox1.top) {
            maxOverlap = diff;
          }
        }
      }
    });
    //console.log(maxOverlap);
    return maxOverlap;
  }

  serializeSegment(seg) {
    var segments = this.segmentation.getSegments();
    var offset = seg.getOffset().toString();
    var data = this.score.getStaves().map(stave => {
      var clef = stave.getClef();
      return {
        clef: clef,
        type: stave.type,
        hideEndBarline: true,
        voices: [
          {
            clef: clef,
            noteStructs: []
          }
        ]
      };
    });
    seg.getItems().forEach(item => {
      var staveIndex = item.staveIndex;
      var noteData = item.serialize();
      noteData.onset = item.getOnset()-seg.start;
      data[staveIndex].voices[0].noteStructs.push(noteData);
    });
    var totalDur = seg.getDuration();
    var isLastSeg = segments.indexOf(seg)===segments.length-1;
    if (isLastSeg) {
      totalDur = this.score.getDuration()-seg.start;
    }
    data.forEach(staveObj => {
      var noteStructs = staveObj.voices[0].noteStructs;
      var clef = staveObj.clef;
      if (noteStructs.length>0) {
        staveObj.voices[0] = NoteTools.onsetsToDurations(noteStructs, totalDur, clef);
      }
    });
    return data;
  }

  segmentToScore(seg, elem, metrics, options) {
    var staveData = this.serializeSegment(seg);
    var data = {
      staves: staveData
    };
    var score = new Score({
      el: elem,
      data: data,
      metrics: metrics,
      autoCentre: false,
      ...options   // <- Adding this (mainly so we can print grand staff scores) causes a rendering bug...
    });
    if (this.score.showDynamics) {
      score.showDynamics = true;
      score.toggleDynamics();
    }
    return score;
  }

  renderSegments(elem) {
    var printWidth = this.getPrintWidth();
    var segments = this.segmentation.getSegments();
    var scoreOptions = { isGrandStaff: this.score.isGrandStaff };
    var rendered = segments.map(seg => {
      var segStartX = seg.getStartX();
      var segEndX = seg.getEndX();
      var ctx = this.score.getRenderContext();
      var stave = this.score.getStaves()[0];
      //ctx.line(segEndX, stave.getYForTopLine(), segEndX, stave.getYForLine(4));
      var width = printWidth;
      var isLastSeg = segments.indexOf(seg)===segments.length-1;
      if (isLastSeg) {
        width = stave.getNoteEndX()-segStartX;
      }
      var metrics = { width: width, marginBottom: 40 };
      var score = this.segmentToScore(seg, elem, metrics, scoreOptions);
      return score.render();
    });
    if (this.score.showTimeGrid) {
      var numDivs = this.score.numTimeGridDivisions;
      var totalWidth = 0;
      rendered.forEach(score => {
        totalWidth += score.getStaves()[0].getEffectiveWidth();
      });
      var segX = rendered[0].getStaves()[0].getNoteStartX();
      var segWidth = rendered[0].getStaves()[0].getEffectiveWidth();
      var gridX = segX;
      var gridSegWidth = totalWidth/numDivs;
      rendered.forEach(score => {
        var ctx = score.getRenderContext();
        var staves = score.getStaves();
        var width = staves[0].getEffectiveWidth();
        while (gridX>=segX && gridX<segX+width) {
          var stave1 = staves[0];
          var stave2 = staves[staves.length-1];
          var staveStartX = staves[0].getNoteStartX();
          var x = staveStartX+(gridX-segX); //gridX % segWidth; //width;
          var y1 = stave1.getYForLine(0)+2;
          var y2 = stave2.getYForLine(stave1.numLines-1);
          Graphics.drawDashedLine(ctx, x, y1, x, y2, [2,3]);
          gridX += gridSegWidth;
        }
        segX += width;
      });
    }
    return this;
  }

  timepointForX(x) {
    var score = this.score;
    var totalTime = score.getDuration();
    var stave = score.getStaves()[0];
    var width = stave.getEffectiveWidth();
    var timePoint = Math.floor((x/width)*totalTime);
    return timePoint;
  }

  render(elem) {
    var stave = this.score.getStaves()[0];
    var x = stave.getX();
    var noteStartX = stave.getNoteStartX();
    var effectiveWidth = stave.getEffectiveWidth();
    var printWidth = this.getPrintWidth();
    var segDur = this.timepointForX(printWidth); //this.score.timepointForX(printWidth);
    var numSegs = Math.ceil(effectiveWidth/printWidth);
    var segmentationPoints = Utils.repeatN(numSegs, (i) => {
      var point = segDur*i;
      return [point, point+segDur];
    });
    this.segmentation = new Segmentation({
      score: this.score,
      segmentationPoints: segmentationPoints
    })
    .build();
    var staves = this.score.getStaves();
    var segs = this.segmentation.getSegments();
    segs.forEach(seg => {
      seg.getItems().forEach(note => {
        var stave = note.getVoice().getStave();
        note.staveIndex = staves.indexOf(stave);
      });
    });
    this.renderSegments(elem);
    return this;
  }

  // The print method creates an 'off screen' iframe, copies the contents of our print preview into it, then prints the iframe.
  // Adapted from here: https://www.aspsnippets.com/Articles/Print-DIV-content-without-opening-new-popup-window-using-JavaScript.aspx
  print() {
    var title = this.title;
    var frame1 = $("<iframe />", {
      name: "frame1",
      style: "position: absolute; top: -1000000px"
    })[0];
    $(frame1).appendTo("body");
    var frameDoc = ( frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document
      ? frame1.contentDocument.document : frame1.contentDocument );
    var doc = frameDoc.document;
    doc.open();
    doc.write('<html><head><title>'+title+'</title>');
    doc.write('</head><body>');
    doc.write('<div id="print-view" style="width: 21cm; height: 29.7cm;"></div>');
    doc.write('</body></html>');
    var elem = doc.getElementById("print-view");  // We need to find the element inside the iframe's document. $("#print-view") will just look inside the main page.
    this.render(elem);
    doc.close();
    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      $(frame1).remove();
    }, 500);
    return false;
  }

}
