import React, { useContext } from "react";
import { RadioButtonGroup } from "../../widgets/radio-button";
import { SettingsContext } from "./dialog";


const NotationInstructionsSettings = () => {
  let { notationInstructionsLocation, dispatch } = useContext(SettingsContext);
  const onChange = (e) => dispatch({
    type: "SET_NOTATION_INSTRUCTIONS_LOCATION",
    payload: { notationInstructionsLocation: e.target.value }
  });
  const items = [
    { id: "main-panel-radio", value: "main panel", label: "Main panel", onChange: onChange, checked: notationInstructionsLocation==="main panel" },
    { id: "info-panel-radio", value: "info panel", label: "Notation information panel", onChange: onChange, checked: notationInstructionsLocation==="info panel" }
  ];
  return (
    <div>
      <legend>Notation Instructions</legend>
      <p>Control where the notation instructions should be displayed.</p>
      <RadioButtonGroup items={ items }/>
    </div>
  );
}

export default NotationInstructionsSettings;