import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import appReducer from "./reducers/app-reducer";
import App from "./components/app";
import UserGuide from "./components/doc-pages/user-guide";
import QuickStart from "./components/doc-pages/quickstart";
import NotFound from "./components/not-found";


const store = createStore(appReducer);

// Render the app into the page with the initial data.
// To run locally with http-server use the --proxy option:
// http-server --proxy http://127.0.0.1:8080?
// N.B: The question mark at the end of the url is required.
// See: https://stackoverflow.com/questions/54511950/react-router-with-http-server
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/app/" component={ App }></Route>
        <Route path="/quickstart/" component={ QuickStart }></Route>
        <Route path="/guide/" component={ UserGuide }></Route>
        <Route component={ NotFound }></Route>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
