import Spanner from '../base/spanner';
import NoteGroupSpanner from './note-group-spanner';
import PedalMarkSpanner from './pedal-mark-spanner';
import DynamicsSpanner from './dynamics-spanner';

// Spanner factory.
const SpannerFactory = function() {};

// Default spannerClass is just the generic Spanner.
SpannerFactory.prototype.spannerClass = Spanner;

// Factory method for creating Spanners.
SpannerFactory.prototype.createSpanner = function (options) {

  switch (options.type) {
    case "spanner":
      this.spannerClass = Spanner;
      break;
	case "noteGroup":
      this.spannerClass = NoteGroupSpanner;
      break;
	case "pedalMark":
      this.spannerClass = PedalMarkSpanner;
      break;
	case "dynamics":
      this.spannerClass = DynamicsSpanner;
      break;
  }

  return new this.spannerClass(options);

};

export { SpannerFactory };
