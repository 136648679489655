import React, { useRef } from "react";
import Select from "../widgets/select";
import GlyphIconButton from "../widgets/glyphicon-button";
import { ZOOM_LEVELS } from "../realisation-container";
import { useMediaQuery, NotMobile } from "../hooks/use-media-query";
import { useSelector, useDispatch } from "react-redux";


export default function ZoomControls() {

  let currentZoomLevel = useSelector(state => state.realisation.currentZoomLevel);
  let dispatch = useDispatch();
  const options = ZOOM_LEVELS.map(level => level+"%");

  const zoomList = useRef(currentZoomLevel);

  const zoomOut = () => {
    var val = zoomList.current;
    var index = ZOOM_LEVELS.indexOf( val );
    var prev = ZOOM_LEVELS[ index-1 ];
    if (prev) {
      zoomList.current = prev;
      dispatch({ type: "CHANGE_ZOOM_LEVEL", payload: prev });
    }
  };
  
  const zoomIn = () => {
    var val = zoomList.current;
    var index = ZOOM_LEVELS.indexOf( val );
    var next = ZOOM_LEVELS[ index+1 ];
    if (next) {
      zoomList.current = next;
      dispatch({ type: "CHANGE_ZOOM_LEVEL", payload: next });
    }
  };

  const zoomPcnt = (e) => {
    var val = e.target.value; //e.currentTarget.value;
    var pcnt = parseInt(val.slice(0, -1)); // Slice the '%' sign off the end.
    zoomList.current = pcnt;
    dispatch({ type: "CHANGE_ZOOM_LEVEL", payload: pcnt });
  };

  let isNotMobile = useMediaQuery('(min-width: 768px), (min-width: 992px)')
  let mr2 = isNotMobile ? " mr-2" : "";

  return (
    <div className={ "btn-group"+mr2 } id="zoom-controls">
      <NotMobile>
        <GlyphIconButton id="zoom-out" title="Zoom Out" icon="zoom-out" onClick={ zoomOut }/>
        <GlyphIconButton id="zoom-in" title="Zoom In" icon="zoom-in" onClick={ zoomIn }/>
      </NotMobile>
      <div style={ {display: "block", float: "left"} }>
        <Select id="zoom-list" value={ currentZoomLevel+"%" } buttonClass={"iconBtn"} width="100px" items={ options } onChange={ zoomPcnt }/>
      </div>
    </div>
  );
}
