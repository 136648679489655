import { Tables } from './tables';

// The following implements set difference for Array type.
// See: http://stackoverflow.com/questions/1187518/javascript-array-difference
Array.prototype.diff = function(a) {
  return this.filter(function(i) {
    return a.indexOf(i) < 0;
  });
};

/**
 * The CC.Utils package, containing general utility functions.
 * @namespace
 */
const Utils = {

  merge: function(destination, source) {
    for (var property in source)
      destination[property] = source[property];
    return destination;
  },

  // Generic identity function - useful as a default for optional callbacks, and for other things.
  // See: http://obscurejavascript.tumblr.com/post/143335631545/how-identity-functions-are-useful-in-javascript
  identity: function(value) {
    return value;
  },

  contains: function(arr, obj) {
    return (arr.indexOf(obj) != -1);
  },

  numericSort: function(arr, direction) {
    let dir = direction || "asc";
    return arr.slice(0).sort((a,b) => {
      if (dir==="asc") { return a-b; }
      else if (dir==="desc") { return b-a; }
    });
  },

  reverse: function(arr) {
    var temp = [];
    for (var i = (arr.length-1); i >= 0; i--) {
      temp.push(arr[i]);
    }
    return temp;
  },

  isArray: function(thing) {
    return thing.constructor===Array;
  },

  isEmptyArray: function(thing) {
    return Utils.isArray(thing) && thing.length===0;
  },

  // This checks if 'thing' is explicitly an object - since
  // arrays are objects we need to exclude them.
  isObject: function(thing) {
    return thing!==null && !Utils.isArray(thing) && typeof thing==='object';
  },

  isNumber: function(thing) {
    return !isNaN(thing) && isFinite(thing);
  },

  isFunction: function(func) {
    if (func && typeof func === "function") {
      return true
    }
    return false
  },

  round: function(val, closestMultipleOf) {
    let to = closestMultipleOf;
    return to ? Math.round(val/to)*to : Math.round(val);
  },

  floor: function(val, closestMultipleOf) {
    let to = closestMultipleOf;
    return to ? Math.floor(val/to)*to : Math.floor(val);
  },

  ceil: function(val, closestMultipleOf) {
    let to = closestMultipleOf;
    return to ? Math.ceil(val/to)*to : Math.ceil(val);
  },

  // GUID generator found here: http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  guid: function() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  },

  // This formats a Date object to an ISO date string (yyyy-MM-dd).
  // See https://stackoverflow.com/a/29774197/795131
  getISODateString: function() {
    return new Date().toISOString().split('T')[0];
  },

  modSlice: function(arr, start, len) {
    var slice = [];
    for (let i = 0; i < len; i++) {
      let modIndex = (start+i) % arr.length;
      slice.push(arr[ modIndex ]);
    }
    return slice;
  },

  scale: function(val, fromMin, fromMax, toMin, toMax) {
    return ((toMax - toMin) * (val - fromMin)) / (fromMax - fromMin) + toMin;
  },

  sum: function(arr) {
    return arr.reduce(function (a, b) {
      return a + b;
    });
  },

  getRandomIntInclusive: function(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
  },

  // Get a random element from an array.
  getRandElem: function(arr) {
    return arr[Math.floor(Math.random() * arr.length)]
  },

  getRandomSubarray: function(arr, size) {
    var shuffled = arr.slice(0), i = arr.length, temp, index;
    while (i--) {
      index = Math.floor((i + 1) * Math.random());
      temp = shuffled[index];
      shuffled[index] = shuffled[i];
      shuffled[i] = temp;
    }
    return shuffled.slice(0, size);
  },

  // Javascript doesn't have a native function for repeating some action and collecting into
  // an array. The following is copied (and slightly simplified) from the Underscore source.
  // The 'iterator' is a function object with 0 arguments.
  // See: http://stackoverflow.com/questions/18499935/idiom-for-repeat-n-times
  // Use like this:
  /*
  Utils.repeatN(10, function() {
    return ///...
  })
  */
  repeatN: function(n, iterator) {
    var accum = Array(Math.max(0, n));
    for (var i = 0; i < n; i++) {
      // Previously we just had iterator.call(), but passing in (this, i)
      // gives code inside the iterator access to the current accumulator
      // index, as in Array.map and Array.forEach.
      accum[i] = iterator.call(this, i);
    }
    return accum;
  },

  // We can't simply use slice(0) to copy a nested array, as this will still contain references. The following
  // function (courtesy of http://blog.andrewray.me/how-to-clone-a-nested-array-in-javascript/) creates a
  // deep copy of an array of arrays. Won't work with an array of objects.
  arrayClone: function(arr) {
    var i, copy;
    if(Array.isArray(arr)) {
      copy = arr.slice(0);
      for(var i = 0; i < copy.length; i++) {
        copy[i] = arrayClone(copy[i]);
      }
      return copy;
    }
    else if(typeof arr === 'object') {
      throw 'Cannot clone array containing an object!';
    }
    else {
      return arr;
    }
  },

  // Splice an array of arrays into another array.
  spliceArray: function(arr1, arr2, start, deleteCount) {
    deleteCount = deleteCount || arr2.length;
    arr1_copy = arrayClone(arr1);
    arr1_copy.splice.apply(arr1_copy, [start, deleteCount].concat(arr2));
    return arr1_copy;
  },

  queryParams: function(params) {
    let esc = encodeURIComponent;
    return Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
  },

  // Angle in radians
  getAngleRadians: function(x1, y1, x2, y2) {
    return Math.atan2(y2 - y1, x2 - x1);
  },

  // Angle in degrees
  getAngleDegrees: function(x1, y1, x2, y2) {
    var deg = Utils.getAngleRadians(x1, y1, x2, y2) * 180 / Math.PI;
    return deg > 0 ? deg : (360 + deg);
  },

  // We can't use indexOf on a raw HTMLCollection, so this function converts such a collection to a native JS array.
  // The function is non-destructive, returning a new array.
  htmlCollectionToArray: function(coll) {
    return [].slice.call(coll);
  },

  removeElementsByClass: function(className){
    // Need to use getElementsByClassName here as it returns a live
    // NodeList, unlike querySelectorAll - the list returned by the
    // latter won't change as the elements are removed from the DOM.
    const elements = document.getElementsByClassName(className);
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }
  },

  getEnharmonics: function(key) {
    return Tables.enharmonics[key].concat(key).sort();
  },

  // Shuffle an array in place (so it's a destructive operation). Uses the Fisher-Yates algorithm.
  // Found here: https://stackoverflow.com/a/6274381
  shuffle: function(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  },

  // Alternative version of shuffle, still using Fisher-Yates, found here: https://stackoverflow.com/a/2450976
  /*
  shuffle: function(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array
  },
  */

  xToDx: function(vals) {
    var result = [];
    for (var i=1; i<vals.length; i++) {
      var dx = vals[i]-vals[i-1];
      result.push(dx);
    }
    return result;
  },

  dxToX: function(vals, start) {
    var result = [start || 0];
    vals.slice(0, -1).forEach((val, i) => {
      var x = result[i]+val;
      result.push(x);
    });
    return result;
  },

  range: function(start, end) {
    let n = Math.abs( end-start );
    return Utils.repeatN(n+1, (i) => start+i);
  },

  getRandPartition: function(sum, n) {
    var range = Utils.repeatN(sum-1, (i) => {
      return i+1;
    });
    var subSeq = Utils.getRandomSubarray(range, n-1);
    subSeq = subSeq.concat([0,sum]);
    subSeq.sort((a,b) => a-b);
    var diffs = subSeq.slice(1).map((val,i) => val-subSeq[i]);
    return diffs;
  },

/*
  var partition = Utils.getRandPartition(10000, 50);
  console.log(partition);
  console.log(partition.find(elt => elt===0)===undefined && partition.reduce((a,b) => a+b)===total);
*/

  chunkArray: function(arr, sizes) {
    var groups = [];
    for (var i=0, j=0; j<sizes.length; i+=sizes[j], j+=1) {
      groups.push(arr.slice(i, i + sizes[j]));
    }
    return groups;
  },

  // Remove duplicate entries from an array. Found here (named 'uniq'): https://stackoverflow.com/a/9229821/795131.
  // Note that this is the 'hash table' version (second in the above link).
  removeDuplicates: function(arr) {
    var seen = {};
    return arr.filter(item => {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
  },

  // Check if all values in an array are equal (see https://stackoverflow.com/a/21266395/795131).
  allEqual: function(arr) {
    return !!arr.reduce((a, b) => {
      return (a===b) ? a : NaN;
    });
  },

  // Copy (shallow) an object, omitting a key. Found here: https://stackoverflow.com/a/34705171/795131.
  omit: function(obj, omitKey) {
    return Object.keys(obj).reduce((result, key) => {
      if (key !== omitKey) {
         result[key] = obj[key];
      }
      return result;
    }, {});
  },

  isEmptyObject: function(thing) {
    return Object.keys(thing).length===0 && thing.constructor===Object;
  },

  // See https://coderwall.com/p/wkdefg/converting-milliseconds-to-hh-mm-ss-mmm
  msToTime(ms) {
    if (ms < 10) return "00:00:00.00" + ms;
    var milliseconds = parseInt(ms%1000) //parseInt((ms%1000)/100)
        , seconds = parseInt((ms/1000)%60)
        , minutes = parseInt((ms/(1000*60))%60)
        , hours = parseInt((ms/(1000*60*60))%24);
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    milliseconds = (milliseconds < 10) ? "00" + milliseconds : (milliseconds < 100) ? "0" + milliseconds : milliseconds;
    return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  },

  timeToMs(hhmmss) {
    let [ hh, mm, ss ] = hhmmss.split(":");
    let h = parseInt(hh*60*60*1000);
    let m = parseInt(mm*60*1000);
    // The rounded parseFloat call here seems to be required in certain places,
    // for instance certain numbers between 1000-1010 and 2000-2010 would come out
    // as floats if using parseInt. 
    let s = Math.round( parseFloat(ss*1000) ); //parseInt(ss*1000);
    return h + m + s;
  },

  // From the Django CSRF page. The CSRF token needs to be added in the header of all Ajax requests.
  // See https://docs.djangoproject.com/en/2.2/ref/csrf/#ajax
  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  },

};

function getNotationViewClass(type) {
  var name = "Notation"+type+"View";
  return window[name];
}

//From http://stackoverflow.com/questions/11935175/sampling-a-random-subset-from-an-array
function getRandomSubarray(arr, size) {
  var shuffled = arr.slice(0), i = arr.length, temp, index;
  while (i--) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(0, size);
}

// Find the pixel position of the highest notehead in a voice. We apply Math.min because,
// of course, the origin is placed at the top left, so the highest notehead position will be
// the minimum of all the notehead positions in the voice.
function findVoiceMaxY(notes) {
  var maxY = Math.min.apply(Math, notes.map(function(note) {
    var Ys = note.getYs();
    return Ys[Ys.length-1];
  }));
  return maxY;
}

// Min complement for findVoiceMaxY.
function findVoiceMinY(notes) {
  var minY = Math.max.apply(Math, notes.map(function(note) {
    var Ys = note.getYs();
    return Ys[0];
  }));
  return minY;
}

// The following functions convert back and forth between integers and VexFlow keys.

function integersToKeys(midis) {
  var keys = midis.map(function(midi) {
    var int_val = midi % 12;
    var octave = Math.floor((midi-12)/12);
    return Vex.Flow.integerToNote.table[int_val]+"/"+octave;
  });
  return keys;
}

function keysToIntegers(keys) {
  var ints = keys.map(function(key) {
    var key_parts = key.split('/');
    var pitch_class = key_parts[0];
    var octave = key_parts[1];
    var int_val = Vex.Flow.Music.noteValues[pitch_class].int_val;
    return octave*12+12+int_val;
  });
  return ints;
}

function keyToInteger(key, octave) {
  var int_val = Vex.Flow.Music.noteValues[key].int_val;
  return octave*12+12+int_val;
}

function durationsToOnsets(durations, start) {
  start = start || 0;
  var onsets = [start];
  durations.forEach(function(dur, index) {
    onsets.push(onsets[index]+dur);
  });
  return onsets;
}

function onsetsToDurations(onsets) {
  var durations = [];
  for (var i = 1; i < onsets.length; i += 1) {
    durations.push(onsets[i]-onsets[i-1]);
  }
  return durations;
}

//////////////////////////////////////////////////////////////////////////////////////////

// The following functions are used by the app context menu, but are likely very useful elsewhere.

function getNoteheadElem(e) {
  return e.target.closest('.vf-notehead');
}

function getNoteheadElemIndex(notehead) {
  // This is the HTMLCollection containing all the noteheads. We need this to find the index of the notehead.
  var noteheads = notehead.parentNode.children;
  noteheads = Utils.htmlCollectionToArray(noteheads);
  return noteheads.indexOf(notehead);
}

function getKeyAtIndex(note, index) {
  return note._vfnote.getKeyProps()[index].key; // We should also add the octave.
}

//////////////////////////////////////////////////////////////////////////////////////////

function findKeyInChord(chord, key) {
  // Finds a given key in the chord. If successful
  // returns the index, otherwise false.
  var posn = false,
      keys = chord.getKeys();
  for (var i = 0; i < keys.length; i += 1) {
    if ( (keys[i].toLowerCase()) === (key.toLowerCase()) ) {
      posn = i;
      break;
    }
  }
  return posn;
}

export { Utils, getNotationViewClass, getRandomSubarray, findVoiceMaxY, findVoiceMinY, integersToKeys, keysToIntegers, durationsToOnsets, onsetsToDurations, getNoteheadElem, getNoteheadElemIndex, getKeyAtIndex, findKeyInChord };
