/*
The Toplevel App Component.
*/

import React from "react";
import Loader from "react-loader";
import { Utils } from "../utils";
import { Tables } from "../tables";
import MainMenu from "./main-menu";
import NotationContainer from "./notation-container";
import Modals from "./modals/modals";
import { batch, connect } from 'react-redux';
import { setNotationData } from '../actions/notation-actions';
import { resetRealisation } from '../actions/realisation-actions';
import { sortBy } from 'lodash';

const URL_ROOT = "/api/";

export { URL_ROOT };

const wrapData = (data) => {
  if (!data.staves) {
    return data;
  }
  return {
    ...data,
    staves: data.staves.map(staveObj => {
      return {
        ...staveObj,
        voices: staveObj.voices || [
          {
            clef: staveObj.clef || "treble",
            noteStructs: staveObj.noteStructs,
            offset: staveObj.offset
          }
        ]
      };
    })
  };
}

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div id="footer" className="footer">
      <div className="container" id="attribution">
        <span>Copyright ⓒ {year},&nbsp;</span><span>University of Huddersfield | University of Leeds</span>
      </div>
    </div>
  );
}

// Currently (23/11/2019) this only returns a random notation from the
// first two alphabets (A-AZ), since the rest aren't quite finished. It
// will also only return the first instance of the selected notation. 
const getRandomNotation = () => {
  let charCode = Utils.getRandomIntInclusive(0, 51);
  let alphabet = charCode > 25 ? "A" : "";
  let type = alphabet + String.fromCharCode((charCode % 26) + 65);
  return { type: type, page: Tables.notationMap[type][0], order: 1 };
};

export const MainMenuContext = React.createContext();

class AppBase extends React.Component {

  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    const { defaultNotationSelectionType, defaultNotation } = this.props;
    const initialNotation = defaultNotationSelectionType==="none" ? getRandomNotation() : defaultNotation;
    let { type, page, order=1 } = initialNotation;
    this.update(type, page, order);
  }

  update(type, page, order) {
    Promise.all([
      this.load(type),
      this.fetch(type)
    ])
    .then(([module, fetched]) => {
      this.module = module[ `Notation${type}` ];
      const { instructions, comments, directions, notations } = fetched;
      const data = notations.find(obj => obj.page==page && obj.order==order);
      // Not sure if explicit batching is necessary here, React might do it automatically.
      batch(() => {
        this.props.resetRealisation();
        this.props.setNotationData({
          ...data,
          instructions,
          comments,
          directions,
          key: Utils.guid()
        });
      });
    });
  }

  load(type) {
    return import(/* webpackChunkName: '[request]' */ "../notations/notation"+type);
  }

  // Code inspired by: https://www.sitepoint.com/cache-fetched-ajax-requests/
  fetch(type) {
    let found = JSON.parse(localStorage.getItem(type));
    if (found) {
      return Promise.resolve(found);
    }
    //let url = URL_ROOT + "notations/" +type +"/";
    let url = `/data/${type}.json`;
    return fetch( url )
      .then(response => response.json())
      .then(json => {
        let parsed = json.notations.map(obj => this.parse(obj));
        let fetched = {
          ...json,
          notations: sortBy(parsed, ['type', 'page'])
        };
        localStorage.setItem(type, JSON.stringify(fetched));
        return fetched;
      });
  }

  parse(response, options) {
    var attr = {
      type: response.type,
      page: response.page,
      order: response.order,
      data: response.data,
      parameters: response.parameters,
      realisationData: response.realisation_data
    };
    attr.data = wrapData(response.data);
    return attr;
  }

  render() {
    let { isLoaded } = this.props;
    return (
      <Loader loaded={ isLoaded } loadedClassName={ "app" }>
        <div id="main-content">
          <MainMenuContext.Provider value={ { update : this.update } }>
            <MainMenu />
          </MainMenuContext.Provider>
          <NotationContainer module={this.module} />
          <div className="push"></div>
        </div>
        <Footer />
        <Modals />
      </Loader>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    notation : state.notation,
    isLoaded : state.notation.data!==null,
    defaultNotationSelectionType : state.settings.defaultNotationSelectionType,
    defaultNotation : state.settings.defaultNotation,
  }
}

const mapDispatchToProps = {
  setNotationData: setNotationData,
  resetRealisation: resetRealisation
}

const App = connect(mapStateToProps, mapDispatchToProps)(AppBase);

export default App;
