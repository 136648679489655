import { combineReducers } from "redux";
import notationReducer from "./notation-reducer";
import realisationReducer from "./realisation-reducer";
import settingsReducer from "./settings-reducer";
import modalsReducer from "./modals-reducer";

const appReducer = combineReducers({
  notation: notationReducer,
  realisation: realisationReducer,
  settings: settingsReducer,
  modals: modalsReducer
});

export default appReducer;