import React, { Fragment, useState } from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import GlyphIconButton from "./glyphicon-button";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


/*
const toggleTick = (e) => {
  e.preventDefault();
  let elem = e.currentTarget;
  // For details on toggling a 'selected' arrow like this see https://stackoverflow.com/a/29374172/795131.
  let list = elem.closest("ul");
  let selected = list.querySelector("a.selected");
  selected.classList.remove("selected");
  elem.classList.add("selected");
}

const wrapOnClick = (onClick) => {
  return (e) => {
    toggleTick(e);
    let elem = e.currentTarget;
    let value = elem.getAttribute("data-value");
    onClick(e, value);
  };
}

const TickListItems = (props) => {
  let items = props.items.map(itemDef => {
    let { selected, value, onClick, text } = itemDef;
    let handleOnClick = wrapOnClick(onClick);
    return (
      <li>
        <a className={ "dropdown-item" + (selected ? " selected" : "") } data-value={ value || text } href="#" onClick={ handleOnClick }>
          <i className="glyphicon glyphicon-ok mr-1"></i> <span>{ text }</span>
        </a>
      </li>
    );
  });
  return items;
}

const TickList = (props) => {
  let { widgetId: id, widgetTitle: title, items, icon } = props;
  return (
    <div className="btn-group">
      <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" id={ id } title={ title }>
        <i className={ "glyphicon glyphicon-"+icon }></i>
      </button>
      <ul className="dropdown-menu dropdown-menu-sm tick-list" role="menu" id={ id + "-list" }>
        <TickListItems items={ items } />
      </ul>
    </div>
  );
}
*/

const TickList = (props) => {
  const { id, title, items, icon } = props;

  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const [ checked, setChecked ] = useState(0);

  const TickListItems = () => {
    return items.map((itemDef, i) => {
      const { onClick, text } = itemDef;
      const handleOnClick = (e) => {
        onClick(e, text);
        setChecked(i);
        handleCloseMenu();
      };
      const visibility = i===checked ? "visible" : "hidden";
      return (
        <MenuItem onClick={handleOnClick}>
          <ListItemIcon style={ { minWidth: 30, visibility: visibility } }>
            <i className="glyphicon glyphicon-ok"/>
          </ListItemIcon>
          <Typography style={ { color: "inherit" } }>{text}</Typography>
        </MenuItem>
      );
    });
  }

  const btnContainerStyle = {
    display: "inline-flex",
    alignItems: "center",
    position: "relative"
  };

  const expMoreLessIcon = {
    right: "10px",
    overflow: "hidden",
    textAlign: "start",
    position: "absolute",
    display: "inline-block",
    pointerEvents: "none",
  };

  return (
    <Fragment>
      <span style={ btnContainerStyle }>
        <GlyphIconButton classNames="tick-list" id={id} icon={icon} title={title} onClick={handleOpenMenu}/>
        { anchorEl
          ? <ExpandLessIcon style={ expMoreLessIcon }/>
          : <ExpandMoreIcon style={ expMoreLessIcon }/>
        }
      </span>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <TickListItems />
      </Menu>
    </Fragment>
  );
}

export default TickList;