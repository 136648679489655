import Score from './score';
import ScoreObject from './score-object';
import GrandStaffScore from './grand-staff-score';
import NetworkScore from './network-score';
import FrameScore from './frame-score';
import GridScore from './grid-score';

// Score factory.
const ScoreFactory = function() {};

// Default scoreClass is just Score (rename to 'SimpleScore'?).
ScoreFactory.prototype.scoreClass = Score;

// Factory method for creating Scores.
ScoreFactory.prototype.createScore = function (options) {

  switch (options.type) {
    case "simple":
      this.scoreClass = Score;
      break;
	case "grandStaff":
      this.scoreClass = GrandStaffScore;
      break;
    case "network":
      this.scoreClass = NetworkScore;
      break;
    case "frame":
      this.scoreClass = FrameScore;
      break;
    case "grid":
      this.scoreClass = GridScore;
      break;
  }

  //console.log(this.scoreClass);
  return new this.scoreClass(options);

};

export { ScoreFactory };

// Use it like this:
/*
var scoreFactory = new ScoreFactory();
var score = scoreFactory.createScore({
  type: "simple",
  el: document.getElementById("#score"),
  data: data,
  metrics: {
    width: 1200
  }
});
*/
