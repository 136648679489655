import { Utils, findVoiceMaxY, findVoiceMinY } from '../../../utils';
import { Tables } from '../../../tables';
import { sampleSize, range as lodashRange } from 'lodash';


var NoteTools = {

  // Returns an array of random MIDI velocity values (integers between 0 and 127, inclusive).
  // If 'scale' is not provided an array of random velocity values is returned, otherwise the
  // values will be selected from the scale.
  genRandomDynamics: function(n, scale) {
    var result;
    if (scale) {
      result = Utils.repeatN(n, () => {
        return Utils.getRandElem(scale);
      });
    }
    else {
      result = Utils.repeatN(n, () => {
        return Utils.getRandomIntInclusive(5, 127);
      });
    }
    return result;
  },

  getKeysBetween: function(startNote, endNote) {
    var startKeyData = startNote.getPitches()[0].toString().toLowerCase().split("/");
    var endKeyData = endNote.getPitches()[0].toString().toLowerCase().split("/");
    var startKeyVal = startKeyData[0];
    var endKeyVal = endKeyData[0];
    var startKeyOctave = parseInt(startKeyData[1]);
    var endKeyOctave = parseInt(endKeyData[1]);
    var startVal = Vex.Flow.Music.noteValues[startKeyVal].int_val;
    var endVal = Vex.Flow.Music.noteValues[endKeyVal].int_val;
    var startMidi = startKeyOctave*12+12+startVal;
    var endMidi = endKeyOctave*12+12+endVal;
    var keys = lodashRange(Math.min(startMidi,endMidi)+1, Math.max(startMidi,endMidi));
    return keys;
  },

  getCluster: function(arr, maxSize) {
    var size = Utils.getRandomIntInclusive(2, Math.min(arr.length, maxSize));
    var start = Utils.getRandomIntInclusive(0, arr.length-size);
    var end = start+size;
    return arr.slice(start, end);
  },

  getClusters: function(arr, nSamps) {
    var n = nSamps;
    var clusters = [];
    while (n>0) {
      var cluster = this.getCluster(arr, n);
      clusters.push(cluster);
      n -= cluster.length;
    }
    return clusters;
  },

  getChord: function(arr, maxSize) {
	var chordMinSize = 3;
    var chordMaxSize = 9;
    var size = Utils.getRandomIntInclusive(chordMinSize, Math.min(chordMaxSize, maxSize));
	//Utils.getRandomIntInclusive(chordMinSize, Math.min(arr.length, maxSize));
	//Utils.getRandomIntInclusive(chordMinSize, chordMaxSize);
    var samps = sampleSize(arr, size);
    return samps.sort((a,b) => {
      return a-b;
    });
  },

  getDyad: function(arr) {
    var samps = sampleSize(arr, 2);
    return samps.sort((a,b) => {
      return a-b;
    });
  },

  getDyads: function(arr, n) {
    var dyads = [];
    // If the required number of notes is odd then we can't divide equally by 2.
    // So the rule in that case is that one 'dyad' should contain just a single note.
    if (!(n%2==0)) {
      dyads.push([sampleSize(arr)]); // Grab a single note and push it onto the result array (in its own array).
      n -= 1; // Decrement n by 1.
    }
    while (n>0) {
      var samps = this.getDyad(arr);
      dyads.push(samps);
      n -= samps.length;
    }
    // console.log(dyads);
    return dyads; // _.shuffle this? Because we can't guarantee that it'll be shuffled anywhere else.
  },

  getAggregates: function(arr, n, fn) {
    var aggs = [];
    function backtrack(arr, n) {
      try {
        if (n>0) {
          var agg = fn(arr, n);
          var last_sel = aggs[aggs.length-1] || agg;
          if (n<agg.length) {
            aggs.pop();
            n += last_sel.length;
            return backtrack(arr, n);
          }
          else {
            aggs.push(agg);
            n -= agg.length;
            if (backtrack(arr, n)===true) {
              return true;
            }
          }
        }
        return false;
      }
      catch (err) {
        console.log(err.message);
      }
    }
    backtrack(arr, n);
    // Test to check if the sum of the lengths of the aggregates equals n.
    var sum = 0;
    aggs.forEach((agg) => {
      sum += agg.length;
    });
    console.log(aggs, n==sum);
    //
    return aggs;
  },

  _noteStringToMidi: function(noteString) {
    var pitchParts = noteString.split("/");
    var pitchClass = pitchParts[0].toUpperCase();
    var octave = pitchParts[1];
    var intVal = Vex.Flow.keyProperties.note_values[pitchClass].int_val;
    return octave*12+12+intVal;
  },

  noteStringToMidi: function(noteData) {
	var result;
    if (noteData.constructor===Array) {
	  result = noteData.map(this._noteStringToMidi);
	}
	else {
	  result = this._noteStringToMidi(noteData);
	}
    return result;
  },

  _midiToNoteString: function(midi) {
    var intVal = midi%12;
    var octave = Math.floor((midi-12)/12);
    return Vex.Flow.integerToNote.table[intVal]+"/"+octave;
  },

  midiToNoteString: function(midiData) {
	var result;
    if (midiData.constructor===Array) {
	  result = midiData.map(this._midiToNoteString);
	}
	else {
	  result = this._midiToNoteString(midiData);
	}
    return result;
  },

  getLineForNoteString: function(noteString) {
    const C1 = -10.5;
    const linesPerOctave = 3.5;
    let [ pitch, octave ] = noteString.split("/");
    return C1+(linesPerOctave*(octave-1))+(Tables.pitchIndices[pitch[0]]*0.5);
  },

  createNoteCluster: function(bottomNote, topNote) {
    var start = this.noteStringToMidi(bottomNote);
    var end = this.noteStringToMidi(topNote);
    var pitches = [];
    for (var i = start; i <= end; i++) {
      pitches.push(this.midiToNoteString(i));
    }
    return pitches;
  },

  createNoteAggregate: function(bottomNote, topNote, size) {
    var size = size || 3;
    var range = this.createNoteCluster(bottomNote, topNote);
	  var pitches = Utils.getRandomSubarray(range, size);
    return pitches;
  },

  hasAccidental: function(p) {
    var p1 = p[1];
    if ( p1 !== "/") {
      return p1;
    }
  },

  transpose: function(pitch, interval) {
    var posn = Tables.noteToInteger[pitch[0]],
        accidental = this.hasAccidental(pitch) || "",
        octave = parseInt(pitch[pitch.length-1]),
        // This is the way to do modulo addition OR subtraction.
        // See: http://www.cplusplus.com/forum/beginner/7787/
        numOctaves = Math.floor(interval/7),
        newPosn = ((Math.abs(numOctaves)*7)+(posn+interval))%7; //(Math.floor(interval/7)+posn+interval)%7;
        numOctaves = (interval+posn-newPosn)/7;
        //console.log(numOctaves);
    return Tables.integerToNote[newPosn] + accidental + "/" + (octave+numOctaves);
  },

// The following is a chromatic version of transpose. It uses name+accidental as a key into Vex.Flow.Music.noteValues.
/*
  integerToNote: {
    0: "c",
    1: "c#",
    2: "d",
    3: "d#",
    4: "e",
    5: "f",
    6: "f#",
    7: "g",
    8: "g#",
    9: "a",
    10: "a#",
    11: "b"
  },

  transpose: function(key, interval) {
    var split_key = key.toLowerCase().split('/');
    var octave = parseInt(split_key[1]);
    var posn = Vex.Flow.Music.noteValues[split_key[0]].int_val;
    // This is the way to do modulo addition OR subtraction.
    // See: http://www.cplusplus.com/forum/beginner/7787/
    var numOctaves = Math.floor(interval/12);
    var newPosn = ((Math.abs(numOctaves)*12)+(posn+interval))%12;
    var numOctaves = (interval+posn-newPosn)/12;
    return Tables.integerToNote[newPosn] + "/" + (octave+numOctaves);
  },
*/

  _applyClef: function(pitch, clef, originalClef) {
	var pitch = pitch.toLowerCase();
    var originalClef = originalClef || "treble";
    var result = pitch;
    if (clef!=originalClef) {
      var shift;
      switch (clef) {
        case "treble":
          shift = 12;
          break;
        case "bass":
          shift = -12;
          break;
      };
      result = this.transpose(pitch, shift);
	  }
    return result;
  },

  applyClef: function(pitchData, clef, originalClef) {
    var result;
    if (pitchData.constructor===Array) {
      result = pitchData.map(function(pitch) {
        return this._applyClef(pitch, clef, originalClef);
      }, this);
    }
    else {
      result = this._applyClef(pitchData, clef, originalClef);
    }
    return result;
  },

  sortPitches: function(pitches) {
    var _pitches = pitches.slice();
    var sorted = _pitches.sort((a, b) => {
      var keyA = this.noteStringToMidi(a);
      var keyB = this.noteStringToMidi(b);
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    return sorted;
  },

  sortNoteStrings: function(pitches) {
    const sorted = pitches.sort((a, b) => {
      let [name1, oct1] = a.split("/");
      let [name2, oct2] = b.split("/");
      let aa = (parseInt(oct1) * 12) + Vex.Flow.Music.noteValues[name1.toLowerCase()].int_val;
      let bb = (parseInt(oct2) * 12) + Vex.Flow.Music.noteValues[name2.toLowerCase()].int_val;
      //console.log(aa,bb);
      return aa - bb;
    });
    return sorted;
  },

  /*
  onsetsToDurations: function(tempData, totalDur, clef) {
    var clef = clef || "treble";
    tempData.sort(function(a, b) {
      return a.onset-b.onset;
    });
    var noteStructs = [];
    for (var i=1; i<tempData.length; i++) {
      var dur = tempData[i].onset-tempData[i-1].onset;
      noteStructs.push({
        pitches: tempData[i-1].pitches,
        duration: dur==0 ? "1" : dur.toString(),
        dynamic: tempData[i-1].dynamic,
        type: tempData[i-1].type,
        technique: tempData[i-1].technique
      });
    }
    var lastStruct = tempData[tempData.length-1];
    noteStructs.push({
      pitches: lastStruct.pitches,
      duration: (totalDur-lastStruct.onset).toString(),
      dynamic: lastStruct.dynamic,
      type: lastStruct.type,
      technique: lastStruct.technique
    });
    return {
      clef: clef,
      noteStructs: noteStructs,
      offset: tempData[0].onset.toString()
    };
  },
  */

  onsetsToDurations: function(data, totalDur, clef) {
    var clef = clef || "treble";
    data.sort((a, b)=>a.onset-b.onset);
    var noteStructs = [];
    var len = data.length;
    for (var i=1; i<=len; i++) {
      var dur = ( i<len ? data[i].onset : totalDur )-data[i-1].onset;
      var struct = Utils.omit(data[i-1], "onset");
      struct.duration = dur===0 ? "1" : dur.toString();
      noteStructs.push(struct);
    }
    return {
      clef: clef,
      noteStructs: noteStructs,
      offset: data[0].onset.toString()
    };
  },

  getSimultaneities: function(notes) {
    const temp = {};
    notes.forEach(note => {
      let onset = note.getOnset();
      (!temp[onset]) && (temp[onset] = []);
      temp[onset].push(note);
    });
    const result= {};
    for (let key in temp) {
      let val = temp[key];
      val.length > 1 && (result[key] = val);
    }
    return result;
  },

  isCrossStaffCluster: function(notePair) {
    let [ noteA, noteB ] = notePair;
    if (noteA.type==="cluster" && noteB.type==="cluster") {
      let sorted = [noteA, noteB].sort((a,b) => {
        let rangeA = a.getPitchRange();
        let rangeB = b.getPitchRange();
        return NoteTools.noteStringToMidi(rangeA[0])-NoteTools.noteStringToMidi(rangeB[0]);
      });
      let bottomPartRange = sorted[0].getPitchRange();
      let topPartRange = sorted[1].getPitchRange();
      let bottomPartTopPitch = bottomPartRange[bottomPartRange.length-1];
      let topPartBottomPitch = topPartRange[0];
      let partsDiff = NoteTools.noteStringToMidi(topPartBottomPitch)-NoteTools.noteStringToMidi(bottomPartTopPitch);
      //console.log(partsDiff, bottomPartRange, topPartRange);
      if (partsDiff===1) {
        return true;
      }
    }
  },

  getNoteRelativePosition: function(note1, note2, h) {
    var bbox1 = note1.getBoundingBox();
    var bbox2 = note2.getBoundingBox();
    var degrees = Utils.getAngleDegrees(bbox1.x, h-bbox1.y, bbox2.x, h-bbox2.y);
    //var degrees = getAngleDegrees(note1.getAbsoluteX(), note1.stave.getYForLine(note1.getKeyProps()[0].line), note2.getAbsoluteX(), note2.stave.getYForLine(note2.getKeyProps()[0].line));
    var position;
    switch (true) {
      case (degrees==0 || degrees==360): // Is 0 degrees actually possible?
        position = 'E';
        break;
      case (degrees>0 && degrees<45):
        position = 'ENE';
        break;
      case (degrees==45):
        position = 'NE';
        break;
      case (degrees>45 && degrees<90):
        position = 'NNE';
        break;
      case (degrees==90):
        position = 'N';
        break;
      case (degrees>90 && degrees<135):
        position = 'NNW';
        break;
      case (degrees==135):
        position = 'NW';
        break;
      case (degrees>135 && degrees<180):
        position = 'WNW';
        break;
      case (degrees==180):
        position = 'W';
        break;
      case (degrees>180 && degrees<225):
        position = 'WSW';
        break;
      case (degrees==225):
        position = 'SW';
        break;
      case (degrees>225 && degrees<270):
        position = 'SSW';
        break;
      case (degrees==270):
        position = 'S';
        break;
      case (degrees>270 && degrees<315):
        position = 'SSE';
        break;
      case (degrees==315):
        position = 'SE';
        break;
      case (degrees>315):
        position = 'ESE';
        break;
    }
    return position;
  },

  getNoteheadRelativePosition(note1, note1Pitch, note2, note2Pitch, h) {
    var x1 = note1.getBoundingBox().x;
    var y1 = note1.getYs()[note1Pitch];
    var x2 = note2.getBoundingBox().x;
    var y2 = note2.getYs()[note2Pitch];
    var degrees = Utils.getAngleDegrees(x1, h-y1, x2, h-y2);
    var position;
    switch (true) {
      case (degrees==0 || degrees==360): // Is 0 degrees actually possible?
        position = 'E';
        break;
      case (degrees>0 && degrees<45):
        position = 'ENE';
        break;
      case (degrees==45):
        position = 'NE';
        break;
      case (degrees>45 && degrees<90):
        position = 'NNE';
        break;
      case (degrees==90):
        position = 'N';
        break;
      case (degrees>90 && degrees<135):
        position = 'NNW';
        break;
      case (degrees==135):
        position = 'NW';
        break;
      case (degrees>135 && degrees<180):
        position = 'WNW';
        break;
      case (degrees==180):
        position = 'W';
        break;
      case (degrees>180 && degrees<225):
        position = 'WSW';
        break;
      case (degrees==225):
        position = 'SW';
        break;
      case (degrees>225 && degrees<270):
        position = 'SSW';
        break;
      case (degrees==270):
        position = 'S';
        break;
      case (degrees>270 && degrees<315):
        position = 'SSE';
        break;
      case (degrees==315):
        position = 'SE';
        break;
      case (degrees>315):
        position = 'ESE';
        break;
    }
    return position;
  },

  // This function converts the unicode version of an accidental into the version required by VexFlow.
  // Since we are currently printing double flats as two unicode flats and double sharps as two unicode sharps
  // we have to iterate over the string's contents and convert to the VexFlow equivalent.
  formatUnicodeAccidental: function(acc) {
    var result = "";
    for (var i = 0; i < acc.length; i += 1) {
      var code = acc.charCodeAt(i);
      if (code==9837) {
        result += "b";
      }
      else if (code==9839) {
        result += "#";
      }
    }
    return result;
  },

  numberToDynamic: function(num, options={}) {
    const { velocityScale, velocityToExpression } = Tables;
    const { min=1, max=64 } = options;
    var range = max-min;
    if (num<min || num>max || range<=0) {
      throw new Error("Number is out of range, or range is improperly defined.");
    }
    else {
      var normalised = (num-min)/range;
      num = 127*normalised;
    }
    var index = Math.floor(num/16);
    var vel = velocityScale[index];
    return velocityToExpression[vel];
  },

};

export { NoteTools };
