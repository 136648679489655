/*
Edit Mode Toolbar, for notations which require different modes of realisation.
*/

import React from "react";
import TickList from "./widgets/tick-list";
import { useSelector, useDispatch } from "react-redux";

const DefaultMode = (props) => {

  const { editMode } = useSelector(state => state.notation);
  const dispatch = useDispatch();

  const notation = props.notation;

  const changeEditMode = (e, mode)=>{
    notation.editMode = mode;
    dispatch({ type: "CHANGE_EDIT_MODE", payload: mode });
  };

  let items = [
    { selected: true, onClick: changeEditMode, text: "Random (auto)" },
    { onClick: changeEditMode, text: "Manual Selection" }
  ];
  return (
    <div>
      <TickList icon="edit" widgetId="edit-mode" widgetTitle="Edit Mode" items={ items }/>
      { editMode=="Manual Selection" && <button type="button" className="btn btn-default" id="update" >Update</button> }
    </div>
  )
}

const EditModeToolbar = (props) => {
  let { notation, widgets: Widgets } = props;
  return (
    <div id="edit-mode-toolbar">
      { Widgets==="default" ? <DefaultMode { ...{ notation } }/> : <Widgets { ...{ notation } }/> }
    </div>
  )
}

export default EditModeToolbar;