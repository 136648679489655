const initialState = {
  showNotationInfo: false,
  showSettingsDialog: false,
  showAboutDialog: false,
  showRealisationList: false
};

const modalsReducer = (state=initialState, action) => {
  switch (action.type) {
    case "SHOW_NOTATION_INFO":
      return { ...state, showNotationInfo: !state.showNotationInfo };
    case "SHOW_SETTINGS_DIALOG":
      return { ...state, showSettingsDialog: !state.showSettingsDialog };
    case "SHOW_ABOUT_DIALOG":
      return { ...state, showAboutDialog: !state.showAboutDialog };
    case "SHOW_REALISATION_LIST":
      return { ...state, showRealisationList: !state.showRealisationList };
    default:
      return state;
  }
};

export default modalsReducer;