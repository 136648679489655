import ScoreObject from './score-object';
import { Utils } from '../utils';
import { Tables } from '../tables';

/**
 * Class representing a musical dynamic (p, mf, fff, etc).
 * @class CC.Core.Dynamic
 * @extends CC.Core.ScoreObject
 */
export default class Dynamic extends ScoreObject {

  constructor(options) {

    super(options);

    this.text = options.text;
    this.font = options.font || Dynamic.DEFAULT_FONT;

  }

  getElem() {
    return this.elem;
  }

  toVelocity() {
    var vToE = Dynamic.velocityToExpression;
    for (var key in vToE) {
      if (vToE[key]==this.getText()) return key;
    }
  }

  getText() {
    return this.text;
  }

  getFont() {
    return this.font;
  }

  remove() {
    var elem = this.getElem();
    elem.parentNode.removeChild(elem);
  }

  drawDragFilament(ctx, x1, y1, x2, y2) {
    var attr = {
      stroke: "#777",
      "stroke-width": 1,
      "stroke-opacity": 0,
      "fill-opacity": 0, // If we don't set fill-opacity to 0 Chrome will print the line as solid.
      "stroke-dasharray": [4,3]
    };
    ctx.line(x1, y1, x2, y2, attr).parent.lastChild;
    return this;
  }

  render(ctx, x, y, noteXY) {
    var expr = this.getText();
    ctx.openGroup();
    ctx.fillText(expr, x, y);
    // Add a "dynamic" class to the text element so we can add jQuery handlers (for dragging).
    // fillText returns nothing, so we access the text we added with ctx.parent.lastChild.
    var elem = ctx.parent.lastChild;
    elem.setAttribute("class", "dynamic draggable");
    if (noteXY) {
      this.drawDragFilament(ctx, x, y, noteXY.x, noteXY.y);
    }
    ctx.closeGroup();
    this.elem = elem;
    return this;
  }

}

Dynamic.velocityToExpression = Utils.merge({}, Tables.velocityToExpression);

Dynamic.DEFAULT_FONT = {
  family: 'gonville',
  size: 17,
  weight: 'italic'
};
