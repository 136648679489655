import update from 'immutability-helper';

// For the moment this just updates a single note struct.
// 'data' is the score data tree, 'path' is an object representing the 'address' of a note struct. Any
// keys in 'struct' will then be merged into the target note struct.
function immutableUpdate(data, path, struct) {
  let { stave: i=0, voice: j=0, noteStruct: k=0 } = path;
  return update( data, {staves: {[i]: {voices: {[j]: {noteStructs: {[k]: {$merge: struct} } } } } } } );
}

export { immutableUpdate };