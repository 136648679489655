/*
Notation Instructions Component.
*/

import React from "react";

const NotationInstructions = (props) => {
  let { instructions } = props;
  return (
    <div id="notation-instructions" >
      <blockquote id="notation-instructions-text">
        { instructions }
      </blockquote>
    </div>
  );
}

export default NotationInstructions;

