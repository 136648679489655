import ScoreObject from './score-object';
import { NoteTools } from './tools/scoretools/note-tools';
import { Graphics } from './graphics';

export default class NoteConnector extends ScoreObject {

  constructor(options) {

    super(options);

    this.startNote = options.startNote;
    this.endNote = options.endNote;
    this.startNotePitch = options.startNotePitch || 0;
    this.endNotePitch = options.endNotePitch || 0;
    this.drawToNoteCentre = options.drawToNoteCentre || false;
    this.renderOptions = options.renderOptions;
    this.isPath = false || options.isPath;
    this.isArrow = options.isArrow || false;
    this.arrowDirection = options.arrowDirection || "forwards"
    this.arrowHeadOffset = options.arrowHeadOffset || 1;
    this.arrowHeadSize = options.arrowHeadSize || 10;

  }

  getElem() {
    return this.elem;
  }

/*
  getNoteCoords() {
    var note1 = this.startNote;
    var note2 = this.endNote;
    var canvasHeight = this.getRenderContext().height;
    var posn = NoteTools.getNoteRelativePosition(note1, note2, canvasHeight);
    var paddingX = 5;
    var paddingY = 5;
    var fromX, fromY, toX, toY;
    var bbox1 = note1.getBoundingBox();
    var bbox2 = note2.getBoundingBox();
    switch (posn) {
      case 'E':
      case 'ENE':
        fromX = bbox1.x+bbox1.w+paddingX;
        fromY = bbox1.y+(bbox1.h/2);
        toX = bbox2.x-paddingX;
        toY = bbox2.y+(bbox2.h/2);
        break;
      case 'NE':
      case 'NNE':
      case 'N':
      case 'NNW':
      case 'NW':
        fromX = bbox1.x+(bbox1.w/2);
        fromY = bbox1.y-paddingY;
        toX = bbox2.x+(bbox2.w/2);
        toY = bbox2.y+bbox2.h+paddingY;
        break;
      case 'WNW':
      case 'W':
        fromX = bbox1.x-paddingX;
        fromY = bbox1.y+(bbox1.h/2);
        toX = bbox2.x+bbox2.w+paddingX;
        toY = bbox2.y+(bbox2.h/2);
        break;
      case 'WSW':
      case 'SW':
        fromX = bbox1.x-paddingX;
        fromY = bbox1.y+(bbox1.h/2);
        toX = bbox2.x+(bbox2.w/2);
        toY = bbox2.y-paddingY;
        break;
      case 'SSW':
      case 'S':
      case 'SSE':
        fromX = bbox1.x+(bbox1.w/2);
        fromY = bbox1.y+bbox1.h+paddingY;
        var acc = note2.getAccidental(0);
        toX = acc ? bbox2.x-(10*acc.length)+(bbox2.w+(10*acc.length)/2) : bbox2.x+(bbox2.w/2);
        toY = bbox2.y-paddingY;
        break;
      case 'SE':
      case 'ESE':
        fromX = bbox1.x+bbox1.w+paddingX;
        fromY = bbox1.y+(bbox1.h/2);
        toX = bbox2.x-paddingX;
        toY = bbox2.y+(bbox2.h/2);
        break;
    }
    return [fromX, fromY, toX, toY];
  }
*/

  getNoteCoords() {
    var startNote = this.startNote;
    var endNote = this.endNote;
    var startNoteHead = this.startNotePitch;
    var endNoteHead = this.endNotePitch;
    var fromX, fromY, toX, toY;
    // If our start and end notes are the same we draw a vertical line
    // connecting the start and end pitches.
    if (startNote===endNote || this.drawToNoteCentre) {
      fromX = startNote.getNoteCenterXY().x;
      fromY = startNote.getYs()[startNoteHead];
      toX = endNote.getNoteCenterXY().x;
      toY = endNote.getYs()[endNoteHead];
    }
    // Otherwise compute the correct coordinates.
    else {
      var canvasHeight = this.getRenderContext().height;
      var posn = NoteTools.getNoteheadRelativePosition(startNote, startNoteHead, endNote, endNoteHead, canvasHeight);
      var paddingX = 5;
      var paddingY = 5;
      var bbox1 = startNote.getBoundingBox();
      var x1 = bbox1.x;
      var y1 = startNote.getYs()[startNoteHead];
      var w1 = bbox1.w;
      var h1 = 0; //bbox1.h;
      var bbox2 = endNote.getBoundingBox();
      var x2 = bbox2.x;
      var y2 = endNote.getYs()[endNoteHead];
      var w2 = bbox2.w;
      var h2 = 0; //bbox2.h;
      switch (posn) {
        case 'E':
        case 'ENE':
          fromX = x1+w1+paddingX;
          fromY = y1+(h1/2);
          toX = x2-paddingX;
          toY = y2+(h2/2);
          break;
        case 'NE':
        case 'NNE':
        case 'N':
        case 'NNW':
        case 'NW':
          fromX = x1+(w1/2);
          fromY = y1-paddingY;
          toX = x2+(w2/2);
          toY = y2+h2+paddingY;
          break;
        case 'WNW':
        case 'W':
          fromX = x1-paddingX;
          fromY = y1+(h1/2);
          toX = x2+w2+paddingX;
          toY = y2+(h2/2);
          break;
        case 'WSW':
        case 'SW':
          fromX = x1-paddingX;
          fromY = y1+(h1/2);
          toX = x2+(w2/2);
          toY = y2-paddingY;
          break;
        case 'SSW':
        case 'S':
        case 'SSE':
          fromX = x1+(w1/2);
          fromY = y1+h1+paddingY;
          var acc = endNote.getAccidental(0);
          toX = acc ? x2-(10*acc.length)+(w2+(10*acc.length)/2) : x2+(w2/2);
          toY = y2-paddingY;
          break;
        case 'SE':
        case 'ESE':
          fromX = x1+w1+paddingX;
          fromY = y1+(h1/2);
          toX = x2-paddingX;
          toY = y2+(h2/2);
          break;
      }
    }
    return [fromX, fromY, toX, toY];
  }

/*
  drawArrowHead() {
    var ctx = this.getRenderContext();
    var coords = this.getNoteCoords();
    var x1 = this.drawToNoteCentre ? this.startNote.getNoteCenterXY().x : coords[0];
    var y1 = this.drawToNoteCentre ? this.startNote.getNoteCenterXY().y : coords[1];
    var x2 = this.drawToNoteCentre ? this.endNote.getNoteCenterXY().x : coords[2];
    var y2 = this.drawToNoteCentre ? this.endNote.getNoteCenterXY().y : coords[3];
    var dx = x2-x1;
    var dy = y2-y1;
    var angle = Math.atan2(y2-y1,x2-x1);
    var headOffset = this.arrowHeadOffset;
    var direction = this.arrowDirection;
    var size = this.arrowHeadSize;
    if (direction=="forwards") {
      Graphics.drawArrowHead(ctx, x1+dx*headOffset, y1+dy*headOffset, angle, size);
    }
    else if (direction=="backwards") {
      Graphics.drawArrowHead(ctx, x2-dx*headOffset, y2-dy*headOffset, angle+Math.PI, size);
    }
  }
*/

  drawArrowHead() {
    var ctx = this.getRenderContext();
    var coords = this.getNoteCoords();
    var x1 = this.drawToNoteCentre ? this.startNote.getNoteCenterXY().x : coords[0];
    var y1 = this.drawToNoteCentre ? this.startNote.getYs()[this.startNotePitch] : coords[1];
    var x2 = this.drawToNoteCentre ? this.endNote.getNoteCenterXY().x : coords[2];
    var y2 = this.drawToNoteCentre ? this.endNote.getYs()[this.endNotePitch] : coords[3];
    var dx = x2-x1;
    var dy = y2-y1;
    var angle = Math.atan2(y2-y1,x2-x1);
    var headOffset = this.arrowHeadOffset;
    var direction = this.arrowDirection;
    var size = this.arrowHeadSize;
    if (direction=="forwards") {
      Graphics.drawArrowHead(ctx, x1+dx*headOffset, y1+dy*headOffset, angle, size);
    }
    else if (direction=="backwards") {
      Graphics.drawArrowHead(ctx, x2-dx*headOffset, y2-dy*headOffset, angle+Math.PI, size);
    }
  }

/*
  render() {
    var ctx = this.getRenderContext();
    var start = this.startNote, end = this.endNote;
    var coords = this.drawToNoteCentre ? [start.getNoteCenterXY().x, start.getNoteCenterXY().y, end.getNoteCenterXY().x, end.getNoteCenterXY().y] : this.getNoteCoords();
    this.x1 = coords[0];
    this.y1 = coords[1];
    this.x2 = coords[2];
    this.y2 = coords[3];
    // Draw as either a path or line element.
    if (this.isPath===true) {
      ctx.beginPath();
      ctx.moveTo(this.x1, this.y1);
      ctx.lineTo(this.x2, this.y2);
      ctx.stroke();
    }
    else {
      ctx.line(this.x1, this.y1, this.x2, this.y2, this.renderOptions);
    }
    if (this.isArrow===true) {
      this.drawArrowHead();
    }
    var elem = ctx.parent.lastChild;
    elem.setAttribute("class", "note-connector");
    this.elem = elem;
    return this;
  }
*/

  addMouseEventProxy() {
    var ctx = this.getRenderContext();
    let targetWidth = parseInt( this.elem.getAttribute("stroke-width") );
    let { x1, y1, x2, y2 } = this;
    ctx.line(x1, y1, x2, y2, {
      "stroke": "black",
      "stroke-width": targetWidth + 6,
      "stroke-opacity": 0,
      "fill": "rgb(0,0,0,0)"
    });
    let proxy = ctx.parent.lastChild;
    ctx.applyAttributes(proxy, {
      "class": "note-connector-proxy",
      "data-target": this.id
    });
  }

  render() {
    var ctx = this.getRenderContext();
    var coords = this.getNoteCoords();
	  var x1 = this.x1 = coords[0];
    var y1 = this.y1 = coords[1];
    var x2 = this.x2 = coords[2];
    var y2 = this.y2 = coords[3];
	  if (this.isPath===true) {
      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    }
	  else {
      ctx.line(x1, y1, x2, y2, this.renderOptions);
    }
	  if (this.isArrow===true) {
      this.drawArrowHead();
    }
    var elem = ctx.parent.lastChild;
    elem.setAttribute("class", "note-connector");
    this.elem = elem;
    return this;
  }

}
