import React from "react";
import SettingsModal from "./settings-dialog/dialog";
import NotationInfoSidebar from "./notation-info-sidebar";
import RealisationListModal from "./realisation-list-modal";
import AboutDialog from "./about-dialog";

const Modals = () => {
  return (
    <div>
      <NotationInfoSidebar />
      <SettingsModal />
      <RealisationListModal />
      <AboutDialog />
    </div>
  );
}

export default Modals;