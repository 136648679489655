import React from "react";
import { IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const FlatButton = withStyles({
  root: {
    bordeRadius: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 11,
    paddingBottom: 11,
    "&:hover": {
      borderRadius: 0,
    },
    "&:focus": {
      borderRadius: 0,
    }
  },
})((props) => {
    const { disableRipple, title="", ...restProps } = props;
    return (
      <Tooltip title={title}>
        <IconButton disableRipple={true} { ...restProps }/>
      </Tooltip>
    );
  }
);

export default FlatButton;