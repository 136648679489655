import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { SettingsContext } from "./dialog";
import { RadioButtonGroup } from "../../widgets/radio-button";
import TimeField from "../../widgets/time-field";
import { Utils } from "../../../utils";

const TimeFormatSettings = () => {
  let { timeFormat, dispatch } = useContext(SettingsContext);
  const onChange = (e) => dispatch({
    type: "SET_TIME_FORMAT",
    payload: { timeFormat: e.target.value }
  });
  const items = [
    { id: "hh-mm-ss-radio", value: "hh:mm:ss", checked: (timeFormat==="hh:mm:ss"), label: "Hours, minutes, seconds (hh:mm:ss)", onChange: onChange },
    { id: "ms-radio", value: "milliseconds", checked: (timeFormat==="milliseconds"), label: "Milliseconds", onChange: onChange }
  ];
  return (
    <RadioButtonGroup items={ items }/>
  );
};

const MSField = (props) => {
  let { time, id, onChange } = props;
  let ms = Utils.timeToMs(time);
  const style = {padding: ".2rem 0 calc(.2rem - 1px)"};
  return (
    <input type="number" className="form-control" id={id} value={ms} min={1} step={1} onChange={onChange} style={style}/>
  );
};

const DurationSettings = () => {
  let { timeFormat, defaultRealisationDuration, defaultTimeGridSegmentSize, dispatch } = useContext(SettingsContext);
  const onChangeDuration = (values) => dispatch({ type: "SET_DEFAULT_REALISATION_DURATION", payload: { defaultRealisationDuration: values.hhmmss } });
  const onChangeTimeGrid = (values) => dispatch({ type: "SET_DEFAULT_TIMEGRID_SEGMENT_SIZE", payload: { defaultTimeGridSegmentSize: values.hhmmss } });
  const onChangeDurationMs = (e) => dispatch({ type: "SET_DEFAULT_REALISATION_DURATION", payload: { defaultRealisationDuration: Utils.msToTime(e.target.value) } });
  const onChangeTimeGridMs = (e) => dispatch({ type: "SET_DEFAULT_TIMEGRID_SEGMENT_SIZE", payload: { defaultTimeGridSegmentSize: Utils.msToTime(e.target.value) } });
  const timeFieldStyle = { width: "100%", textAlign: "start", fontSize: "1rem", fontWeight: 400 };
  const formatForLabel = timeFormat==="milliseconds" ? "ms" : timeFormat;
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6} className="mb-3">
        <label for="duration-input">{`Duration (${formatForLabel})`}</label>
        { timeFormat==="hh:mm:ss" ?
          <TimeField className="form-control" id="duration-input" time={defaultRealisationDuration} onChange={onChangeDuration} style={ timeFieldStyle }/> :
          <MSField id="duration-input" time={defaultRealisationDuration} onChange={onChangeDurationMs}/>
        }
      </Grid>
      <Grid item xs={6} className="mb-3">
        <label for="timegrid-seg-size-input">{`Time Grid Segment Size (${formatForLabel})`}</label>
        { timeFormat==="hh:mm:ss" ?
          <TimeField className="form-control" id="timegrid-seg-size-input" time={defaultTimeGridSegmentSize} onChange={onChangeTimeGrid} style={ timeFieldStyle }/> :
          <MSField id="timegrid-seg-size-input" time={defaultTimeGridSegmentSize} onChange={onChangeTimeGridMs}/>
        }
      </Grid>
    </Grid>
  );
};


const TimeSettings = () => {
  return (
    <div>
      <legend>Time</legend>
      <p>Control the format in which time is displayed within the realisation panel.</p>
      <TimeFormatSettings />
      <p style={ {marginTop: "0.2rem", marginBottom: "0.25rem"} }>Set the default duration and time grid segment size.</p>
      <DurationSettings />
    </div>
  );
}

export default TimeSettings;