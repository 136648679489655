import ScoreObject from './score-object';
import { Utils } from '../utils';
import Pitch from './pitch';
import Dynamic from './dynamic';
import GraceNoteGroup from './grace-note-group';
import { Graphics } from './graphics';
import { NoteTools } from './tools/scoretools/note-tools';

/**
 * Class representing a note (or chord) in a space-time score. A note must have at
 * least one pitch (represented by a CC.Core.Pitch instance), and a duration. Notes
 * currently do not draw themselves, but delegate that to their parent voice.
 * @class CC.Core.Note
 * @extends CC.Core.ScoreObject
 * @param {Object} options - Note options.
 * @param {string|string[]} options.pitches A single string or array of strings defining the pitch(es) for the note.
 * @param {string} options.duration - Duration of the note. The meaning of 'duration' is somewhat open, given that the duration for a notation in the original score is not determined. The actual values for durations in the input data typically come from the pixel measurements of the score scans. A general rule of thumb is to think of a duration as 'some multiple of a millisecond'.
 * @param {string} options.type - The 'type' of note, which typically determines the way it is rendered. Can be one of 'cluster', 'harmonic' or 'note' (for a normal note).
 * @param {Object.<CC.Core.Dynamic>} options.dynamic - The note dynamic, represented by a CC.Core.Dynamic instance.
 * @param {string} options.technique - The playing technique for the note - 'pizz', 'mute', 'normal', etc.
 * @param {Array.<string, string[]>} options.graceNotes - Array of strings representing grace notes to be attached to the note. Arrays of strings contained within the array will be interpreted as chord graces.
 */
export default class Note extends ScoreObject {

  constructor(options) {
    super(options);

    var pitches = Array.isArray(options.pitches) ? options.pitches : [options.pitches];

    this.pitches = pitches.map(function(pitch) {
      if (pitch instanceof String || typeof pitch === "string") {
        return Pitch.fromString(pitch);
      }
      else return pitch;
    });

    this.type = options.type || "note";
    this.duration = options.duration;
    this.clef = options.clef;
    this.dynamic = options.dynamic;
    this.showDynamic = false;
    this.dynamicX = options.dynamicX;
    this.dynamicY = options.dynamicY;
    this.technique = options.technique || "normal"; // pizz, mute, etc.
    this.articulation = options.articulation;
    this.graceNotes = options.graceNotes;
  }

  getPitches() {
    return this.pitches;
  }

  numPitches() {
    return this.getPitches().length;
  }

  getDynamic() {
    return this.dynamic; // Or compute from velocity?
  }

  setDynamic(dyn) {
    this.dynamic = dyn;
    return this.dynamic;
  }

  getDynamicText() {
    return this.dynamic.getText();
  }

  getAccidental(noteHeadIndex) {
    var index = noteHeadIndex || 0;
    return this._vfnote.getKeyProps()[index].accidental;
  }

  getTechnique() {
    return this.technique;
  }

  setTechnique(technique) {
    this.technique = technique;
    return this.technique;
  }

  getArticulation() {
    return this.articulation;
  }

  getVoice() {
    return this.voice;
  }

  getDuration() {
    return this.duration;
  }

  setDuration(dur) {}

  getOnset() {
    return this.getAbsoluteOnset();
  }

  setOnset(when) {}

  getAbsoluteOnset() {
    var notes = this.getVoice().getNotes();
    var posn = this.getIndex();
    var onset = parseInt(this.getVoice().getOffset());
    for (var i = 0; i < notes.length; i++) {
      if (i==posn) {
        break;
      }
      else onset += parseInt(notes[i].getDuration());
    }
    return onset;
  }

  getVoiceRelativeOnset() {
    var voiceOffset = this.getVoice().getOffset();
    return this.getAbsoluteOnset()-voiceOffset;
  }

  getAbsoluteX() {
    return this._vfnote.getAbsoluteX();
  }

  // We probably don't want to do this ultimately, but it's OK for now...
  getAbsoluteY() {
    return this._vfnote.getYs()[0];
  }

  getYs() {
    return this._vfnote.getYs();
  }

  getBoundingBox() {
    var bbox = this._vfnote.getBoundingBox();
    bbox.top = bbox.y;
    bbox.right = bbox.x+bbox.w;
    bbox.bottom = bbox.y+bbox.h;
    bbox.left = bbox.x;
    return bbox;
  }

  getElem() {
    return this._vfnote.getElem();
  }

  getRenderContext() {
    return this._vfnote.context;
  }

  getClef() {
    return this.clef;
  }

  getStave() {
    return this.getVoice().getStave();
  }

  getIndex() {
    var notes = this.getVoice().getNotes();
    return notes.indexOf(this);
  }

  getNextNote() {
    var next = null;
    var notes = this.getVoice().getNotes();
    var index = this.getIndex();
    if (index<notes.length-1) {
      next = notes[index+1];
    }
    return next;
  }

  getPreviousNote() {
    var prev = null;
    var notes = this.getVoice().getNotes();
    var index = this.getIndex();
    if (index>0) {
      prev = notes[index-1];
    }
    return prev;
  }

  getNoteheads() {
    return this._vfnote.note_heads;
  }

  getPitchPosition(pitch) {
    var posn;
    var pitches = this.getPitches();
    for (var i=0; i<pitches.length; i++) {
      var pitchClass = pitches[i].getClass().toLowerCase();
      var octave = pitches[i].getOctave();
      if (pitchClass+'/'+octave==pitch.toLowerCase()) {
        posn = i;
        break
      }
    }
    return posn;
  }

  getPitchRange() {
    var pitches = [].concat(this.serialize().pitches);
    if (pitches.length>1) {
      let sorted = NoteTools.sortNoteStrings(pitches);
      return [sorted[0], sorted[sorted.length-1]];
    }
    return pitches;
  }

  getAttachments() {
    return this.attachments;
  }

/*
  getLine(pitchIndex) {
    var index = pitchIndex || 0;
    var vfnote = this._vfnote;
    return vfnote.getKeyProps()[index].line;
  }
*/

  getLine(pitchIndex) {
    var index = pitchIndex || 0;
    var key = this.getPitches()[index].toString();
    var props = Vex.Flow.keyProperties(key, this.clef);
    return props.line;
  }

// This is problematic with respect to chords.
  getNoteCenterXY() {
    var xBegin = this._vfnote.getNoteHeadBeginX();
    var xEnd = this._vfnote.getNoteHeadEndX();
    return {
      x: xBegin + ((xEnd-xBegin)/2),
      y: this.getAbsoluteY()
    };
  }

  getLedgerLines() {
    var elem = this.getElem();
    var ledgerLines = [];
    var prev = elem.previousSibling;
    while (prev.nodeName.toLowerCase()=='rect') {
      // Even though we only want to find ledger lines sometimes the start and end stave
      // connectors also get included. This logic checks if we have a ledger line rect
      // by checking if the width is greater than the height, which it will be for a ledger
      // line but not for a stave connector (since the latter is vertical). On reaching a
      // stave connector we must then manually break, otherwise we'll have an infinite 'while' loop.
      var bbox = prev.getBBox();
      if (bbox.width>bbox.height) {
        ledgerLines.push(prev);
        //console.log(prev);
        prev = prev.previousSibling;
      }
      else break;
    }
    return ledgerLines;
  }

  // This is a hack to set the note (including any ledger lines it might have)
  // transparent. It can only be called after the note has actually been
  // rendered, though that doesn't seem to result in any momentary 'flashing'
  // of an opaque note.
  setTransparent() {
    const elem = this.getElem();
    this.hideLedgerLines();
    Graphics.setElemColor($(elem), "rgba(0,0,0,0)");
  }

  hideLedgerLines() {
    const rgba = "rgba(0,0,0,0)";
    const ledgerLines = this.getLedgerLines();
    if (ledgerLines.length>0) {
      ledgerLines.forEach(line => {
        line.setAttributeNS(null, "fill", rgba);
        line.setAttributeNS(null, "stroke", rgba);
      });
    }
    return this;
  }

  isChord() {
    return this.numPitches()>1 ? true : false;
  }

  toMidi() {
    var midis = this.getPitches().map(pitch => pitch.toMidi());
    return this.isChord() ? midis : midis[0];
  }

  getNoteheadElemIndex(notehead) {
    // This is the HTMLCollection containing all the noteheads. We need this to find the index of the notehead.
    var noteheads = notehead.parentNode.children;
    noteheads = Utils.htmlCollectionToArray(noteheads);
    return noteheads.indexOf(notehead);
  }

  /*
  getKeyAtIndex(index) {
    return this._vfnote.getKeyProps()[index].key; // We should also add the octave.
  }
  */

  // This version includes the octave if the second arg is passed as 'true'.
  getKeyAtIndex(index, includeOctave) {
    let { key, octave } = this._vfnote.getKeyProps()[index];
    return includeOctave===true ? key+"/"+octave : key;
  }

  remove() {
    var elem = this.getElem();
    var parentNode = elem.parentNode;
    // The elements immediately before the note (irrespective of whether the note is below or above the
    // stave) will be rect elements which represent the ledger lines. So we use 'previousSibling' to
    // loop backwards while we have a rect element, and remove it.
    while (elem.previousSibling.nodeName.toLowerCase()=='rect') {
      // Even though we only want to remove ledger lines sometimes the start and end stave
      // connectors also get removed. This logic checks if we have a ledger line rect
      // by checking if the width is greater than the height, which it will be for a ledger
      // line but not for a stave connector (since the latter is vertical). On reaching a
      // stave connector we must then manually break, otherwise we'll have an infinite 'while' loop.
      var bbox = elem.previousSibling.getBBox();
      if (bbox.width>bbox.height) {
        parentNode.removeChild(elem.previousSibling);
      }
      else break;
    }
    // Now remove the note itself.
    parentNode.removeChild(elem);
    if (this.getDynamic() && this.showDynamic==true) {
      this.getDynamic().remove();
    }
  }

  transpose(amount) {}

  respell(keyIndex, key, acc) {
    var pitches = this.serialize().pitches;
    var _pitches = Array.isArray(pitches) ? pitches : [pitches];
    var [, octave ] = _pitches[keyIndex].split('/');
    _pitches[keyIndex] = key[0]+acc+"/"+octave;
    //console.log(_pitches[keyIndex]);
    this.pitches = _pitches.map(pitch => {
      if (pitch instanceof String || typeof pitch === "string") {
        return Pitch.fromString(pitch);
      }
      else return pitch;
    });
  }

  hasAccidental() {}

  setStyle(styleStruct) {
    //this._vfnote.setStyle(styleStruct);
    this.style = styleStruct;
  }

  drawGraceNotes() {
    let group = GraceNoteGroup.drawGraceNotes(this._vfnote.context, this, this.graceNotes);
    this.graceNoteGroup = group;
    return this;
  }
  
  drawDynamic() {
    const ctx = this._vfnote.context; //this.getRenderContext();
    const dynamic = this.getDynamic();
    let xAdjust = this.getDynamicText().length>1 ? 5 : 0;
    let yAdjust = 28;
    let { dynamicX, dynamicY } = this;
    const stave = this.getStave();
    const scoreWidth = stave.getEffectiveWidth();
    const startX = stave.getNoteStartX();
    let x = dynamicX ? (dynamicX * scoreWidth) + startX : this.getAbsoluteX() - xAdjust;
    let y = dynamicY || Math.max(stave.getYForBottomText(1), this.getYs()[0] + yAdjust);
    ctx.save();
    let { family, size, weight } = Dynamic.DEFAULT_FONT;
    ctx.setFont(family, size, weight);
    dynamic.render(ctx, x, y, this.getNoteCenterXY());
    dynamic.getElem().setAttribute("data-note-id", this.getID());
    ctx.restore();
  }

  drawStaccatoPoint() {
    var ctx = this.getRenderContext();
    var stave = this.getStave();
    var { x, y } = this.getNoteCenterXY();
    var staveCenterY = stave.getYForLine(2);
    var yAdjust = y >= staveCenterY ? 10 : -10;
    ctx.circle(x, y+yAdjust, 1.5, { fill: "black" });
  }

  drawArticulation(type) {
    switch (type) {
      case "staccato":
        this.drawStaccatoPoint();
    }
  }

  drawTechnique() {
    var ctx = this.getRenderContext();
    ctx.save();
    var technique = this.getTechnique();
    var stave = this._vfnote.getStave();
    var textWidth = ctx.measureText(technique).width;
    var xAdjust = textWidth / 2;
    var x =  this.getNoteCenterXY().x - xAdjust;
    var yAdjust = 24;
    var y = Math.min(stave.getYForTopText(1) + 6, this._vfnote.getYs()[0] - yAdjust);
    ctx.fillText(technique, x, y);
    ctx.restore();
  }

  /*
  drawCluster() {
    var ctx = this._vfnote.context;
    var x = this.getNoteCenterXY().x + 0.5;
    var Ys = this.getYs();
    var topY = Ys[0];
    var bottomY = Ys[Ys.length-1];
    var thickness = 5; //bbox.w - 4;
    var lineWidth = ctx.lineWidth;
    ctx.save();
    ctx.beginPath();
    ctx.setLineWidth(thickness);
    ctx.moveTo(x, topY);
    ctx.lineTo(x, bottomY);
    ctx.stroke();
    ctx.restore(); // The restore method doesn't seem to reset the lineWidth, so we have to do it manually.
    ctx.setLineWidth(lineWidth);
    // This moves the rendered path to just before the note DOM element.
    // Otherwise the line sits on top of the start and end noteheads, which
    // interferes with note selection.
    let path = ctx.parent.lastChild;
    ctx.svg.insertBefore(path, this.getElem());
  }
  */

  drawCluster() {
    let { crossStaffClusterPart: part } = this;
    if (part) {
      this.drawCrossStaffClusterPart(part);
    }
    else {
      var x = this.getNoteCenterXY().x + 0.5;
      var Ys = this.getYs();
      var y1 = Ys[0];
      var y2 = Ys[Ys.length-1];
      this.drawClusterLine(x, y1, y2);
    }
  }

  drawCrossStaffClusterPart(part) {
    var x = this.getNoteCenterXY().x + 0.5;
    var y1 = this.getYs()[0];
    var stave = this.getStave();
    if (part==="upper") {
      let y2 = stave.getYForLine(5);
      this.drawClusterLine(x, y1, y2);
    }
    else if (part==="lower") {
      let y2 = stave.getYForLine(-1);
      this.drawClusterLine(x, y1, y2);
    }
  }

  drawClusterLine(x, y1, y2) {
    var ctx = this._vfnote.context;
    var thickness = 5; //bbox.w - 4;
    var lineWidth = ctx.lineWidth;
    ctx.save();
    ctx.beginPath();
    ctx.setLineWidth(thickness);
    ctx.moveTo(x, y1);
    ctx.lineTo(x, y2);
    ctx.stroke();
    ctx.restore(); // The restore method doesn't seem to reset the lineWidth, so we have to do it manually.
    ctx.setLineWidth(lineWidth);
    // This moves the rendered path to just before the note DOM element.
    // Otherwise the line sits on top of the start and end noteheads, which
    // interferes with note selection.
    let path = ctx.parent.lastChild;
    ctx.svg.insertBefore(path, this.getElem());
  }

  drawSustain(options={ type: "curve" }) {
    let { type } = options;
    type==="curve" ? this.drawSustainCurve(options) : this.drawSustainLine(options);
  }

  drawSustainCurve(options) {
    var options = Utils.merge({
      pitch: 0,
      controlPoints: [{x: 0, y: 4}, {x: 0, y: 4}],
      length: 12,
      position: "after"
    }, options || {});
    const ctx = this.getRenderContext();
    var stave = this.getVoice().stave;
    let { pitch, controlPoints, length, posn } = options;
    let bbox = this.getBoundingBox();
    let padding = 5;
    let startX;
    if (posn=="after") {
      startX = bbox.x+bbox.w+padding;
    } else if (posn=="before") {
      startX = bbox.x-length-padding;
    }
    let startY = this.getYs()[pitch];
    let staveCenterY = stave.getYForLine(2);
    let direction = startY>=staveCenterY ? 1 : -1;
    Graphics.drawCurve(ctx, startX, startX+length, startY, startY-1, controlPoints, direction, 2);
  }

  drawSustainLine(options={}) {
    let {
      scoreDuration,
      sustainDuration=this.__data__.sustainDuration || this.getDuration(),
      width=this.getStave().getEffectiveWidth(),
      lineAttr={
      "stroke": "black",
      "stroke-width": 6,
      "stroke-opacity": 0.2
      }
    } = options;
    const ctx = this.getRenderContext();
    let len = (sustainDuration / scoreDuration) * width;
    let { x } = this.getNoteCenterXY();
    this.getYs().forEach(y => {
      ctx.line(x, y, x + len, y, lineAttr);
      let line = ctx.parent.lastChild;
      ctx.svg.insertBefore(line, this.getElem());
    });
  }

  serialize(data) {
    var pitches = this.getPitches().map(pitch => pitch.toString());
    var obj = {
      pitches: pitches.length>1 ? pitches : pitches[0],
      duration: this.getDuration()
    };
    this.dynamic && (obj.dynamic = this.getDynamicText());
    this.dynamicX && (obj.dynamicX = this.dynamicX);
    this.dynamicY && (obj.dynamicY = this.dynamicY);
    !(this.type==="note") && (obj.type = this.type);
    this.graceNotes && (obj.graceNotes = this.graceNotes);
    !(this.technique==="normal") && (obj.technique = this.technique);
    this.articulation && (obj.articulation = this.articulation);
    if (data) {
      if (data==="all") {
        for (let key in this.__data__) {
          obj[key] = this.__data__[key];
        }
      }
      else if ( Array.isArray(data) && data.length>0 ) {
        data.forEach(key => obj[key]=this.__data__[key]);
      }
    }
    return obj;
  }

  /*
  getPath() {
    let voice = this.getVoice();
    let stave = voice.getStave();
    let noteIndex = voice.getNotes().indexOf( this );
    let voiceIndex = stave.getVoices().indexOf( voice );
    let staveIndex = this.score.getStaves().indexOf( stave );
    return {
      stave: staveIndex,
      voice: voiceIndex,
      noteStruct: noteIndex
    };
  }
  */

  accept(visitor) {
    visitor.visitNote(this);
  }

  // This is now being called, but it's still the VexFlow Voice which actually
  // renders the VexFlow Notes.
  render() {

    // Get the pitches for the note.
    var pitches = this.getPitches().map(function(pitch) {
      return pitch.getClass()+'/'+pitch.getOctave();
    });

    var type = this.type;

    /*
    // If we have a cluster, limit the pitches to just the top and bottom notes.
    // We probably want to sort the pitches beforehand, though.
    if (type=="cluster") {
      var bottom = pitches[0];
      var top = pitches[pitches.length-1];
      pitches = [bottom, top];
    }
    */

    // If we have a cluster, limit the pitches to just the top and bottom notes.
    // if the note is one half of a cross-staff cluster we only want one of the notes,
    // either the top of bottom one.
    if (type=="cluster") {
      var bottom = pitches[0];
      var top = pitches[pitches.length-1];
      if (this.crossStaffClusterPart==="lower") {
        pitches = [bottom];
      }
      else if (this.crossStaffClusterPart==="upper") {
        pitches = [top];
      }
      else {
        pitches = [bottom, top];
      }
    }

    // We use the VexFlow harmonic notehead type to render harmonic notes (though
    // as we're actually always rendering VexFlow crotchet noteheads we reset the
    // glyph to that for a minim - see core.js.)
    var vfType = this.type=="harmonic" ? "h" : "n";

    // Create the VexFlow Note.
    this._vfnote = new Vex.Flow.StaveNote({
      keys: pitches,
      duration: this.getDuration(),
      clef: this.getClef(),
      type: vfType
    });

    this._vfnote.setStyle(this.style);

    // Add the accidentals for the VexFlow Note.
    var keyProps = this._vfnote.keyProps;
    for (var i = 0; i < keyProps.length; i++) {
      var acc = keyProps[i].accidental;
      if (acc) {
        this._vfnote.addAccidental(i, new Vex.Flow.Accidental(acc))
      }
    }

    // Set the id for the VexFlow Note. This also sets the id attribute for the
    // associated SVG element. Might be better to set it separately as a 'data-id'
    // attribute, as we could then avoid the 'vf-' prefix that VexFlow adds to the id.
    this._vfnote.setId(this.getID());

    // Add any grace notes.
    /*
    var graceNotes = this.graceNotes;
    if (graceNotes.length>0) {
      this.graceNoteGroup = this.addGraceNotes(graceNotes);
    }
    */

    /*
    this._vfnote.draw();
    if (this.showDynamic==true) {
      this.drawDynamic();
    }
    */
    return this;
  }

// The modifications below are required if we want to call _vfnote.draw directly.
// Also see the accompanying changes (likewise commented out for now) under voice.js
/*
setStave(stave) {
  this.stave = stave;
  this._vfnote.setStave(stave._vfstave);
  return this;
}

setRenderContext(ctx) {
  this.renderContext = ctx;
  this._vfnote.setContext(ctx);
  return this;
}

render() {
  this._vfnote.draw();
  if (this.showDynamic==true) {
    this.drawDynamic();
  }
  var technique = this.getTechnique();
  if (technique!=="normal") {
    this.drawTechnique();
  }
  return this;
}
*/

}
