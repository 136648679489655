import { Utils } from '../../utils'

/**
 * Base class for the composite hierarchy representing a score.
 * @class CC.Core.Component
 */
export default class Component {

  constructor(options) {
    this.info = options.info || "";
    this.setID(options.id);
  }

  /**
   * Returns the id of the component.
   * @returns {string} The id.
   */
  getID() {
    return this.id;
  }

  /**
   * @param {string} id - Sets the id for the component. If not supplied the id is set to a UUID.
   */
  setID(id) {
    this.id = id || Utils.guid();
    return this.id;
  }

  /**
   * Returns the info string of the component.
   * @returns {string} The info string.
   */
  getInfo() {
    return this.info;
  }

  /**
   * @param {string} text - Sets the info string for the component.
   */
  setInfo(text) {
    this.info = text;
    return this.info;
  }

  accept(visitor) {}

}
