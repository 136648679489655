import Spanner from '../base/spanner';
import { Utils } from '../../utils';
import NoteConnector from '../note-connector';
import { NoteTools } from '../tools/scoretools/note-tools';
import { Graphics } from '../graphics';

export default class NoteGroupSpanner extends Spanner {

  constructor(options) {
    super(options);
    this.drawConnectors = options.drawConnectors || false;
    this.isBeamed = options.isBeamed || false;
    this.beamOptions = options.beamOptions;
    this.connectors = [];
    this.connectorType = options.connectorType;
    this.id = options.id || Utils.guid();
    this.drawCentredConnectors = options.drawCentredConnectors || false;
    this.connectorStyle = options.connectorStyle;
    this.hasPolygon = options.hasPolygon || false;
    this.__data__ = {};

    // Copy any extra keys to the __data__ struct.
    for (var key in options) {
      if (options.hasOwnProperty(key) && !Utils.contains(NoteGroupSpanner.STANDARD_OPTIONS, key)) {
        this.__data__[key] = options[key];
      }
    }
  }

  // Static method for building an array of note groups.
  static build(notes) {
    var groups = [];
    for (var i=0; i<notes.length; i++) {
      var note = notes[i];
      if (note.__data__.hasOwnProperty("group")) {
        var options = Utils.merge({
          items: [],
          drawConnectors: true,
          drawCentredConnectors: true
        }, note.__data__.groupOptions || {});
        var noteGroup = new NoteGroupSpanner(options);
        noteGroup.addMembers(notes, note.__data__.members);
        groups.push(noteGroup);
      }
    }
    return groups;
  }

  // We only have two styles at the moment, dashed or the default (no need to return anything for the latter);
  getConnectorStyleRenderOptions(style) {
    let options;
    switch (style) {
      case "dashed":
        options = { stroke: "black", "stroke-width": 1, "stroke-dasharray": [4,3] };
        break;
      case "wide":
        options = { stroke: "black", "stroke-width": 4 };
        break;
    }
    return options;
  }

  attach() {
    var notes = this.getTarget().getNotes();
    var members = this.__data__.members;
    this.addMembers(notes, members);
    return this;
  }

  addMembers(notes, members) {
    if (typeof members === 'string' || members instanceof String) {
      this.addMembersFromSequence(notes, members);
    }
    else if (Array.isArray(members)) {
      members.forEach(memb => {
        if (Array.isArray(memb)) {
          this.addMembersFromArray(notes, memb);
        }
      });
    }
  }

  addMembersFromArray(notes, arr) {
    var [ i, j, pitchIndices=[[0,0]] ] = arr;
    var startNote = notes[i];
    var endNote = notes[j];
    if (!Utils.contains(this.items, startNote)) {
      this.items.push(startNote);
    }
    if (!Utils.contains(this.items, endNote)) {
      this.items.push(endNote);
    }
    pitchIndices.forEach(pi => {
      var connector = new NoteConnector({
        startNote: startNote,
        endNote: endNote,
        startNotePitch: pi[0],
        endNotePitch: pi[1],
        drawToNoteCentre: this.drawCentredConnectors,
        isPath: this.connectorType=="path",
        renderOptions: this.getConnectorStyleRenderOptions(this.connectorStyle) 
      });
      this.connectors.push(connector);
    });
  }

  //"0,2_7,9,24,50_73,0:91_216,346:8:79";

  _addOneMember(notes, index) {
    let note = notes[ parseInt(index) ];
    let items = this.items;
    !Utils.contains(items, note) && items.push(note);
  }

  _addMembersFromSlice(notes, _start, _end) {
    let start = parseInt(_start);
    let end = parseInt(_end);
    var slice = (start<end) ? notes.slice(start, end+1) : Utils.reverse( notes.slice(end, start+1) );
    var startNote = slice[0];
    var endNote = slice[slice.length-1];
    var trimStart = !(Utils.contains(this.items, startNote)) ? 0 : 1;
    var trimEnd = !(Utils.contains(this.items, endNote)) ? undefined : -1;
    var trimmed = slice.slice(trimStart, trimEnd);
    this.items = [ ...this.items, ...trimmed ];
    for (let i=1; i<slice.length; i++) {
      var connector = new NoteConnector({
        startNote: slice[i-1],
        endNote: slice[i],
        drawToNoteCentre: this.drawCentredConnectors,
        isPath: this.connectorType=="path", // ????
        renderOptions: this.getConnectorStyleRenderOptions(this.connectorStyle) 
      });
      this.connectors.push(connector);
    }
  }

  _addMembers(notes, start, end) {
    if (!end) {
      this._addOneMember(notes, start);
    }
    else {
      this._addMembersFromSlice(notes, start, end);
    }
  }

  addMembersFromSequence(notes, members) {
    let deColonised = members.split(":");
    deColonised.forEach(arr => {
      let commaChunks = arr.split(",");
      commaChunks.forEach((chunk, i) => {
        let [start, end] = chunk.split("_");
        if (i>0) {
          let lastChunk = commaChunks[i-1];
          let lastChunkEnd = Utils.reverse( lastChunk.split("_") )[0];
          this.addMembersFromArray(notes, [ parseInt(lastChunkEnd), parseInt(start) ]);
        }
        this._addMembers(notes, start, end)
      });
    }); 
  }

  getBoundingBox() {
    var x = this.earliest().getAbsoluteX();
    var y = this.highest();
    var w = this.latest().getAbsoluteX()-x;
    var h = this.lowest()-y;
    return {
      x: x,
      y: y,
      w: w,
      h: h,
      top: y,
      bottom: this.lowest(),
      left: x,
      right: this.latest().getAbsoluteX()
    };
  }

  getConnectors() {
    return this.connectors;
  }

  /*
  toPolygon() {
    var ctx = this.getRenderContext();
    var points = [];
    this.getConnectors().forEach(conn => {
      var el = conn.getElem();
      points = points.concat([el.getAttribute("x1"), el.getAttribute("y1")]);
    });
    var poly = ctx.polygon(points, {
      fill: "rgba(0,0,0,0)",
      "stroke-opacity": 0,
      "stroke-width": 1, //1.5,
      stroke: "red"
    });
    return poly;
  }
  */

  // This version of toPolygon allows us to add a polygon even when we don't join the
  // last connector to the first. We need this in notations like AE, which have polygons
  // with a 'missing' edge.
  toPolygon() {
    var ctx = this.getRenderContext();
    const conn0 = this.getConnectors()[0];
    let points = Graphics.getAttributes(conn0.getElem(), [ "x1", "y1", "x2", "y2" ]);
    this.getConnectors().slice(1).forEach(conn => {
      var el = conn.getElem();
      if (!(conn.endNote===conn0.startNote)) {
        points = points.concat([el.getAttribute("x2"), el.getAttribute("y2")]);
      }
    });
    var poly = ctx.polygon(points, {
      fill: "rgba(0,0,0,0)",
      "stroke-opacity": 0,
      "stroke-width": 1,
      stroke: "red"
    });
    return poly;
  }

  getPolygon() {
    var poly;
    if (this.hasPolygon===true) {
      var elem = this.elem;
      poly = elem.getElementsByTagName("polygon")[0];
    }
    return poly;
  }

  getIntersection(line) {
    const poly = this.getPolygon();
    if (!poly) return;
    const shape1 = new Polygon(poly);
    const shape2 = new Line(line);
    var { points } = Intersection.intersectShapes(shape1, shape2);
    return points.sort((a,b) => a.y-b.y);
  }

  getIntersectionAt(x) {
    const poly = this.getPolygon();
    if (!poly) return;
    const ctx = this.getRenderContext();
    var lineAttr = {
      stroke: "red",
      "stroke-opacity": 0,
      "stroke-width": 1
    };
    const line = ctx.line(x, 0, x, 2000, lineAttr).parent.lastChild;
    const points = this.getIntersection(line);
    line.remove();
    return points;
  }

  getRandomSamplePoints(max) {
    var max = max || 3;
    var startNote = this.earliest();
    var endNote = this.latest();
    var keys = NoteTools.getKeysBetween(startNote, endNote);
    var numEvents = Utils.getRandomIntInclusive(1, max);
    var samplePoints = Utils.repeatN(numEvents, function() {
      return Utils.getRandomIntInclusive(startNote.getOnset(), endNote.getOnset());
    });
    samplePoints.sort(function(a, b) {
      return a-b;
    });
    return samplePoints;
  }

  getOnset() {
    return this.earliest().getOnset();
  }

  earliest() {
    var notes = this.getItems();
    var earliest = notes[0];
    notes.forEach(note => {
      var onset = note.getOnset();
      if (onset<earliest.getOnset()) {
        earliest = note;
      }
    });
    return earliest;
  }

  latest() {
    var notes = this.getItems();
    var latest = notes[0];
    notes.forEach(note => {
      var onset = note.getOnset();
      if (onset>latest.getOnset()) {
        latest = note;
      }
    });
    return latest;
  }

  /*
  highest() {
    var notes = this.getItems();
    var highest = notes[0];
    notes.forEach(function(note) {
      var y = note._vfnote.getYs()[note.numPitches()-1];
      if (y<highest._vfnote.getYs()[highest.numPitches()-1]) {
        highest = note;
      }
    });
    return highest;
  }

  lowest() {}
  */

  highest() {
    var notes = this.getItems();
    var highest = Math.min.apply(Math, notes.map(note => {
      var index = note.numPitches()-1;
      return note._vfnote.getYs()[index];
    }));
    return highest;
  }

  lowest() {
    var notes = this.getItems();
    var lowest = Math.max.apply(Math, notes.map(note => {
      return note._vfnote.getYs()[0];
    }));
    return lowest;
  }

  sample(options) {
    var result;
    var source = options.source;
    var size = options.size;
    var resultType = options.resultType || "single notes";
    switch (source) {
      case "perimeter":
      result = this.samplePerimeter(size);
      break;
      case "region":
      result = this.sampleRegion(size);
      break;
      default:
      result = this.sampleItems(size);
      break;
    }
    return result;
  }

  sampleItems(size) {
    var notes = this.getItems();
    var size = size || Utils.getRandomIntInclusive(1, notes.length-1);
    return Utils.getRandomSubarray(notes, size).sort((a, b) => {
      return a.getOnset()-b.getOnset();
    });
  }

  samplePerimeter(size) {}

  sampleRegion(size) {}

  groupOrderToTimeOrder() {
    var notes = this.getItems();
    var onsets = notes.map(note => note.getOnset());
    onsets.sort((a,b) => {
      return a-b;
    });
    var durations = [];
    for (var i=1; i<onsets.length; i++) {
      durations.push(onsets[i]-onsets[i-1]);
    }
    durations.push(this.latest().getDuration());
    var noteStructs = notes.map((note, index) => {
      var struct = note.serialize();
      struct.duration = durations[index];
      return struct;
    });
    return noteStructs;
  }

  toVertices() {
    var notes = this.getItems();
    var vertices = notes.map(note => note.getNoteCenterXY());
    return vertices;
  }

  getDuration() {}

  getRange() {}

  serialize() {
    var notes = this.getItems();
    var data = notes.map(function(note) {
      return note.serialize();
    });
    return data;
  }

  drawBeam() {
    var ctx = this.getRenderContext();
    var notes = this.getItems();
    var options = this.beamOptions;
    Graphics.drawBeam(ctx, notes, options)
  }

  render() {
    var ctx = this.getRenderContext();
    this.elem = ctx.openGroup("note-group", this.id);
    if (this.drawConnectors==true) {
      this.getConnectors().forEach(function(conn) {
        conn.setRenderContext(ctx).render();
      });
    }
    if (this.hasPolygon===true) {
      this.toPolygon();
    }
    ctx.closeGroup();
    if (this.isBeamed==true) this.drawBeam();
    return this;
  }

}

NoteGroupSpanner.STANDARD_OPTIONS = [
  "items",
  "drawConnectors",
  "drawCentredConnectors",
  "isBeamed",
  "beamOptions",
  "id",
  "hasPolygon"
];
