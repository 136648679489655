import React from "react";
import { PageTopBar } from "./layout";

const MinimalConfig = () => {
  let toc = [
    [
      {text: "Default Notation", href: "#default-notation-setting"},
      {text: "Notation Instructions", href: "#notation-instructions-setting"},
      {text: "Time", href: "#time-settings"}
    ],
  ];
  return (
    <section id="user-config">
      <PageTopBar title="4. Configuration" toc={toc}/>
      <p>The Settings Dialog, available from the 'cog' icon on the main menu bar, contains a small number of controls which can be used to configure the app. It is likely that in future more configuration options will be added, organised under different categories.</p>
      <p>At the bottom of the dialog are three buttons - <em>Restore Defaults</em>, <em>Close</em> and <em>Save</em>. Making any changes within the dialog will cause the <em>Save</em> and <em>Restore Defaults</em> buttons to become activated. Saving will persist any changes to the browser's local storage, so the changes will remain even after a page refresh. <em>Restore Defaults</em> may then be used to reset the application back to its default state.</p>
      <p>Please note that since user settings are persisted locally to browser storage, clearing the browser's saved data will therefore also reset the application to its defaults.</p>
      <div className="img-panel">
        <img src="/img/user-guide/minimal-settings.png" />
      </div>
      <p>The following sections describe the various controls available within the dialog, and their different functions.</p>
      <h4 id="default-notation-setting">5.1. Default Notation</h4>
      <section>
        <p>Use this setting to control the notation that will be displayed when the application is loaded in the browser. There are two mutually-exclusive options available for this setting:</p>
        <ul>
          <li><h6>Use selected</h6>
            <p>Choose this option to set the default notation to whatever has been chosen from the dropdown selectors for <em>Type</em> and <em>Page</em> (these selectors will be greyed-out for the 'None (random selection)' option). To set the default notation to the one currently loaded select 'Current' from the top of each dropdown.</p>
          </li>
          <li><h6>None (random selection)</h6>
            <p>Choose this option to cause a randomly selected notation to be loaded each time the application is opened in the browser.</p>
          </li>
        </ul>
      </section>
      <h4 id="notation-instructions-setting">5.2. Notation Instructions</h4>
      <section>
        <p>Use this setting to determine the location of the Notation Instructions in the user interface. By default the instructions are displayed below the main menubar, above the transcription, but may be moved to the Notation Information sidebar by selecting that option.</p>
      </section>
      <h4 id="time-settings">5.3. Time</h4>
      <section>
        <p>This section within the dialog contains several settings relating to time.</p>
        <ul>
          <li><h6>Time Format</h6>
            <p>Choose from two available options - hours, minutes, seconds (hh:mm:ss); or milliseconds. all components of the realisation panel which display time-related information will then use the selected format.</p>
          </li>
          <li><h6>Duration</h6>
            <p>Set the initial duration for the realisation. Defaults to 15 seconds.</p>
          </li>
          <li><h6>Time Grid Segment Size</h6>
            <p>Set the initial segment size for the time grid. Defaults to 5 seconds.</p>
          </li>
        </ul>
      </section>
    </section>
  );
}

export default MinimalConfig;