import React, { Fragment, useContext } from "react";
import ButtonGroup from "../widgets/button-group";
import GlyphIconButton from "../widgets/glyphicon-button";
import { Mobile, NotMobile } from "../hooks/use-media-query";
import { RealisationContainerContext } from "../realisation-container";

// Input for displaying/editing the realisation title. Not sure if it should be an input yet, perhaps better
// to place it as editable text on the score itself (problem with that, however, is how to inform the user
// that it is editable). Not decided on this yet, so not adding it in to the UI at this time. To do so add
// it to the file controls, by wrapping it and the button group in a span (title comes first, obviously).
function RealisationTitle(props) {
  return (
    <input className="form-control" id="realisation-title" type="text" title="Title" autoComplete="off" minlength="1" maxlength="256" value={props.title} placeholder="Untitled" style={ {display: "inline-block", width: "120px"} } />
  )
}

const FileControlsButtonGroup = () => {
  const context = useContext(RealisationContainerContext);
  const { load, save, print } = context;
  return (
    <div className="btn-group mr-2" id="file-controls">
      <GlyphIconButton id="load" title="Load" icon="folder-open" onClick={ load }/>
      <GlyphIconButton id="save" title="Save" icon="floppy-disk" onClick={ save }/>
      <GlyphIconButton id="print" title="Print" icon="print" onClick={ print }/>
    </div>
  );
}

const FileControlsDropdown = () => {
  const context = useContext(RealisationContainerContext);
  const { load, save } = context;
  return (
    <div className="btn-group">
      <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" id="file" title="File">
        <i className="glyphicon glyphicon-file"></i>
      </button>
      <ul className="dropdown-menu dropdown-menu-sm" role="menu" id="file-controls-list">
        <li>
          <a className="dropdown-item" onClick={ load }>
            <i className="glyphicon glyphicon-cloud-download mr-2"></i><span>Load</span>
          </a>
        </li>
        <li>
          <a className="dropdown-item" onClick={ save }>
            <i className="glyphicon glyphicon-floppy-disk mr-2"></i><span>Save</span>
          </a>
        </li>
        <li>
          <a className="dropdown-item" onClick={ print }>
            <i className="glyphicon glyphicon-print mr-2"></i><span>Print</span>
          </a>
        </li>
      </ul>
    </div>
  );
}


export default function FileControls(props) {
  return (
    <Fragment>
      <NotMobile><FileControlsButtonGroup /></NotMobile>
      <Mobile><FileControlsDropdown /></Mobile>
    </Fragment>
  );
}
