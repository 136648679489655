import React, { useLayoutEffect, useContext } from "react";
import { Typography, MenuItem, } from '@material-ui/core';
import { Tables } from "../tables";
import { useSelector } from "react-redux";
import { MainMenuContext } from "./app";

const RECENT_NOTATIONS_LIST_LENGTH = 10;

const addToRecentNotationsList = (type, page, order) => {
  let recentNotations = localStorage.getItem("recentNotations");
  recentNotations = recentNotations ? JSON.parse(recentNotations) : [];
  let obj = { type: type, page: page, order: order };
  let updated = recentNotations.filter(item => {
    return item.type!=type || item.page!=page || item.order!=order;
  });
  updated.length===RECENT_NOTATIONS_LIST_LENGTH && updated.pop();
  updated.unshift(obj);
  localStorage.setItem("recentNotations", JSON.stringify(updated));
}

const isSamePageRepeatNotation = (type, page) => {
  return Tables.samePageRepeatNotations.find(obj => {
    return type===obj.type && page===obj.page;
  });
}
  
const formatRecentNotationsItem = (obj) => {
  let { type, page, order } = obj;
  order = isSamePageRepeatNotation(type, page) ? ( "(" + String.fromCharCode(96 + order) + ")" ) : "";
  return "Notation " + type + " Page " + page + " " + order;
}
  
const loadRecentNotation = (obj, update) => () => {
  let { type, page, order } = obj;
  update(type, page, order);
}

const RecentNotationsList = () => {

  let { type, page, order } = useSelector(state => ({
    type: state.notation.type,
    page: state.notation.page,
    order: state.notation.order
  }));

  const context = useContext(MainMenuContext);
  const { update } = context;

  useLayoutEffect(() => {
    addToRecentNotationsList(type, page, order);
  }, [type, page, order]);

  let recentNotations = localStorage.getItem("recentNotations");
  let items = recentNotations && JSON.parse(recentNotations)
    .filter(item => item.type!=type || item.page!=page || item.order!=order)
    .map((obj) => {
      let itemText = formatRecentNotationsItem(obj);
      let onClick = loadRecentNotation(obj, update);
      return (
        <MenuItem onClick={ onClick }>
          <Typography>{ itemText }</Typography>
        </MenuItem>);
    });

  return items;
}

export default RecentNotationsList;