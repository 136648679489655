import React, { useState } from "react";
import { AppBar, Toolbar, Box, Button, Menu, MenuItem, Typography, Link } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { NotMobile } from "../hooks/use-media-query";


const drawerWidth = 240;

const styles = ({ palette }) => ({
  abRoot: {
    left: `${drawerWidth}px`,
    width: `calc(100% - ${drawerWidth}px)`,
    display: "flex",
    justifyContent: "space-between",
    //alignItems: "center",
    color: "rgba(0,0,0,.87)",
    backgroundColor: "white",
    boxShadow: "none",
    marginBottom: "16px",
    background: "white",
    borderBottom: "1px solid #eaecef"
  },
  tbRoot: {
    minHeight: "60px"
  },
  pageTitle: {
    marginRight: "auto !important",
  },
  menuSubItem: {
    paddingLeft: '40px',
  },
});

const useStyles = makeStyles(styles);

const PageTOC = (props) => {
  const { toc } = props;

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [selectedItem, setSelectedItem] = useState("");

  const handleOnClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickMenuItem = (e) => {
    const id = e.currentTarget.getAttribute("id");
    setSelectedItem(id);
    handleClose();
  };

  const items = [];

  const linkStyle = { color: "inherit", textDecoration: "none" };

  const build = (itemList, itemClass) => {
    const className = itemClass || "main-item";
    itemList.forEach(entry => {
      if ( !Array.isArray(entry) ) {
        const { text, href } = entry;
        const id = `toc-${href.slice(1)}`;
        const item = <MenuItem
            id={id}
            selected={id===selectedItem}
            className={classes[className]}
            onClick={handleOnClickMenuItem}
          >
            <Typography>
              <Link href={ href } style={ linkStyle }>{ text }</Link>
            </Typography>
          </MenuItem>;
        items.push(item);
      }
      else {
        build(entry, "menuSubItem");
      }
    });
  }

  toc.forEach(entry => build(entry));

  return (
    <Box>
      <Button title="Contents" onClick={handleOnClick}>
        <i className="glyphicon glyphicon-list mr-2"/>
        <NotMobile><span>Contents</span></NotMobile>
      </Button>
      <Menu
        className="toc"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
        keepMounted
      >
        { items }
      </Menu>
    </Box>
  );
}

const PageTopBar = (props) => {
  const { title, toc } = props;
  const classes = useStyles();
  return (
    <AppBar position="fixed" classes={ { root: classes.abRoot } }>
      <Toolbar classes={ { root: classes.tbRoot } }>
        <Typography classes={ { root: classes.pageTitle } } variant="h5" component="div">{ title }</Typography>
        { toc && <PageTOC toc={ toc }/> }
      </Toolbar>
    </AppBar>
  );
}

export { PageTOC, PageTopBar };