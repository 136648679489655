import Component from "./base/component";

/**
 * Abstract class representing a generic score object.
 * @class CC.Core.ScoreObject
 * @extends CC.Core.Component
 */
export default class ScoreObject extends Component {

  constructor(options) {
    super(options);
    this.options = options;
    this.attachments = []; // Container for other objects associated with this object.
    this.__data__ = {}; // Any notation-specific data associated with this object (proportions in Notation B, for example).
    this.decorators = [];
  }

  getAttachments() {
    return this.attachments;
  }

  attachItem(item) {
    return this.attachments.push(item);
  }

  setRenderContext(ctx) {
    this.renderContext = ctx;
    return this;
  }

  getRenderContext() {
    return this.renderContext;
  }

  getData(key) {
    if (key) {
      return this.__data__[key];
    }
    else {
      return this.__data__
    };
  }

  setData(arg1, arg2) {
    // Handle key/value pair args...
    if (typeof arg1==="string" && arg2) {
      this.__data__[arg1] = arg2;
    }
    // Handle single object arg (arg2 ignored)...
    else if (typeof arg1==="object") {
      this.__data__ = { ...this.__data__, ...arg1 };
    }
    return this;
  }

  // Shallow copy. We create a new instance using the same options and then assign to it from the original object. This is
  // what Backbone does in its own Model.clone() method (see http://backbonejs.org/docs/backbone.html#section-92).
  clone() {
    var target = new this.constructor(this.options);
    return Object.assign(target, this);
  }

  decorate(decorator) {
    return this.decorators.push(decorator);
  }

  serialize() {}

  render() {
    return this;
  }

}
