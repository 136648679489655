import ScoreObject from './score-object';

export default class Glyph extends ScoreObject {

  constructor(options) {

    super(options);
    this._vfglyph = new Vex.Flow.TextNote(options);
    this.line = options.line || 0;

  }

  getRenderContext() {
    return this.renderContext;
  }

  setRenderContext(ctx) {
    this.renderContext = ctx;
    this._vfglyph.setContext(ctx);
    return this;
  }

  getAbsoluteX() {
    return this._vfglyph.getAbsoluteX();
  }

  setLine(line) {
    this.line = line;
    return this;
  }

  // Wrap Vexflow's static renderGlyph method so we can draw raw glyphs.
  static renderRawGlyph(ctx, x, y, code, scale) {
    Vex.Flow.renderGlyph(ctx, x, y, scale || 35, code);
  }

  render() {
    this._vfglyph.draw();
    return this;
  }

};
