/*
Notation Info Modal Sidebar.
*/

import React, { useState, useRef } from "react";
import { Drawer, Box, Tabs, Tab, makeStyles } from '@material-ui/core';
import TabPanel from "../widgets/tab-panel";
import { useMediaQuery } from "../hooks/use-media-query";
import ReactMarkdown from "react-markdown";
import { BootstrapDialogTitle } from "./base-modal";
import NotationInstructions from "../notation-instructions";
import { useSelector, useDispatch } from "react-redux";


// This is required by react-markdown.
// See: https://github.com/remarkjs/react-markdown/issues/339#issuecomment-653396337
window.process = { cwd: () => '' };

// This adds a default src to an img element if one can't be found. Useful while we're still
// adding the notation images. For more info see https://stackoverflow.com/a/38527860/795131
const addDefaultImgSrc = (ev) => {
  ev.target.src = "/img/notations/A1.png"
}

const drawerWidth = 466;
  
const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth
  },
  imgPanel: {
    display: "flex",
    justifyContent: "center",
    borderTop: "1px solid #e0e0e0",
    borderBottom: "1px solid #e0e0e0"
  },
  notationImg: {
    maxWidth: "100%",
  },
}));

const NotationInfoSidebar = (props) => {

  const classes = useStyles();

  const node = useRef();
  const dispatch = useDispatch();

  const handleClose = () => dispatch({type: "SHOW_NOTATION_INFO" });

  const { instructions, comments, directions, type, page, order } = useSelector(state => (state.notation));
  const { notationInstructionsLocation } = useSelector(state => (state.settings));
  const { showNotationInfo } = useSelector(state => (state.modals))

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setTabValue(newValue);
  };

  const a11yProps = (index) => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  });

  const { notations } = JSON.parse(localStorage.getItem(type));
  let pages = notations.filter(notation => notation.type===type).map(notation => notation.page);
  let index = pages.indexOf(page)+1;
  order===2 && (index+=1);

  const imgSrc = `/img/notations/${type}${index}.png`;
  
  const title = "Notation Information";

  const isMobile = useMediaQuery("(max-width: 767px)");

  return (
    <Drawer
      id="notation-info-sidebar"
      className={classes.drawer}
      classes={ { paper: classes.drawerPaper } }
      open={showNotationInfo}
      onClose={handleClose}
      anchor="right"
      
    >
      <Box id="notation-info-container">
        <BootstrapDialogTitle onClose={handleClose} >{ title }</BootstrapDialogTitle>
        { notationInstructionsLocation==="info panel" && <NotationInstructions instructions={ instructions }/> }
        <div id="notation-info-img-panel" className={classes.imgPanel}>
          <img src={imgSrc} className={classes.notationImg} onError={addDefaultImgSrc}/>
        </div>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs value={tabValue} onChange={handleChangeTab} aria-label="tabs">
              <Tab label="Comments" {...a11yProps(0)} />
              <Tab label="Directions" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <ReactMarkdown children={comments} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ReactMarkdown children={directions} />
          </TabPanel>
        </Box>
      </Box>
    </Drawer>
  );
}

export default NotationInfoSidebar;
