import { Utils } from "../utils";
import { Tables } from "../tables";
import { NoteTools } from "./tools/scoretools/note-tools";
import { mergeWith } from 'lodash';

const formatToDistribution = (noteStructs, totalDur, clefs=["treble","bass"]) => {
  let temp = { "0": [] };
  noteStructs.forEach(struct => {
    let { stave="0" } = struct;
    !temp.hasOwnProperty(stave) && (temp[stave] = []);
    temp[stave].push(struct);
  });
  let staves = [];
  let [ lastClef ] = Utils.reverse(clefs);
  for (let key in temp) {
    let clef = clefs[key] || lastClef;
    let structs = temp[key];
    let voiceStruct = NoteTools.onsetsToDurations(structs, totalDur, clef);
    staves.push({ clef: clef, voices: [ voiceStruct ] });
  }
  return { staves: staves };
};

const DEFAULT_PERFORMANCE_MODE_LIMITS = { treble: "a/3",  bass: "e/4" };

const formatToStaveLimits = (voiceStructs, totalDur, options) => {
  const { type } = { type: "standardLimits", ...options };
  const isGrandStaff = type==="grandStaff";
  const merged = { "treble": [], "bass": [] };
  const mergeHandler = (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  };
  // Get the raw data for each voice and merge it in...
  voiceStructs.forEach(voiceStruct => {
    let { clef, noteStructs } = voiceStruct;
    let data = sortToStaves(noteStructs, clef, isGrandStaff);
    mergeWith(merged, data, mergeHandler);
  });
  // Format to staves...
  const staveData = [];
  for (let clef in merged) {
    let arr = merged[clef];
    if (arr.length>0) {
      let opts = { clef: clef, mergeOnsets: true };
      staveData.push({
        clef: clef,
        voices: [ onsetsToDurations(arr, totalDur, opts) ]
      });
    }
  };
  return { staves: staveData };
}

const isDisplaced = (noteStrings) => {
  let displaced = false;
  let lastWasDisplaced = false;
  for (let i=1; i<noteStrings.length; i+=1) {
	  let line0 = NoteTools.getLineForNoteString(noteStrings[i-1]);
	  let line1 = NoteTools.getLineForNoteString(noteStrings[i]);
    displaced = Math.abs(line1-line0)===0.5 && !lastWasDisplaced;
	  lastWasDisplaced = displaced;
  }
  return displaced;
}

/*
const sortToStaves = (noteStructs, clef, isGrandStaff) => {
  const { standardLimits, grandStaffLimits } = Tables.staveLimits;
  const limits = isGrandStaff===true ? grandStaffLimits : standardLimits;
  const limit = limits[clef];
  var otherClef = clef==="treble" ? "bass" : "treble";
  var midiLimit = NoteTools.noteStringToMidi(limit);
  var result = {
    "treble": [],
    "bass": []
  };
  var included = result[clef];
  var excluded = result[otherClef];
  var test = {
    "treble": midi => midi>=midiLimit,
    "bass": midi => midi<=midiLimit
  };
  noteStructs.forEach(struct => {
    let { pitches } = struct;
    // We need to check if we have just a single note or a
    // chord/dyad, and handle these cases accordingly.
    if (!Array.isArray(pitches)) {
      // Single note.
      let midi = NoteTools.noteStringToMidi(pitches);
      test[clef](midi) ? included.push(struct) : excluded.push(struct);
    }
    else {
      // Chord.
      let includedPart = [];
      let excludedPart = [];
      pitches.forEach(pitch => {
        let midi = NoteTools.noteStringToMidi(pitch);
        test[clef](midi) ? includedPart.push(pitch) : excludedPart.push(pitch);
      });
      // The following logic relates to Grand Staff format only. If lines 0 and -0.5 are both
      // occupied (that is, we have a C and a B at those positions, with or without accidentals),
      // they will collide, IFF the B is not displaced (the C will not be displaced, since it's
      // the bottom note of the treble part at that point). So we potentially need to move the
      // C to the bass stave.
      if (isGrandStaff && includedPart.length > 0 && excludedPart.length > 0) {
        let bottomIncludedDiatonicPart = includedPart[0][0].toLowerCase();
        let topExcludedDiatonicPart = excludedPart[excludedPart.length-1][0].toLowerCase();
        if (!isDisplaced(excludedPart) && bottomIncludedDiatonicPart==="c" && topExcludedDiatonicPart==="b") {
          excludedPart.push( includedPart.shift() );
        }
      }
      includedPart.length > 0 && included.push({ ...struct, pitches: includedPart });
      excludedPart.length > 0 && excluded.push({ ...struct, pitches: excludedPart });
    }
  });
  return result;
}
*/

// This version handles grace notes.
const sortToStaves = (noteStructs, clef, isGrandStaff) => {
  const { standardLimits, grandStaffLimits } = Tables.staveLimits;
  const limits = isGrandStaff===true ? grandStaffLimits : standardLimits;
  const limit = limits[clef];
  var otherClef = clef==="treble" ? "bass" : "treble";
  var midiLimit = NoteTools.noteStringToMidi(limit);
  var result = {
    "treble": [],
    "bass": []
  };
  var included = result[clef];
  var excluded = result[otherClef];
  var test = {
    "treble": midi => midi>=midiLimit,
    "bass": midi => midi<=midiLimit
  };
  noteStructs.forEach(struct => {
    let { pitches, graceNotes } = struct;
    // We need to check if we have just a single note or a
    // chord/dyad, and handle these cases accordingly.
    if (!Array.isArray(pitches)) {
      // Single note.
      let midi = NoteTools.noteStringToMidi(pitches);
      test[clef](midi) ? included.push(struct) : excluded.push(struct);
    }
    else {
      // Chord.
      let includedPart = [];
      let excludedPart = [];
      pitches.forEach(pitch => {
        let midi = NoteTools.noteStringToMidi(pitch);
        test[clef](midi) ? includedPart.push(pitch) : excludedPart.push(pitch);
      });
      // The following logic relates to Grand Staff format only. If lines 0 and -0.5 are both
      // occupied (that is, we have a C and a B at those positions, with or without accidentals),
      // they will collide, IFF the B is not displaced (the C will not be displaced, since it's
      // the bottom note of the treble part at that point). So we potentially need to move the
      // C to the bass stave.
      if (isGrandStaff && includedPart.length > 0 && excludedPart.length > 0) {
        let bottomIncludedDiatonicPart = includedPart[0][0].toLowerCase();
        let topExcludedDiatonicPart = excludedPart[excludedPart.length-1][0].toLowerCase();
        if (!isDisplaced(excludedPart) && bottomIncludedDiatonicPart==="c" && topExcludedDiatonicPart==="b") {
          excludedPart.push( includedPart.shift() );
        }
      }
      struct = Utils.omit(struct, "graceNotes");
      includedPart.length > 0 && included.push({ ...struct, pitches: includedPart });
      excludedPart.length > 0 && excluded.push({ ...struct, pitches: excludedPart });
      if (graceNotes) {
        let target = includedPart.length===0 ? excluded : excludedPart.length===0 && included;
        if (!target) {
          const targetIndex = getMaxAccidentals([includedPart, excludedPart]);
          target = [included, excluded][targetIndex];
        }
        target[target.length-1].graceNotes = graceNotes;
      }
    }
  });
  return result;
}

// Helper for sortToStaves2. Returns the index of the note string array
// which has the largest number of accidentals. Probably needs to be renamed,
// and also possibly needs to handle double sharps and double flats.
const getMaxAccidentals = (arrays) => {
  let target = arrays[0];
  let maxAccidentals = -1;
  arrays.forEach(array => {
    let numAccidentals = 0;
    array.forEach(noteString => {
      let [ pitch ] = noteString.split("/");
      (pitch[1]==="#" || pitch[1]==="b") && (numAccidentals += 1);
    });
    (numAccidentals > maxAccidentals) && (maxAccidentals += numAccidentals) && (target = array);
  });
  return arrays.indexOf(target);
}

///////////////////////////////////////////////////////////////////////////////////

function getIncludedExcludedKeys(voice, limits=DEFAULT_PERFORMANCE_MODE_LIMITS) {
  var clef = voice.getStave().getClef();
  var limit = limits[clef];
  var otherClef = clef==="treble" ? "bass" : "treble";
  var midiLimit = NoteTools.noteStringToMidi(limit);
  var result = {
    "treble": [],
    "bass": []
  };
  var included = result[clef];
  var excluded = result[otherClef];
  var test = {
    "treble": midi => midi>=midiLimit,
    "bass": midi => midi<=midiLimit
  };
  voice.getNotes().forEach(note => {
    let struct = note.serialize();
    struct.onset = note.getOnset();
    // We need to check if we have just a single note or a
    // chord/dyad, and handle these cases accordingly.
    if (!note.isChord()) {
      let midi = note.toMidi();
      test[clef](midi) ? included.push(struct) : excluded.push(struct);
    }
    else {
      // We handle chords/dyads by splitting them up into single notes, each with the same
      // onset. This is fine, since we'll be merging all notes with the same onset anyway.
      struct.pitches.forEach(pitch => {
        let _struct = { ...struct, pitches: pitch };
        let midi = NoteTools.noteStringToMidi(pitch);
        test[clef](midi) ? included.push(_struct) : excluded.push(_struct);
      });
    }
  });
  return result;
}

// Destructively modifies the input array so that consecutive notes with the same
// onset are merged into a chord/dyad. N.B: The input data needs to have been sorted
// by onset time so that we indeed get all same-onset notes grouped together.
function mergeForSameOnsets(arr) {
  for (var i = arr.length-1; i >= 1; --i) {
    if (arr[i].onset === arr[i-1].onset) {
      var pitches = arr[i].pitches;
      arr.splice(i, 1); // Remove pitches with equal onsets.
      //arr[i-1].pitches = [arr[i-1].pitches].concat(pitches); // Merge pitch to next.
      arr[i-1].pitches = [].concat(arr[i-1].pitches, pitches); // Merge pitch to next.
      arr[i-1].pitches = Utils.removeDuplicates(arr[i-1].pitches); // Remove duplicates.
    }
  }
}

function onsetsToDurations(data, totalDur, options) {
  var opts = { ...{ clef: "treble", mergeOnsets: false }, ...options };
  let { clef, mergeOnsets } = opts;
  data.sort((a, b) => a.onset-b.onset);
  mergeOnsets && mergeForSameOnsets(data);
  var dynamics = ["ppp", "pp", "p", "mp", "mf", "f", "ff", "fff"];
  var noteStructs = [];
  var len = data.length;
  for (var i=1; i<=len; i++) {
    var dur = ( i<len ? data[i].onset : totalDur )-data[i-1].onset;
    var struct = Utils.omit(data[i-1], "onset");
    struct.duration = (dur===0 && !mergeOnsets) ? "1" : dur.toString();
    (!struct.dynamic) && (struct.dynamic = Utils.getRandElem(dynamics));
    noteStructs.push(struct);
  }
  let offset = data[0].onset.toString();
  return {
    clef: clef,
    noteStructs: noteStructs,
    offset: offset
  };
}

class PerformanceModeFormatter {

  constructor(options) {
    this.score = options.score;
  }

  getPerformanceModeData(voices, limits, totalDur) {
    const merged = { "treble": [], "bass": [] };
    const mergeHandler = (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    };
    // Get the raw data for each voice...
    voices.forEach(voice => {
      let data = getIncludedExcludedKeys(voice, limits);
      mergeWith(merged, data, mergeHandler);
    });
    // Format to staves...
    const staveData = [];
    for (let clef in merged) {
      let arr = merged[clef];
      if (arr.length>0) {
        let opts = { clef: clef, mergeOnsets: true };
        staveData.push({
          clef: clef,
          voices: [ onsetsToDurations(arr, totalDur, opts) ]
        });
      }
    };
    return staveData;
  }

  format(options) {
    let { isDividedByHand, limits } = options = Utils.merge({
      isDividedByHand: false,
      limits: DEFAULT_PERFORMANCE_MODE_LIMITS
    }, options || {});
    let source = isDividedByHand ? this.score.getStaves() : this.score;
    source = [].concat(source || []);
    let data = {
      staves: []
    };
    source.forEach(obj => {
      var voices = [];
      var noiseStaveData;
      obj.getVoices().forEach(voice => {
        let staveType = voice.getStave().type;
        // If we have a noise stave, serialize and store the data...
        if (staveType==="noise") {
          noiseStaveData = { type: "noise", voices: [ voice.serialize() ] };
        }
        else {
          voices.push(voice);
        }
      });
      // Handle the case where we have just a single noise stave (as in notation P)...
      if (noiseStaveData && voices.length===0) {
        data.staves = [ noiseStaveData ];
      }
      // Handle the more usual case, in which we have one or more treble or bass
      // staves (plus any noise staves, as in notations S, V, BK, etc.)...
      else if (voices.length>0) {
        const totalDur = this.score.getDuration();
        var pData = this.getPerformanceModeData(voices, limits, totalDur);
        data.staves = data.staves.concat(pData);
        // Splice in any noise stave data...
        if (noiseStaveData) {
          data.staves = [ data.staves[0], noiseStaveData, ...data.staves.slice(1) ]
        }
      }
    });
    return data;
  }

}

export default class Formatter {

  constructor(options) {
    this.score = options.score;
    this.performanceModeFormatter = new PerformanceModeFormatter({
      score: this.score
    });
  }

  toGrandStaff() {
    return this.toPerformanceMode({
      limits: {
        treble: "c/4",
        bass: "c/4"
      }
    });
  }

  toPerformanceMode(options) {
    return this.performanceModeFormatter.format(options);
  }

  static formatToDistribution(noteStructs, totalDur, clefs=["treble","bass"]) {
    return formatToDistribution(noteStructs, totalDur, clefs);
  }

  static formatToStaveLimits(voiceStructs, totalDur) {
    return formatToStaveLimits(voiceStructs, totalDur);
  }

  static formatToGrandStaff(voiceStructs, totalDur) {
    return formatToStaveLimits(voiceStructs, totalDur, {type: "grandStaff"});
  }

  /*
  format() {
    var result;
    switch (this.format) {
      case "grandStaff":
        result = this.toGrandStaff();
        break;
      case "performance":
      case "practical":
        result = this.toPerformanceMode();
        break;
      case "distribution":
        result = this.toDistribution();
        break;
    }
    return result;
  }
  */

}
