import React, { useState, useRef, useCallback } from "react";
import { Menu, MenuItem } from '@material-ui/core';
import { useEventListener } from "../hooks/use-event-listener";
import { Utils } from "../../utils";


const ContextMenu = ({ id, ctx, items }) => {
  const [contextMenu, setContextMenu] = useState(null);

  const menuItems = useRef();

  const before = (e, elem) => {
    if (items) {
      Utils.isFunction(items) && (items = items(e));
      menuItems.current = items.map((item) => ({
        ...item,
        onClick: (e) => {
          item.onClick(e, elem);
          handleClose();
        }
      }));
    }
  }

  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      const elem = e.target.closest(ctx);
      if (elem) {
        before(e, elem);
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: e.clientX - 2,
                mouseY: e.clientY - 4,
              }
            : null,
        )
      }
    },
    [contextMenu, setContextMenu]
  );

  const handleClose = useCallback((e) => (contextMenu ? setContextMenu(null) : null), [contextMenu]);

  useEventListener("click", handleClose, document);
  useEventListener("contextmenu", handleContextMenu, document);

  return (
    <Menu
      id={id}
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      {
        menuItems.current &&
        menuItems.current.map((item, itemIndex) => {
          const { name, onClick } = item;
          return (
            <MenuItem key={ itemIndex } onClick={ onClick }>{ name }</MenuItem>
          )
        })
      }
    </Menu>
  );
};

export default ContextMenu;
