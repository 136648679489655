import Canvas from './canvas';

export default class FrameScore extends Canvas {

  constructor(options) {
    super(options);
    this.data = options.data;
    this.noteHeads = [];
    this.frame;
    this.frameWidth = 2.5;
  }

  getData() {
    return this.data;
  }

  getFrame() {
    return this.frame;
  }

  createNoteHeads() {
    var data = this.getData()[0];
    var width = this.getMetrics().width;
    var height = this.getMetrics().height;
    var startX = this.getMargins().marginLeft;
    var onset = startX+(width*data.offset);
    var noteHeads = data.xVals.map(function(val, i) {
      var duration = width*val;
      var noteHead = new Vex.Flow.NoteHead({
        duration: duration.toString(),
      });
      onset += duration;
      return noteHead;
    });
    return noteHeads;
  }

  drawNoteHeads() {
    var ctx = this.getRenderContext();
    var data = this.getData()[0];
    var width = this.getMetrics().width;
    var height = this.getMetrics().height;
    var startX = this.getMargins().marginLeft;
    var onset = startX+(width*data.offset);
    var startY = this.getMargins().marginBottom;
    this.noteHeads.forEach(function(noteHead, i) {
      var y = startY+(height*(1-data.yVals[i]));
      noteHead.setX(onset);
      noteHead.setY(y);
      noteHead.setContext(ctx).draw();
      onset += parseInt(noteHead.duration);
    });
  }

  preRender() {}

  postRender() {}

  render() {
    this.preRender();
    var ctx = this.getRenderContext();
    var margins = this.getMargins();
    var width = this.getMetrics().width;
    var height = this.getMetrics().height;
    var zoomWidth = Math.floor(width*this.getZoomLevel()/100);
    this.frame = ctx.rect(margins.marginLeft, margins.marginTop, zoomWidth, height, {
      fill: "none",
      stroke: "black",
      "stroke-width": this.frameWidth
    }).parent.lastChild;
    var newWidth = zoomWidth+margins.marginLeft+margins.marginRight;
    var newHeight = height+margins.marginTop+margins.marginBottom;
    this.resize(newWidth, newHeight);
    this.noteHeads = this.createNoteHeads();
    this.drawNoteHeads();
    this.postRender();
    return this;
  }

};
