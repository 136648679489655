import React from "react";
import BaseModal from "./base-modal";
import { useSelector, useDispatch } from "react-redux";


const AboutDialog = (props) => {
  const dispatch = useDispatch();
  const { showAboutDialog } = useSelector(state => state.modals);

  const year = new Date().getFullYear();

  return (
    <BaseModal id="about-dialog" title="About" open={showAboutDialog} handleClose={() => dispatch({type: "SHOW_ABOUT_DIALOG" })}>
      <div id="about-dialog-content">
        <p id="about-dialog-attribution" style={ { fontSize: "0.9em" } }>Copyright ⓒ {year}, <a href="https://www.hud.ac.uk/">University of Huddersfield</a> | <a href="https://www.leeds.ac.uk/">University of Leeds</a></p>
        <h5 style={ { fontWeight: "500" } }>ACKNOWLEDGEMENTS</h5>
        <p style={ { marginTop: "0.75rem", marginBottom: "0.6rem" } }>Lead Developer: <a href="http://christophermelen.com">Dr Christopher Melen</a></p>
        <p>With invaluable contributions from <a href="http://brahms.ircam.fr/pablo-galaz-salamanca">Pablo Galaz Salamanca</a>, and members of the <a href="https://cageconcert.org/project/people/">Cage Concert Project team</a></p>
      </div>
    </BaseModal>
  );
}

export default AboutDialog;