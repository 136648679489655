/*
Settings Dialog.
*/

import React, { Fragment, useReducer, useMemo } from "react";
import { Button } from '@material-ui/core';
import BaseModal from "../base-modal";
import DefaultNotationSettings from "./default-notation";
import NotationInstructionsSettings from "./notation-instructions";
import FontSettings from "./font";
import TimeSettings from "./time";
import RealisationDisplaySettings from "./realisation-display";
import PlaybackSettings from "./playback";
import TabPanel from "../../widgets/tab-panel";
import { useSelector, useDispatch } from "react-redux";
import { DEFAULT_SETTINGS } from "../../../reducers/settings-reducer";


const SettingsContext = React.createContext();
export { SettingsContext };

/*
const GeneralSettings = () => {
  return (
    <TabPanel isDefault={true} id="general-settings">
      <DefaultNotationSettings />
      <NotationInstructionsSettings />
      <FontSettings />
    </TabPanel>
  );
}

const RealisationSettings = () => {
  return (
    <TabPanel id="realisation-settings">
      <TimeSettings />
      <RealisationDisplaySettings />
      <PlaybackSettings />
    </TabPanel>
  );
}
*/

const MinimalSettings = () => {
  return (
    <div id="minimal-settings">
      <DefaultNotationSettings />
      <NotationInstructionsSettings />
      <TimeSettings />
    </div>
  );
}

const settingsReducer = (state, action) => {
  switch (action.type) {
    case "SET_DEFAULT_NOTATION":
    case "SET_DEFAULT_NOTATION_SELECTION_TYPE":
    case "SET_NOTATION_INSTRUCTIONS_LOCATION":
    case "SET_FONT_FOR_DYNAMICS":
    case "SET_TIME_FORMAT":
    case "SET_DEFAULT_REALISATION_DURATION":
    case "SET_DEFAULT_TIMEGRID_SEGMENT_SIZE":
    case "SET_TIME_GRID_STYLE":
      return { ...state, hasChanges: true, ...action.payload };
    case "FOLLOW_SCORE_ON_PLAYBACK":
      return { ...state, hasChanges: true, followScoreOnPlayback: !state.followScoreOnPlayback };
    case "RESET":
      return DEFAULT_SETTINGS;
  }
  return state;
};

const SettingsModal = () => {

  const { settings } = useSelector(state => state);
  const { showSettingsDialog } = useSelector(state => state.modals);
  const dispatch = useDispatch();

  const [dialogSettings, dialogDispatch] = useReducer(settingsReducer, settings);

  const save = () => {
    let newSettings = { ...JSON.parse(localStorage.getItem("settings")), ...dialogSettings };
    localStorage.setItem("settings", JSON.stringify(newSettings));
    dispatch({type: "SAVE_SETTINGS", payload: newSettings});
  };

  const reset = () => {
    localStorage.removeItem("settings");
    dispatch({type: "RESTORE_DEFAULT_SETTINGS"});
    dialogDispatch({type: "RESET"});
  };

  const contextValue = useMemo(() => {
    return { ...dialogSettings, dispatch: dialogDispatch };
  }, [dialogSettings, dialogDispatch]);

  const handleClose = () => dispatch({type: "SHOW_SETTINGS_DIALOG" });

  let buttonHandlers = { save, reset };

  const Buttons = (props) => {
    let { reset, save } = props.onClickHandlers;
    let disabled = !props.hasChanges; //!localStorage.getItem("settings");
    return (
      <Fragment>
        <Button color="secondary" disabled={disabled} onClick={reset}>Restore Defaults</Button>
        <Button color="secondary" onClick={handleClose}>Close</Button>
        <Button color="primary" disabled={disabled} onClick={save}>Save</Button>
      </Fragment>
    );
  }

  /*
  return (
    <BaseModal
      id="settings-dialog"
      title="Settings"
      open={showSettingsDialog}
      handleClose={handleClose}
      buttons={<Buttons hasChanges={settings.hasChanges} onClickHandlers={ buttonHandlers }/>}
      fullWidth={true}
      maxWidth="sm"
    >
      <div id="settings-modal-container">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a className="nav-link active" data-toggle="tab" href="#general-settings">General</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-toggle="tab" href="#realisation-settings">Realisation</a>
          </li>
        </ul>
        <div className="tab-content">
        <SettingsContext.Provider value={ contextValue }>
          <GeneralSettings />
          <RealisationSettings />
        </SettingsContext.Provider>
        </div>
      </div>
    </BaseModal>
  );
  */

  return (
    <BaseModal
      id="settings-dialog"
      title="Settings"
      open={showSettingsDialog}
      handleClose={handleClose}
      buttons={<Buttons hasChanges={dialogSettings.hasChanges} onClickHandlers={ buttonHandlers }/>}
      fullWidth={true}
      maxWidth="sm"
    >
      <div id="settings-modal-container">
        <SettingsContext.Provider value={ contextValue }>
          <MinimalSettings />
        </SettingsContext.Provider>
      </div>
    </BaseModal>
  );
}

export default SettingsModal;