import React from "react";
import { PageTopBar } from "./layout";

const UserInterface = () => {
  let toc = [
    [
      {text: "Main Menu Bar", href: "#main-menu-bar"}, [
        {text: "The Notation Selector", href: "#notation-selector"},
        {text: "The Options Menu", href: "#options-menu"},
        {text: "Other Menu Bar Controls", href: "#other-menu-bar-controls"}
      ]
    ],
    [
      {text: "Main UI Panel", href: "#main-ui-panel"}, [
        {text: "Notation Instructions", href: "#notation-instructions"},
        {text: "Transcription Panel", href: "#transcription-panel"},
        {text: "Realisation Panel", href: "#realisation-panel"}
      ]
    ],
    [{text: "Notation Information Sidebar", href: "#notation-information-sidebar"}, []],
  ];
  return (
    <section id="user-interface">
      <PageTopBar title="2. The User Interface" toc={ toc }/>
      <p>The Solo for Piano app is a single page application, meaning that it is structured as a single interface, in the same page, with no navigation away. The interface consists of the following main components:</p>
      <section id="main-menu-bar">
        <h4>2.1. Main Menu Bar</h4>
        <p>Like many web applications the Solo app has a menu bar located at the top of the page. This contains a number of controls which are important for general navigation within the app:</p>
        <div className="img-panel">
          <img src="/img/user-guide/main-menubar.png" />
        </div>
        <h5 id="notation-selector">2.1.1. The Notation Selector</h5>
        <p>The Notation Selector is used to load new notations. It consists of a group of dropdown selector controls, allowing to select the notation type and page of occurrence, respectively.</p>
        <div className="img-panel">
          <img src="/img/user-guide/notation-selector.png" />
        </div>
        <p>The Type selector consists of a list of all 84 notation types, organised into four panels - A-Z, AA-AZ, BA-BZ and CA-CF. Navigation between these 'alphabets' is achieved through clicking the right or left arrows at the top of the control. Clicking on an individual letter (or double-letter) will then load that given notation, at the page of its first occurrence in the score. In the very small number of cases where multiple instances of the same notation occur on the same page (for example, Notation C on page 1), an additional dropdown selector control (the 'Order in Page' selector) will appear to the right of the Page selector, allowing to select a specific instance of the notation from the given page (ordered left to right).</p>
        <h5 id="options-menu">2.1.2. The Options Menu</h5>
        <p>At the very right of the main menu bar lies the Options Menu.</p>
        <div className="img-panel">
          <img src="/img/user-guide/options-menu.png" />
        </div>
        <p>When opened the Options Menu reveals the following items:</p>
        <ul>
          <li><span className="bolder">Home</span> - returns to cageconcert.org</li>
          <li><span className="bolder">User Guide</span> - links to this document</li>
          <li><span className="bolder">About</span> - general app info/credits</li>
          <li><span className="bolder">Recent Notations</span></li>
        </ul>
        <p>The last item in the menu, 'Recent Notations', when hovered over reveals a submenu listing notations recently loaded into the app. Selecting an item in this submenu will load the given notation. The list is limited to the last 10 notations visited, in order. <em>Please note that this list is stored locally in the browser, and although it will remain available after a page reload, clearing the browser cache will empty it.</em></p>
        <h5 id="other-menu-bar-controls">2.1.3. Other Menu Bar Controls</h5>
        <p>To the left of the Options menu are a further set of controls relating to additional general features of the app:</p>
        <ul>
          <li><h6>Notation Information</h6>
            <p>Clicking this button will open a popout sidebar, on the right of the main UI, providing detailed information on the current notation (commentary and directions). For more information on this see the section below, <a href="#notation-information-sidebar">Notation Information Sidebar</a>.</p>
          </li>
          <li><h6>Settings</h6>
            <p>Clicking this button will bring up the Settings dialog, which can be used to manage app configuration. For more information on this feature see the <a href="/guide/user-config/">Configuration</a> section of this guide.</p>
          </li>
        </ul>
      </section>
      <section id="main-ui-panel">
        <h4>2.2. Main UI Panel</h4>
        <p>Below the app menu bar lies the main UI panel. This space is used to display the currently-loaded notation, and where most time will be spent when working in the application. The most important components in this region are as follows:</p>
        <h5 id="notation-instructions">2.2.1. Notation Instructions</h5>
        <p>At the top of the main UI panel can be found the Notation Instructions, which displays the text of the instructions provided by Cage for each notation. It is presented in quoted form, since - due to the often ambiguous and occasionally baffling nature of the instructions - they cannot always be relied on as a literal set of instructions for producing a realisation. The instructions are always quoted literally, remaining faithful to Cage's idiosyncrasies of spelling and grammar.</p>
        <h5 id="transcription-panel">2.2.2. Transcription Panel</h5>
        <p>The Transcription Panel is the region into which a transcription of the notation is rendered. The term 'transcription' is here used somewhat loosely, since despite faithfully and accurately reproducing most features of a notation, a transcription is not intended as a literal copy, still less as a replacement for Cage’s original. Generally what is transcribed is what is salient to the generation of a realisation. For example, certain notations in the original score contain graphical elements relating to compositional means, which have no relevance for the process of realisation. In most cases these will therefore be omitted in the transcription. Note that this is not always the case, however - such purely graphical features will often be important for visual recognisability of the notation, and in those circumstances will be retained.</p>
        <p>The width and height of each transcription generally correspond as closely as possible to those of the original notation. It is rarely possible to reproduce the exact dimensions, however, due to unavoidable differences between the two in the size of elements like noteheads, clefs, dynamic indications, etc. Were the exact dimensions always to be followed the result would therefore often be illegible, as a result of collisions between such elements. A compromise approach has therefore been taken, with dimensions chosen so as to correspond as closely as possible to the original score, whilst at the same time maximising legibility, resulting in a comfortably readable and uncluttered score.</p>
        <p>Where a notation has special configuration parameters for realisation, or different realisation 'modes' - for example Notation K, which may be realised using either an odd or even number of selected notes - a toolbar is made available above the transcription with controls for setting these parameters.</p>
        <h5 id="realisation-panel">2.2.3. Realisation Panel</h5>
        <p>The Realisation Panel, situated directly below the transcription, is where realisations for the current notation are displayed. On loading a new notation the app will generate an automatic realisation and render it into this region. At the top of the panel is a toolbar which contains controls relevant to the generation and display of the realisation - updating, zooming, etc. For detailed information on all of these controls and their various functions see the section below, <a href="/guide/walkthrough/#realisation-toolbar">Using the Realisation Toolbar</a>.</p>
      </section>
      <section id="notation-information-sidebar">
        <h4>2.3. Notation Information Sidebar</h4>
        <p>The Notation Information Sidebar opens at the right of the screen on clicking its icon on the right-hand side of the main menu bar.</p>
        <div className="img-panel">
          <img src="/img/user-guide/notation-information.png" style={ { maxWidth: "70%" } }/>
        </div>
        <p>Once opened the sidebar can be dismissed either with its close button, or by clicking on any part of the screen it does not cover. This sidebar is used to display information pertaining to the current notation. At the top can be found a full or partial reproduction of the original notation as it appears in the score (Cage's instructions may also be visible above this image instead of the main panel, as their location may be changed using the <a href="/guide/user-config/#notation-instructions-setting">settings dialog</a>). Below the notation image is a tabbed interface conatining the main text content of the sidebar, in two sections:</p>
        <ul>
          <li><h6>Comments</h6>
            <p>This section is used to provide, where appropriate, general information on the notation, including commentary on any unusual or problematic aspects. In particular it will provide explanation, where necessary, for any interpretative or editorial decisions which have been made in the presentation of the notation and its realisation: for example, where a certain graphical element present in the original might not have been rendered, or been only partially rendered, in the transcription; or where Cage's instructions have seemed insufficient for a straightforward decision on how to realise the notation, or are in other ways problematic.</p>
          </li>
          <li><h6>Directions</h6>
            <p>This section is used for information relating to the process of generating a realisation in the current notation. For a number of notations the information provided here will be unique, but there will often be overlap with other notations. In cases of notations with very straightforward realisations only the most basic of information will typically be provided here.</p>
          </li>
        </ul>
      </section>
    </section>
  );
}

export default UserInterface;