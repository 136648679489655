import Container from './container';

/**
 * Base class for the spanner component.
 * @class CC.Core.Spanner
 * @extends CC.Core.Container
 */
export default class Spanner extends Container {

  constructor(options) {
    super(options);
  }

  setRenderContext(ctx) {
    this.renderContext = ctx;
    return this;
  }

  getRenderContext() {
    return this.renderContext;
  }

  /**
   * @param {Object} object - Sets the target for the spanner, the object to which it will be attached.
   */
  setTarget(object) {
    this.target = object;
    return this;
  }

  /**
   * Returns the target object for the spanner.
   * @returns {Object} The target object.
   */
  getTarget() {
    return this.target;
  }

  /**
   * Attaches the spanner to its target object. By default merely returns 'this', and is meant to be overridden by subclasses.
   */
  attach() {
    return this;
  }

  render() {
    return this;
  }

};
