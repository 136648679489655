import { useState, useEffect } from 'react';

/*
 * This module is derived from https://hooks.fransvilhelm.com/hook/use-media-query.
 *
 * Test to see if a query is matched and listen for changes on that query. This
 * value will be updated if the window is resized.
 *
 *   const Device = () => {
 *     const isMobile = useMediaQuery('(max-width: 375px)');
 *     const isTablet = useMediaQuery('(min-width: 378px) and (max-width: 768px)');
 *
 *     if (isMobile) return <p>You're on a mobile right now</p>;
 *     if (isTablet) return <p>You're on a tablet right now</p>;
 *     return <p>You're on a desktop computer right now</p>;
 *   };
 */

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches,
  );

  useEffect(() => {
    const queryList = window.matchMedia(query);
    setMatches(queryList.matches);

    const listener = (evt) =>
      setMatches(evt.matches);

    queryList.addListener(listener);
    return () => queryList.removeListener(listener);
  }, [query]);

  return matches;
};

/*
 * Some discussion of media queries and how they relate to different devices
 * and screen sizes: https://stackoverflow.com/q/6370690/795131
 * Also https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
 * and https://responsivedesign.is/articles/why-you-dont-need-device-specific-breakpoints/
 * and https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/
 */

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery('(min-width: 992px)');
  return isDesktop ? children : null;
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 991px)');
  return isTablet ? children : null;
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  return isMobile ? children : null;
}
const NotDesktop = ({ children }) => {
  const isNotDesktop = useMediaQuery('(max-width: 767px), (max-width: 991px)');
  return isNotDesktop ? children : null;
}
const NotMobile = ({ children }) => {
  const isNotMobile = useMediaQuery('(min-width: 768px), (min-width: 992px)');
  return isNotMobile ? children : null;
}

export { useMediaQuery, Desktop, Tablet, Mobile, NotDesktop, NotMobile };
