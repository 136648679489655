import React from "react";
import TickList from "../widgets/tick-list";
import { useDispatch } from "react-redux";

export default function DisplayModeControls() {
  let dispatch = useDispatch();
  const changeDisplayMode = (e, mode) => {
    document.querySelector("#transcription").classList.toggle("realisable");
    document.querySelector("#realisation").classList.toggle("performance-mode");
    dispatch({ type: "CHANGE_DISPLAY_MODE", payload: mode });
  };
  let items = [
    { selected: true, onClick: changeDisplayMode, text: "Analysis" },
    { onClick: changeDisplayMode, text: "Performance" }
  ];
  return (
    <TickList icon="blackboard" id="display-mode" title="Display Mode" items={ items }/>
  );  
}
