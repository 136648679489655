const setRealisationData = (data) => {
  return {
    type: "SET_REALISATION_DATA",
    payload: data
  };
};

const setAudioOff = (data) => {
  return {
    type: "IS_PLAYING",
    payload: false
  };
};

const setCurrentSelection = (payload) => {
  return {
    type: "SET_CURRENT_SELECTION",
    payload: payload
  };
}

const resetRealisation = () => {
  return {
    type: "RESET"
  };
};
  
export { setRealisationData, setAudioOff, setCurrentSelection, resetRealisation };