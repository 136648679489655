import ScoreObject from "./score-object";
import { Graphics } from "./graphics";
import Pitch from './pitch';

export default class GraceNote extends ScoreObject {

  constructor(options) {
    super(options);

    var pitches = Array.isArray(options.pitches) ? options.pitches : [options.pitches];

    this.pitches = pitches.map(pitch => {
      if (pitch instanceof String || typeof pitch === "string") {
        return Pitch.fromString(pitch);
      }
      else return pitch;
    });

    this.note = options.note;
    this.clef = options.clef || this.note.getClef();
    this.stave = options.stave;
    this.dynamic = options.dynamic;
    this.showDynamic = false;
    this.hasStem = options.hasStem;
    this.stemOptions = {
      length: 24,
      direction: "up",
      hasSlash: true,
      ...options.stemOptions
    };
  }

  getElem() {
    return this.elem;
  }

  getNoteheadElemIndex(notehead) {
    return this.note.getNoteheadElemIndex(notehead);
  }

  getKeyAtIndex(index, includeOctave) {
    return this.note.getKeyAtIndex.call(this, index, includeOctave);
  }

  getLedgerLines() {
    return this.note.getLedgerLines.call(this);
  }

  hideLedgerLines() {
    this.note.hideLedgerLines.call(this);
  }

  getPitches() {
    return this.pitches;
  }

  getAbsoluteX() {
    return this.note.getAbsoluteX.call(this);
  }

  serialize() {
    const pitches = this.pitches.map(pitch => pitch.toString());
    return {
      pitches: pitches
    }
  }

  drawStem() {
    const ctx = this._vfnote.context;
    let { length: stemHeight, direction, hasSlash } = this.stemOptions;
    Graphics.drawNoteStem(ctx, this, direction, stemHeight);
    Graphics.drawNoteFlag(ctx, this, direction, stemHeight);
    hasSlash && Graphics.drawSlash(ctx, this, direction, stemHeight);
  }

  render() {
    var pitches = this.pitches.map(pitch => pitch.getClass()+'/'+pitch.getOctave());

    this._vfnote = new Vex.Flow.GraceNote({
      keys: pitches,
      duration: "8",
      clef: this.clef
    });

    this._vfnote.setStave(this.stave._vfstave);

    var keyProps = this._vfnote.keyProps;
    for (var i = 0; i < keyProps.length; i++) {
      var acc = keyProps[i].accidental;
      if (acc) {
        this._vfnote.addAccidental(i, new Vex.Flow.Accidental(acc))
      }
    }

    //var ctx = this.getRenderContext();
    //this._vfnote.setContext(ctx).draw();

    return this;
  }

}
