import Score from './score';

export default class GrandStaffScore extends Score {

  constructor(options) {
    super(options);
    this.metrics.spaceAboveStaffLine = 1;
    this.metrics.spaceBelowStaffLine = 1;
  }

};
