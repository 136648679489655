import Spanner from '../base/spanner';

export default class DynamicsSpanner extends Spanner {

  constructor(options) {
    super(options);
    this.startX = options.startX || 0;
    this.endX = options.endX || 0;
    this.startXShift = options.startXShift || 0;
    this.endXShift = options.endXShift || 0;
    this.y = options.y || 50;
    this.gap = options.gap || 50;
  }

  xValToDynamic(x) {
    var dynamics = this.getItems();
    var dynamic;
    var fromStart = this.startX-this.startXShift-this.gap;
    var end = fromStart+(this.gap*dynamics.length);
    var i = 0;
    if (fromStart<=x && x<=end) {
      while (fromStart<x) {
        fromStart += this.gap;
        dynamic = dynamics[i];
        i++;
      }
    }
    return dynamic;
  }

  getGap() {
    var gap = this.gap;
    if (this.endX && this.endX>this.startX) {
      var extent = (this.endX+this.endXShift)-(this.startX-this.startXShift);
      var numItems = this.getItems().length;
      gap = Math.floor(extent/numItems);
    }
    return gap;
  }

  render() {
    var ctx = this.getRenderContext();
    ctx.save();
    ctx.setFont('gonville', 17, 'italic');
    var x = this.startX-this.gap-this.startXShift;
    var y = this.y;
    var gap = this.getGap();
    this.getItems().forEach(function(dynamic) {
      x += gap;
      dynamic.render(ctx, x, y);
    });
    ctx.restore();
    return this;
  }

}
