/*
Realisation Container Component.
*/

import React from "react";
import RealisationToolbar from "./realisation-toolbar/toolbar";
import ScoreComponent from "./score-component";
import { Utils } from "../utils";
import { NoteTools } from "../core/tools/scoretools/note-tools";
import { Tables } from "../tables";
import { Graphics } from '../core/graphics';
import { MidiPlayer, PlaybackCursor } from '../midi-player';
import Formatter from "../core/formatter";
import { Memento } from "../memento";
import PrintManager from "../print-manager";
import ContextMenu from "./widgets/context-menu";
import { immutableUpdate } from "./immutability";
import Toast, { Notify } from "./widgets/toast";
import Catch from "./catch";
import { connect } from 'react-redux';
import { setAudioOff, setRealisationData, setCurrentSelection } from '../actions/realisation-actions';
import { showRealisationList } from '../actions/modal-actions';
import { getScoreOptions } from '../reducers/realisation-reducer';
import { bindAll } from 'lodash';


const ZOOM_LEVELS = [10, 25, 50, 75, 100, 125, 150, 200, 400, 800, 1600, 2400, 3200];

export { ZOOM_LEVELS };

export const RealisationContainerContext = React.createContext();

// Helper function for onDragEnd (duplicated from ../core/score.js).
const isLine = (elem) => {
  return elem.nodeName.toLowerCase() === "line";
};

class RealisationContainerBase extends React.Component {

  constructor(props) {
    super(props);
    this.width = 1800;
    this.scoreComponent = React.createRef();
    this.notify = new Notify();
    bindAll(this, "load", "save", "print", "refresh", "undo", "redo", "onAudioPlayback", "onFinishAudioPlayback", "handleOnClick", "onDragEnd");
  }

  load() {
    let { type, page, order, showRealisationList } = this.props;
    try {
      let realisations = JSON.parse(localStorage.getItem("realisations"));
      if (!realisations || !(realisations.find(obj => obj.type==type && obj.page==page && obj.order==order))) {
        this.notify.warn('Sorry, there are no saved realisations for the current notation.');
      }
      else {
        showRealisationList();
      }
    }
    catch(error) {
      console.log(error);
      this.notify.error('An error occurred while attempting to load the realisation.');
    }
  }

  save() {
    let { type, page, order, data } = this.props;
    try {
      const toSave = {
        "data": data,
        "type": type,
        "page": page,
        "order": order,
        "name": "Untitled",
        "date": Utils.getISODateString()
      };
      const realisations = JSON.parse(localStorage.getItem("realisations")) || [];
      realisations.push(toSave);
      localStorage.setItem("realisations", JSON.stringify(realisations));
      let msg = "Saved realisation for Notation " + type + " (page" + page + ")";
      this.notify.success(msg);
    }
    catch(error) {
      console.log(error);
      this.notify.error('An error occurred while attempting to save your realisation.');
    }
  }

  print() {
    if (!this.printManager) {
      this.printManager = new PrintManager({
        score: this.score
      });
    }
    let { type, page } = this.props;
    let title = "Notation "+type+", Page "+page;
    this.printManager.title = title;
    this.printManager.print();
  }

  refresh() {
    let data = this.props.notation.realise();
    this.props.setRealisationData(data);
  }

  undo() {
    console.log("Undo not yet implemented.");
  }

  redo() {
    console.log("Redo not yet implemented.");
  }

  // These zoom handlers also zoom the transcription. We certainly DON'T want to be doing this here,
  // though; all of the zooming code contained in these methods could actually be moved to the
  // Score module's zooming code. So the Score object will need a 'zoomLevels' array (which could
  // be passed in as an initialisation option). These methods will then just call the Score module's
  // zoom method. The transcription container will have equivalent methods, so when we have a space-time
  // notation the toolbar zoom button handler will just trigger both the realisation container and
  // transcription container zoom handlers. It shouldn't ever be an issue which is called first, I think.

  zoom(pcnt) {
    //this.player.isPlaying() && this.player.stop();
    this.score.zoom(pcnt);
    let { notation } = this.props;
    this.props.isSpaceTime && notation.transcription.zoom(pcnt);
  }

  changeDisplayMode(val) {
    let data;
    if (val==="Analysis") {
      data = this.DisplayModeMemento.restore("data");
    }
    else if (val==="Performance") {
      this.DisplayModeMemento.store("data", this.props.data);
      data = this.formatter.toPerformanceMode({
        isDividedByHand: this.props.isNotationDividedByHand
      });
    }
    this.props.setRealisationData(data);
  }

  toggleTimeGrid() {
    this.score.toggleTimeGrid();
  }

  changeTimeGridIntervalSize(size) {
    let { duration, showTimeGrid } = this.props;
    this.score.numTimeGridDivisions = duration/size;
    if (showTimeGrid) {
      this.score.removeTimeGrid();
      this.score.drawTimeGrid();
    }
  }

  toggleDynamics(val) {
    this.score.showDynamics = val;
    this.score.toggleDynamics();
    this.score.clear().render();
  }

  startAudioPlayback() {
    var player = this.player;
    var voices = this.score.getVoices();
    var data = voices.map(voice => {
      var offset = parseInt(voice.getOffset());
      var notes = voice.getNotes();
      var noteStructs = notes.map(note => {
        var struct = note.serialize();
        struct.dynamic = note.getDynamicText();
        return struct;
      });
      return {
        noteStructs: noteStructs,
        offset: offset
      };
    });
    let { duration } = this.props;
    let scoreDur = this.score.getDuration();
    this.playbackCursor.render();
    player.setData(data, scoreDur, duration).play();
  }

  pauseAudioPlayback() {
    this.player.pause();
  }

  resumeAudioPlayback() {
    this.player.resume();
  }

  stopAudioPlayback() {
    this.player.stop();
    this.playbackCursor.remove();
  }

  onAudioPlayback(pcnt) {
    this.playbackCursor.update(pcnt);
  }

  onFinishAudioPlayback() {
    this.props.setAudioOff();
  }

  respellNote(opts) {
    let { id, key, keyIndex, accidental } = opts;
    var note = this.score.getNoteFromID(id);
    note.respell(keyIndex, key, accidental);
    this.score.clear().render();
  }

  getNoteRespellMenuItems(e) {
    var realisation = this.score;
    var id = e.target.closest('.vf-stavenote').getAttribute("id");
    var note = realisation.getNoteFromID(id);
    var notehead = e.target.closest('.vf-notehead');
    var index = note.getNoteheadElemIndex(notehead);
    var key = note.getKeyAtIndex(index);
    var enharmonics = Utils.getEnharmonics(key);
    var items = enharmonics.map(k => {
      var acc = k.substring(1).toLowerCase();
      var accUnicode = Tables.accidentalToUnicode[acc] || "";
      var spelling = k[0]+accUnicode;
      return {
        name: spelling,
        onClick: (e) => {
          var key = e.target.textContent;
          this.respellNote({
            id: id,
            key: key,
            accidental: NoteTools.formatUnicodeAccidental(key.substring(1)),
            keyIndex: index
          });
        }
      };
    });
    return items;
  }

  setCurrentSelectionInfo(sel, keyIndex) {
    var { duration } = this.props;
    var key = sel.getKeyAtIndex(keyIndex).toLowerCase();
    var noteName = key[0];
    var acc = key.slice(1);
    var accUnicode = Tables.accidentalToUnicode[acc] || "";
    var octave = sel.getPitches()[keyIndex].octave;
    var startX = this.score.getStaves()[0].getNoteStartX();
    var endX = this.score.getStaves()[0].getNoteEndX();
    var width = endX - startX;
    var x = sel.getAbsoluteX() - startX;
    var onset = Math.floor(x/width*duration);
    noteName = noteName.toUpperCase()+accUnicode+"/"+octave;
    let payload = { pitch: noteName, onset: Utils.msToTime(onset) };
    this.props.setCurrentSelection(payload);
  }

  componentDidMount() {
    this.score = this.scoreComponent.current;
    let { title, notation } = this.props;
    notation.realisation = this.score
    this.DisplayModeMemento = new Memento();
    this.formatter = new Formatter({ score: this.score });
    this.score.title = title;
    this.player = new MidiPlayer({
      callback: this.onAudioPlayback,
      onFinish: this.onFinishAudioPlayback
    });
    this.playbackCursor = new PlaybackCursor({ score: this.score });
    /*
    this.noteRespellMenu = new ContextMenu({
      elem: this.score.getElem().parentNode,
      id: "note-respell-menu",
      scopes: "#realisation.performance-mode .vf-stavenote",
      itemsFunction: this.getNoteRespellMenuItems.bind(this)
    });
    */
  }

  onDragEnd(e) {
    let target = e.target;
    if (target && target.classList.contains("dynamic")) {
      const dragFilament = Graphics.siblings(target, isLine)[0];
      dragFilament.setAttribute("stroke-opacity", 0);
      let noteId = target.getAttribute("data-note-id");
      const note = this.score.getNoteFromID("vf-"+noteId);
      const { matrix } = target.transform.baseVal.getItem(0);
      let { e: offsetX, f: offsetY } = matrix;
      let x = parseInt(target.getAttribute("x"))+offsetX;
      let y = parseInt(target.getAttribute("y"))+offsetY;
      let voice = note.getVoice();
      let stave = voice.getStave();
      let noteIndex = voice.getNotes().indexOf( note );
      let voiceIndex = stave.getVoices().indexOf( voice );
      let staveIndex = this.score.getStaves().indexOf( stave );
      const scoreWidth = stave.getEffectiveWidth();
      const startX = stave.getNoteStartX();
      x = (x - startX) / scoreWidth;
      const data = immutableUpdate(this.props.data, {
        stave: staveIndex,
        voice: voiceIndex,
        noteStruct: noteIndex
      }, { dynamicX: x, dynamicY: y });
      this.props.setRealisationData(data);
    }
  }

  handleOnClick(e) {
    const { target } = e;
    const noteheadElem = target.closest("g.vf-notehead");
    if (noteheadElem) {
      const noteElem = noteheadElem.closest("g.vf-stavenote");
      const note = this.score.getNoteFromID(noteElem.id);
      this.score.toggleSelection(note);
      const noteheadElemKids = noteheadElem.querySelectorAll("*");
      Utils.htmlCollectionToArray(noteheadElemKids).forEach(elem => {
        elem.setAttribute("fill", "blue");
        elem.setAttribute("stroke", "blue");
      });
      var keyIndex = note.getNoteheadElemIndex(noteheadElem);
      this.setCurrentSelectionInfo(note, keyIndex);
    }
    else {
      this.score.toggleSelection(null);
      this.props.setCurrentSelection(undefined);
    }
  }

  componentWillUnmount() {
    let { isPlaying, isPaused } = this.props;
    (isPlaying || isPaused) && this.stopAudioPlayback();
    // this.state.isPlaying will still be true if we exit in the middle of playback... does it matter?
    //console.log(this.state.isPlaying);
    this.score.removeEventHandlers();
  }

  componentDidUpdate(prevProps, prevState) {
    // ZOOMING.
    let { currentZoomLevel, displayMode, isPlaying, isPaused, duration, showTimeGrid, timeGridIntervalSize, showDynamics } = this.props;
    if ( !(currentZoomLevel===prevProps.currentZoomLevel) ) {
      this.zoom(currentZoomLevel);
    }
    // DISPLAY MODE.
    if ( !(displayMode===prevProps.displayMode) ) {
      prevProps.displayMode==="Performance" && (isPlaying || isPaused) && this.stopAudioPlayback();
      this.changeDisplayMode(displayMode);
    }
    // TIMEGRID.
    if ( !(showTimeGrid===prevProps.showTimeGrid) ) {
      this.toggleTimeGrid(showTimeGrid);
    }
    if ( !(timeGridIntervalSize===prevProps.timeGridIntervalSize) || !(duration===prevProps.duration) ) {
      this.changeTimeGridIntervalSize(timeGridIntervalSize);
    }
    // DYNAMICS.
    if ( !(showDynamics===prevProps.showDynamics) ) {
      this.toggleDynamics(showDynamics);
    }
    // AUDIO PLAYBACK.
    if ( !(isPlaying===prevProps.isPlaying) ) {
      if (isPlaying===true) {
        this.startAudioPlayback();
       }
       else if (isPlaying===false) {
         this.stopAudioPlayback();
       }
    }
    if ( isPlaying===true && !(isPaused===prevProps.isPaused) ) {
      isPaused===true ? this.pauseAudioPlayback() : this.resumeAudioPlayback();
    }
  }

  render() {
    let { data, notation, scoreOptions } = this.props;
    let options = { ...scoreOptions, onDragEnd: this.onDragEnd };
    const handlers = {
      load : this.load,
      save: this.save,
      print: this.print,
      refresh : this.refresh,
      undo : this.undo,
      redo : this.redo
    }
    return (
      <div className="shadow-2" style={ {position: "relative"} }>
        <RealisationContainerContext.Provider value={ handlers }>
          <RealisationToolbar />
        </RealisationContainerContext.Provider>
        <Catch>
          <ScoreComponent
            id="realisation"
            data={data}
            options={ options }
            onClick={this.handleOnClick}
            postRender={ (score) => notation.renderRealisationGraphics(score) }
            forwardedRef={ this.scoreComponent }
          />
          <ContextMenu
            id="note-respell-menu"
            ctx={"#realisation.performance-mode .vf-stavenote"}
            items={this.getNoteRespellMenuItems.bind(this)}
          />
        </Catch>
        <Toast />
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    type: state.notation.type,
    page: state.notation.page,
    order: state.notation.order,
    data: state.realisation.data,
    currentZoomLevel: state.realisation.currentZoomLevel,
    displayMode: state.realisation.displayMode,
    isPlaying: state.realisation.isPlaying,
    isPaused: state.realisation.isPaused,
    showTimeGrid: state.realisation.showTimeGrid,
    timeGridIntervalSize: state.realisation.timeGridIntervalSize,
    showDynamics: state.realisation.showDynamics,
    duration: state.realisation.duration,
    isSpaceTime: state.notation.parameters.isSpaceTime,
    isNotationDividedByHand: state.notation.parameters.isNotationDividedByHand,
    scoreOptions: getScoreOptions(state)
  }
}

const mapDispatchToProps = {
  setRealisationData: setRealisationData,
  showRealisationList: showRealisationList,
  setAudioOff: setAudioOff,
  setCurrentSelection: setCurrentSelection
}

const RealisationContainer = connect(mapStateToProps, mapDispatchToProps)(RealisationContainerBase);

export default RealisationContainer;
