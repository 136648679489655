import React, { useState } from "react";
import { PageTOC, PageTopBar } from "./layout";
import { withRouter } from 'react-router-dom';

const components = [
  {
    title: "Notation Selector",
    text: "The Notation Selector is used to load new notations, with the dropdown selector components for the notation type and the page of each occurrence. A third selector will also appear for notations which have two instances on a given page.",
    top: "0.0077", //"8px",
    left: "0.00432" //"5px"
  },
  {
    title: "Notation Information",
    text: "Click this menu item to open the Notation Information Sidebar, on the right of the main UI. It provides detailed information on the current notation, in the form of commentary and directions for generating realisations.",
    top: "0.0077", //"8px",
    left: "0.84342" //"975px"
  },
  {
    title: "Settings",
    text: "Click this menu item to open the Settings dialog, which can be used to configure a number of application features.",
    top: "0.0077", //"8px",
    left: "0.88667" //"1025px"
  },
  {
    title: "Notation Instructions",
    text: "Presents Cage's original instructions for the current notation.",
    top: "0.0796", // "82px",
    left: "0.002595" //"3px"
  },
  {
    title: "Transcription",
    text: "Presents a transcription of the original notation.",
    top: "0.18446", //"190px",
    left: "0.285467" //"330px"
  },
  {
    title: "Realisation",
    text: "Presents the realisation for the current notation.",
    top: "0.737864", //"760px",
    left: "0.058823" //"68px"
  },
  {
    title: "Realisation Toolbar",
    text: "Presents a range of controls for interacting with and displaying the realisation.",
    top: "0.645631", //"665px",
    left: "0.449826" //"520px"
  }
];

const ComponentNumber = (props) => {
  let { component, number, onClick } = props;
  let { top, left } = component;
  return (
    <div className="quickstart-component-number" style={ { top: (top*100)+"%", left: (left*100)+"%" } }>
      <span onClick={ onClick }>{ number }</span>
    </div>
  );
}

const QuickStart = () => {
  let [componentNumber, setComponentNumber] = useState(0);
  let { title, text } = components[componentNumber];
  const numbers = components.map((obj, i) => (
    <ComponentNumber component={obj} number={i+1} onClick={ () => setComponentNumber(i) } />
  ));
  return (
    <div id="quickstart-page" className="container-fluid">
      <div className="row">
        <article className="col-12" id="quickstart">
          <h3 style={ { padding: "12px", display: "flex", justifyContent: "center", borderBottom: "1px solid #eaecef" } }>Quickstart</h3>
          <p>This page provides a quide for those users who wish to quickly start using the app. To find out about a particular app component click the relevant number in the screenshot below. Information regarding the component will then be displayed in a panel below the screenshot.</p>
          <p>For more detailed information on the app and its functions please consult the <a href="/guide/overview">User Guide</a>.</p>
          <div className="img-panel" style={ { position: "relative", display: "flex", justifyContent: "center" } }>
            <div style={ { position: "relative" } }>
            <img src="/img/user-guide/quickstart.png" id="quickstart-img" style={ { maxWidth: "100%" } }/>
            { numbers }
            </div>
          </div>
          <div id="quickstart-info-panel">
            <h5>{ title }</h5>
            <p>{ text }</p>
          </div>
        </article>
      </div>
    </div>
  );
}

export default withRouter(QuickStart);