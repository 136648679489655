import React from "react";
import { default as ReactSimpleTimeField } from "../../node_modules/react-simple-timefield/dist/index.js";
import { Utils } from "../../utils.js";

export default class TimeField extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      time: props.time || "00:00:00"
    };
    this.onTimeChange = this.onTimeChange.bind(this);
  }

  onTimeChange(value) {
    let { onChange } = this.props;
    let ms = Utils.timeToMs(value);
    // If the user inputs 00:00:00 (=0ms) the thing freezes the browser,
    // so (for now) only run the onChange handler for non-zero input...
    if (ms!==0) {
      this.setState( { time: value }, onChange && (() => {
        let values = { hhmmss: value, ms: Utils.timeToMs(value) };
        onChange(values)
      }));
    }
  }

  render() {
    let { className, id, title, style } = this.props;
    const { time } = this.state;
    const defaultFontSpec = { fontFamily: "inherit", fontSize: ".875rem", fontWeight: 500 };
    return (
      <ReactSimpleTimeField
        value={ time }
        onChange={ this.onTimeChange }
        showSeconds={ true }
        style={ { width: "90px", color: "black", textAlign: "center", padding: "auto", ...defaultFontSpec, ...style } }
        { ...{ className, id, title } }
      />
    );
  }
}