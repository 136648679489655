import React from "react";
import Toast, { Notify } from "./widgets/toast";

export default class Catch extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.notify = new Notify();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //console.log(error, errorInfo);
    const msg = "Sorry, an error occurred. If you continue to experience this behaviour please send a bug report to support@cageconcert.org. \
    For more information on reporting errors please see the Bug Reports section of the User Guide.";
    this.notify.error(msg);
  }

  render() {
    if (this.state.hasError) {
      //Render fallback UI on error...
      const style = {
        color: "rgba(0,0,0,.54)",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        alignItems: "center",
        paddingTop: "50px",
        paddingBottom: "50px"
      };
      return <div>
        <h3 style={ style }>An error occurred, cannot render.</h3>
        <Toast />
      </div>
    }
    return this.props.children; 
  }

}