import Spanner from '../base/spanner';

export default class PedalMarkSpanner extends Spanner {

  constructor(options) {
    super(options);
    this.lineType = options.lineType || "solid";
    this.startX = options.startX || 0;
    this.endX = options.endX;
    this.startXShift = options.startXShift || 0;
    this.endXShift = options.endXShift || 0;
    this.yShift = options.yShift || 50;
    this.barHeight = options.barHeight || 16;
  }

  getLength() {
    return this.endX-this.startX;
  }

  setStave(stave) {
    this.stave = stave;
    return this;
  }

  getRenderContext() {
    return this.renderContext;
  }

  setRenderContext(ctx) {
    this.renderContext = ctx;
    return this;
  }

  getElem() {
    return this.elem;
  }

  render() {
    var ctx = this.getRenderContext();

    var attr = {
      fill: "none",
      "stroke-width": this.lineWidth,
      stroke: "black"
    };

    if (this.lineType=="dotDash") attr["stroke-dasharray"] = [12,6,4,6];

    var startX = this.startX-this.startXShift;
    var endX = this.endX+this.endXShift;
    var y = this.stave.getYForBottomLine()+50+this.yShift;

    // We use an SVG group to bind the parts, and set this as the element.
    this.elem = ctx.openGroup("pedal-mark");
    ctx.line(startX, y-this.barHeight, startX, y);
    ctx.line(startX, y, endX, y, attr);
    ctx.line(endX, y-this.barHeight, endX, y);
    ctx.closeGroup();

    return this;
  }

}
