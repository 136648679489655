/*
App Menu Component.
*/

import React, { useState, useRef } from "react";
import { AppBar, Toolbar, IconButton, Menu, MenuItem, MenuList, Paper, Popover, ListItemIcon, ListItemText, Divider, Typography, Link, Fade, makeStyles } from '@material-ui/core';
import FlatButton from './widgets/flat-button';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import RestoreIcon from '@material-ui/icons/Restore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import NotationSelector from './notation-selector';
import RecentNotationsList from './recent-notations-list';
import { useDispatch } from 'react-redux'


const useStyles = makeStyles((theme) => ({
  abRoot: {
    backgroundColor: "#f5f5f5",
    boxShadow: "none"
  },
  tbRoot: {
    minHeight: "60px"
  },
  lsIcon: {
    minWidth: "36px"
  },
  ////////
  popover: {
    pointerEvents: "none",
  },
  popoverContent: {
    pointerEvents: "auto",
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
}));

const MoreOptions = (props) => {
  const { anchorEl, handleCloseMenu, showAboutDialog } = props;

  const classes = useStyles();

  return (
    <Menu id="menu-more-options"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={handleCloseMenu}>
        <ListItemIcon style={ { minWidth: 36 } }>
          <i className="glyphicon glyphicon-home mr-2"/>
        </ListItemIcon>
        <Typography variant="inherit">
          <Link href="#" target="_blank" style={ { color: "inherit", textDecoration: "none" } }>Home</Link>
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleCloseMenu}>
        <ListItemIcon style={ { minWidth: 36 } }>
          <i className="glyphicon glyphicon-book mr-2"/>
        </ListItemIcon>
        <Typography variant="inherit">
          <Link href="/guide/overview/" target="_blank" style={ { color: "inherit", textDecoration: "none" } }>User Guide</Link>
        </Typography>
      </MenuItem>
      <MenuItem onClick={showAboutDialog}>
        <ListItemIcon style={ { minWidth: 36 } }><HelpIcon fontSize="small" /></ListItemIcon>
        <Typography variant="inherit">About</Typography>
      </MenuItem>
      <Divider />
      <SubMenu id="recent-notations-submenu" text="Recent Notations">
        <RecentNotationsList />
      </SubMenu>
    </Menu>
  );
}

// This version adapted from https://stackoverflow.com/a/61486098
// Demo: https://codesandbox.io/s/material-demo-79he1?file=/demo.js:1073-1134
const SubMenu = ({ id, text, children }) => {

  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);

  const classes = useStyles();

  const handleMouseEnter = (e) => {
    setOpenedPopover(true);
  };

  const handleClose = (e) => {
    setOpenedPopover(false);
  };
  
  return (
    <div id={id}>
      <MenuItem
        ref={popoverAnchor}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleClose}
      >
        <ListItemText variant="inherit">
          { text }
        </ListItemText>
        <KeyboardArrowRightIcon/>
      </MenuItem>
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{onMouseEnter: handleMouseEnter, onMouseLeave: handleClose}}
      >
        <div>
          <MenuList>{ children }</MenuList>
        </div>
      </Popover>
    </div>
  );
}

/*
{({ TransitionProps }) => (
          <Fade { ...TransitionProps } timeout={300}>
            <div>
              <Paper style={ paperStyle }>
                <MenuList>{ children }</MenuList>
              </Paper>
            </div>
          </Fade>
        )}
*/

const MainMenu = () => {
  const dispatch = useDispatch();
  const [ anchorEl, setAnchorEl ] = useState(null);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const showNotationInfo = (e) => {
    e.preventDefault();
    dispatch({type: "SHOW_NOTATION_INFO"});
  }

  const showSettingDialog = (e) => {
    e.preventDefault();
    dispatch({type: "SHOW_SETTINGS_DIALOG"});
  }

  const showAboutDialog = (e) => {
    e.preventDefault();
    dispatch({type: "SHOW_ABOUT_DIALOG"});
    handleCloseMenu();
  }

  const classes = useStyles();

  // N.B: The container class on the Toolbar component is from material.min.css,
  // which is not part of material-ui.
  return (
    <AppBar position="fixed" id="app-menu" classes={ { root: classes.abRoot } }>
      <Toolbar className="container" classes={ { root: classes.tbRoot } }>
        <NotationSelector />
        <FlatButton onClick={showNotationInfo} title="Notation Information">
          <InfoIcon style={ { fontSize: "20px" } } />
        </FlatButton>
        <FlatButton onClick={showSettingDialog} title="Settings">
          <i className="glyphicon glyphicon-cog" style={ { fontSize: "18px" } }/>
        </FlatButton>
        <FlatButton aria-label="menu" onClick={handleOpenMenu} title="More Options" >
          <i className="glyphicon glyphicon-option-vertical" style={ { fontSize: "18px" } }/>
        </FlatButton>
        <MoreOptions anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} showAboutDialog={showAboutDialog}/>
      </Toolbar>
    </AppBar>
  );
}

export default MainMenu;
