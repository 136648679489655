import React from "react";
import ButtonGroup from "..//widgets/button-group";
import GlyphIconButton from "../widgets/glyphicon-button";
import { useSelector, useDispatch } from "react-redux";


const PlaybackControls = () => {
  let { isPlaying, isPaused } = useSelector(state => ({
    isPlaying: state.realisation.isPlaying,
    isPaused: state.realisation.isPaused
  }));
  const dispatch = useDispatch();
  const play = () => dispatch({ type: "IS_PLAYING", payload: true });
  const stop = () => dispatch({ type: "IS_PLAYING", payload: false });
  const togglePaused = () => dispatch({ type: "IS_PAUSED" });
  let playPauseBtn = isPlaying
    ? <GlyphIconButton id="pause" title="Pause" icon="pause" onClick={ togglePaused }/>
    : <GlyphIconButton id="play" title="Play" icon="play" onClick={ play }/>;
  var btns = [
    playPauseBtn,
    { id: "stop", title: "Stop", icon: "stop", onClick: stop }
  ];
  return (
    <div className="btn-group ml-auto" id="playback-controls">
      { playPauseBtn }
      <GlyphIconButton id="stop" title="Stop" icon="stop" onClick={ stop }/>
    </div>
  );
}

export default PlaybackControls;