/*
Transcription Container Component.
*/

import React, { useLayoutEffect, useRef } from "react";
import ContextMenu from "./widgets/context-menu";
import ScoreComponent from './score-component';
import EditModeToolbar from "./edit-mode-toolbar";
import { setRealisationData } from '../actions/realisation-actions';
import { getScoreOptions } from '../reducers/notation-reducer';
import { useSelector, useDispatch } from "react-redux";


const TranscriptionContainer = (props) => {

  const scoreComponent = useRef();

  const data = useSelector(state => state.notation.data);
  const scoreOptions = useSelector(state => getScoreOptions(state));

  const dispatch = useDispatch();

  const updateRealisationData = (e, callback, options) => {
    dispatch({
      type: "UPDATE_REALISATION_DATA",
      payload: { callback: callback, options: options }})
  };

  useLayoutEffect(() => {
    const score = scoreComponent.current;
    // Add the '.realisable' class to the transcription score element. We toggle
    // this class when moving between Analysis and Performance modes.
    score.getElem().classList.toggle("realisable");
    const { notation } = props;
    notation.vent.on("_update", updateRealisationData);
    const data = notation.realise();
    dispatch(setRealisationData(data));
  }, [], () => notation.vent.off("_update", updateRealisationData));

  const getRealisationModeMenuItems = (e) => {
    const score = scoreComponent.current;
    //const modes = options.modes || ["Single notes", "Dyads", "Aggregates", "Clusters"];
    const modes = ["Single notes", "Dyads", "Aggregates", "Clusters"];
    const items = modes.map(mode => {
      return {
        name: mode,
        onClick: (e, elem) => {
          $(elem).data({mode: mode});
          $(elem).trigger("click");
        }
      };
    });
    return items;
  }

  const { notation } = props;
  const { init, editModeWidgets } = notation;

  return (
    <div className="shadow-2">
      { editModeWidgets && <EditModeToolbar notation={notation} widgets={editModeWidgets}/> }
      <ScoreComponent
        id="transcription"
        data={data}
        options={scoreOptions}
        init={init}
        postRender={ (score) => notation.setTranscription(score) }
        forwardedRef={scoreComponent}
      />
      {
        notation.hasRealisationModeMenu &&
        <ContextMenu
          id="realisation-mode-menu"
          ctx={"#transcription.realisable "+(notation.realisationClass || ".vf-stavenote")}
          items={getRealisationModeMenuItems}
        />
      }
    </div>
  );
}

export default TranscriptionContainer;