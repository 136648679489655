import { Utils } from '../utils';

export default class Canvas {

  constructor(options) {
    this.el = options.el;
    // The metrics object represents the metrics of the musical system, not the 'canvas'.
    this.metrics = Utils.merge({}, Canvas.DEFAULT_METRICS);
    if (typeof options.metrics == "object") {
      Utils.merge(this.metrics, options.metrics);
    }

    this.zoomLevel = 100;

    this.renderer = new Vex.Flow.Renderer(this.el, Vex.Flow.Renderer.Backends.SVG);
    this.ctx = this.renderer.getContext();
  }

  getElem() {
    return this.el;
  }

  getRenderContext() {
    return this.ctx;
  }

  getMetrics() {
    return this.metrics;
  }

  getZoomLevel() {
    return this.zoomLevel;
  }

  setZoomLevel(pcnt) {
    this.zoomLevel = pcnt;
    return this;
  }

  zoom(pcnt) {
    this.setZoomLevel(pcnt).clear().render();
    return this;
  }

  clear() {
    var ctx = this.getRenderContext();
    ctx.clear();
    //It seems we need a call to preserveAspectRatio after clearing. For more see: https://groups.google.com/d/msg/vexflow/mrCN4grzAUs/vD9yNA1HUhcJ
    ctx.svg.setAttribute("preserveAspectRatio", "xMinYMin meet"); // or "xMinYMin slice"?
    return this;
  }

  // This calls renderer.resize, and so sets the dimensions of the entire SVG 'canvas'. Perhaps shouldn't be a public method.
  resize(width, height) {
    this.renderer.resize(width, height);
    // We also need to then rescale. This seems to fix the distortion that's sometimes been occurring when we move between notations in the
    // app. This is due, it seems, to the viewBox not being scaled to the new viewport, and scaling here appears to fix this.
    this.getRenderContext().scale(1, 1);
    return this;
  }

  getWidth() {
    return this.getMetrics().width;
  }

  setWidth(width) {
    this.getMetrics().width = width;
    this.clear().render();
    return this;
  }

  setHeight(height) {
    this.getMetrics().height = height;
    this.clear().render();
    return this;
  }

  getMargins() {
    return {
      marginTop: this.metrics.marginTop,
      marginRight: this.metrics.marginRight,
      marginBottom: this.metrics.marginBottom,
      marginLeft: this.metrics.marginLeft
    }
  }

  setMargins(top, right, bottom, left) {
    this.setMetrics({
      marginTop: top,
      marginRight: right,
      marginBottom: bottom,
      marginLeft: left
    });
  }

  // Set metrics by passing in key value pairs.
  setMetrics(obj) {
    if (typeof obj == "object") {
      Utils.merge(this.metrics, obj);
    }
    this.clear().render();
    return this;
  }

  render() {
    return this;
  }

};

Canvas.DEFAULT_METRICS = {
  width: 0,
  height: 0,
  marginTop: 6,
  marginRight: 20,
  marginBottom: 80,
  marginLeft: 20
};
