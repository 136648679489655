import Score from './score';
import { Utils } from '../utils';
import { Graphics } from './graphics';
import { cloneDeep } from 'lodash';
import * as jsnx from 'jsnetworkx';

export default class NetworkScore extends Score {

  constructor(options) {
    super(options);
    this._graph = new jsnx.MultiGraph();
    this.maxReversePasses = NetworkScore.DEFAULT_MAX_REVERSE_PASSES;
  }

  addNode(node, data) {
    this._graph.addNode(node, data);
    return this.getNodes();
  }

  addNodes(data) {
    this._graph.addNodesFrom(data);
    return this.getNodes();
  }

  removeNode(node) {
    this._graph.removeNode(node);
    return this.getNodes();
  }

  removeNodes(nodes) {
    this._graph.removeNodes(nodes);
    return this.getNodes();
  }

  getNode(node) {
    return this._graph.nodes(true)[node];
  }

  getNodes() {
    return this._graph.nodes(true);
  }

  getNeighbours(node) {
    return this._graph.neighbors(node);
  }

  addPath(nodes) {
    this._graph.addPath(nodes);
  }

  numberOfNodes() {
    return this._graph.numberOfNodes();
  }

  addEdge(node1, node2, data) {
    var data = data || {};
    this._graph.addEdge(node1, node2, data);
    return this.getEdges();
  }

  addEdges(edges) {
    this._graph.addEdgesFrom(edges);
    return this.getEdges();
  }

  addWeightedEdges(edges) {
    this._graph.addWeightedEdgesFrom(edges);
    return this.getEdges();
  }

  removeEdge(edge) {
    this._graph.removeEdge(edge);
    return this.getEdges();
  }

  removeEdges(data) {
    this._graph.removeEdgesFrom(data);
    return this.getEdges();
  }

  getEdges() {
    return this._graph.edges(true);
  }

  drawEdge(edge) {
	  var ctx = this.getRenderContext();
    var {note: startNote, pitchIndex: startPitch} = this.getNode(edge[0])[1];
    var {note: endNote, pitchIndex: endPitch} = this.getNode(edge[1])[1];
    var startX = startNote.getNoteCenterXY().x;
    var startY = startNote.getYs()[startPitch];
    var endX = endNote.getNoteCenterXY().x;
    var endY = endNote.getYs()[endPitch];
    Graphics.drawLine(ctx, startX, startY, endX, endY);
  }

/*
  getPath() {
    var currNode = 0;
    var n = this.numberOfNodes();
    var path = [currNode];
    while (currNode<n-1) {
      var neighbours = this.getNeighbours(currNode);
      var nextNode = Utils.getRandElem(neighbours);
      path.push(nextNode);
      if (currNode>nextNode) {
        this._graph.addEdge(currNode, nextNode, 0, {numReversePasses: 1});
      }
      currNode = nextNode;
    }
    return path;
  }

  getAvailableNodes(node) {
    var edges = this.getEdgesForNode(node);
    var available = [];
    var maxReversePasses = this.maxReversePasses;
    edges.forEach(function(edge) {
      var numReversePasses = edge[2].numReversePasses;
      var neighbour = edge[1];
      if (numReversePasses<maxReversePasses) {
        available.push(neighbour);
      }
    });
    return available;
  }
*/

  getStartNode() {
    let startNodes = this.getStartNodes();
	  return Utils.getRandElem(startNodes);
  }

  getStartNodes() {
    return this.filterNodes("isStartNode");
  }

  getStartNodePitches() {
    let startNodes = this.filterNodes("isStartNode", true);
    let result = [];
    startNodes.forEach(node => {
      let pitches = [].concat( node[1].note.serialize().pitches );
      let index = node[1].pitchIndex;
      result = result.concat(pitches[index]);
    });
    return result;
  }

  filterNodes(key, optData) {
	  var nodes = this.getNodes();
	  var filtered = [];
	  nodes.forEach(node => {
	    if (node[1].hasOwnProperty(key)) {
		    filtered.push(optData ? node : node[0]);
	    }
	  });
	  return filtered;
  }
  
  getEndNodes() {
	  var nodes = this.getNodes();
	  var endNodes = [];
	  nodes.forEach(node => {
	    if (node[1].hasOwnProperty("isEndNode")) {
		    endNodes.push(node[0]);
	    }
	  });
	  return endNodes;
  }

  getPath(start, endNodes) {
    var currNode = start || 0;
	  var endNodes = endNodes || this.getEndNodes();
    var path = [currNode];
    while ( !Utils.contains(endNodes, currNode) ) {
      var availableEdges = this.getAvailableEdges(currNode);
      var edge = Utils.getRandElem(availableEdges);
      var nextNode = edge[1];
      path.push(nextNode);
      if (currNode>nextNode) {
        var edgeData = edge[2];
        edgeData.numReversePasses+=1;
        this._graph.addEdge(currNode, nextNode, 0, edgeData);
      }
      currNode = nextNode;
    }
    return path;
  }

  getAvailableEdges(node) {
    var edges = this.getEdgesForNode(node);
    var available = [];
    var maxReversePasses = this.maxReversePasses;
    edges.forEach(edge => {
      var numReversePasses = edge[2].numReversePasses;
      // An available edge is one which is either moving forward (that is, its
      // start node is numerically prior to its end node), or, failing that, one
      // whose numReversePasses count is below the maximum allowed. This means
      // that we can always move forward from a node, but can only move backward
      // maxReversePasses number of times.
      if (edge[0]<edge[1] || numReversePasses<maxReversePasses) {
        available.push(edge);
      }
    });
    return available;
  }

  getEdgesForNode(node) {
    return this._graph.edges(node, true);
  }

  reset() {
    var score = this;
    var edges = this.getEdges();
    edges.forEach(function(edge) {
      score._graph.addEdge(edge[0], edge[1], 0, {numReversePasses: 0});
    });
    return this;
  }
  
  buildGraph() {
	  var nodeList = this.data.nodeList;
    var notes = this.getNotes();
    nodeList.forEach(nodeStruct => {
	    var {node, targets, data} = cloneDeep(nodeStruct); //Utils.merge({}, nodeStruct);
	    data.note = notes[data.note];
      this.addNode(node, data);
      if (nodeStruct.hasOwnProperty("targets")) {
		    var targets = nodeStruct.targets;
        targets.forEach(target => {
          this.addEdge(node, target, {numReversePasses: 0});
        });
      }
    });
    return this;
  }

  render() {

    super.render();

    this.buildGraph()
	  .getEdges()
	    .forEach(edge => this.drawEdge(edge));

    return this;

  }

}

NetworkScore.DEFAULT_MAX_REVERSE_PASSES = 1;
