import React from "react";
import { PageTopBar } from "./layout";

const GlossaryOfTerms = () => {
  return (
    <section id="bugs-features">
      <PageTopBar title="6. Glossary of Terms"/>
      <h5>Aggregate</h5>
      <p>Cage used the term aggregate to indicate a group of 3 or more notes, usually played together as a chord.</p>
      <h5>Cluster</h5>
      <p>These are used to designate fully chromatic clusters, using all notes between the upper and lower limits.</p>
      <h5>Dyad</h5>
      <p>This refers to any two notes played at the same time.</p>
      <h5>Event</h5>
      <p>This refers to any single event within the notation, including pitches and noises. A single pitch, an aggregate, a cluster, or a noise are all events.</p>
      <h5>Gamut</h5>
      <p>Cage uses this term to describe a fixed set of pitches which form the material for a given moment, or period, or whole piece. Its use may be heard most clearly in Cage's music from the late 1940s which tends toward stasis and repetition.</p>
      <h5>Harmonics</h5>
      <p>These are created when a note or notes are silently depressed followed by one or more notes played normally, triggering resonances from the notes which are silently depressed.</p>
      <h5>Space-time</h5>
      <p>This is a term used to indicate notations which depict when a note should begin in relation to the space on the page. Notes are not given rhythmic values but instead have relationship to each other according to the distance between them.</p>
    </section>
  );
}

export default GlossaryOfTerms;