import React from "react";


const anchorStyle = {
  //padding: ".6875rem 1rem",
  textDecoration: "none",
  borderRadius: "0px",
  cursor: "pointer",
  minWidth: "auto !important",
  backgroundColor: "rgb(0,0,0,0)",
  border: "1px solid rgba(0,0,0,0)",
  color: "black",
  boxShadow: "none",
  lineHeight: 1,
  "&:hover": {
    color: "#0098f7",
    backgroundImage: "linear-gradient(180deg,rgba(0,0,0,.12),rgba(0,0,0,.12))"
  },
}

const GlyphIconButton = (props) => {
  const { classNames, icon, id, title, onClick, iconStyle={} } = props;
  const classNamez = classNames ? ` ${classNames}` : "";
  return (
    <a className={`btn flat-button${classNamez}`} id={id} title={title} onClick={onClick}>
      <i className={`glyphicon glyphicon-${icon}`} style={ iconStyle }/>
    </a>
  );
}

export default GlyphIconButton;