import { Utils } from "./utils";
import { Tables } from "./tables";

import * as MIDI from "midicube";
import midigen from "jsmidgen";

class PlaybackCursor {
  
  constructor(options) {
    let renderOptions = options.renderOptions || {};
    this.renderOptions = Object.assign({ stroke: "red", "stroke-width": 1.5 }, renderOptions);
    this.score = options.score;
  }

  setRenderContext(ctx) {
    this.ctx = ctx;
    return this;
  }

  getRenderContext() {
    return this.ctx;
  }

  getPlaybackRegionWidth() {
    return this.score.getStaves()[0].getEffectiveWidth();
  }

  getPlaybackStartX() {
    return this.score.getStaves()[0].getNoteStartX();
  }

  update(pcnt) {
    let x = this.getPlaybackStartX()+(this.getPlaybackRegionWidth()*pcnt);
    return this.move(x);
  }

  move(x) {
    this.ctx.applyAttributes(this.elem, { x1: x, x2: x });
    return this; 
  }

  remove() {
    if (this.elem) {
      // Just an insurance policy for the moment, since the parent (the score div) might
      // get cleared by another event. Clearly we need a better way of handling this!
      let parent = this.elem.parentNode;
      parent && parent.removeChild(this.elem);
      this.elem = null;
    }
    return this;
  }

  render() {
    this.setRenderContext(this.score.getRenderContext());
    let ctx = this.ctx;
    let staves = this.score.getStaves();
    let x = this.getPlaybackStartX();
    let y1 = staves[0].getYForLine(0);
    let y2 = staves[staves.length-1].getYForLine(4);
    ctx.line(x, y1, x, y2, this.renderOptions);
    this.elem = ctx.parent.lastChild;
    this.elem.setAttribute("class", "playback-cursor");
    return this;
  }

}

// For certain keys (cb, cbb, b# and b##) we have to shift the octave either down (cb, cbb) or up (b#, b##)
// by 1. We can do this by checking the result of subtracting the root_index from the int_val, which gives
// us testable values for these specific cases. Rather crude and 'brute force', but it works. Can't see a
// more elegant way to do it right now.
const _keyToMidi = (key) => {
  var [ noteName, octave ] = key.split("/");
  noteName = noteName.toLowerCase();
  var { int_val, root_index } = Vex.Flow.Music.noteValues[noteName];
  var diff = int_val-root_index;
  // Only cb and cbb yield values above 6 (11 and 10, respectively), so test for this.
  if (diff>6) {
    octave--;
  }
  // Only b# and b## yield values below -1 (-6 and -5, respectively), so test for this.
  else if (diff<-1) {
    octave++;
  }
  return (12 + (octave * 12)) + int_val;
}

const keyToMidi = (k) => {
  if (!(k instanceof Array)) {
    return _keyToMidi(k);
  }
  else {
    return k.map(key => _keyToMidi(key));
  }
}

const dynamicToVelocity = {
  "ppp": 15,
  "pp": 31,
  "p": 47,
  "mp": 63,
  "mf": 79,
  "f": 95,
  "ff": 111,
  "fff": 127
};

const DEFAULT_SOUNDFONT_URL = "/soundfont/";
const DEFAULT_INSTRUMENT = "acoustic_grand_piano";

class MidiPlayer {
  
  constructor(options) {
    var options = Utils.merge({
      soundfontUrl: DEFAULT_SOUNDFONT_URL,
		  instrument: DEFAULT_INSTRUMENT,
		  data: [],
      // jsmidigen sets 128 ticks per beat,
      // see https://github.com/dingram/jsmidgen#miditrack;
      ticksPerBeat: 128,
		  isPaused: false
    }, options || {});
    this._player = new MIDI.Player(); //new MIDI.Player();
    this.soundfontUrl = options.soundfontUrl;
    this.instrument = options.instrument;
    this.data = options.data;
    this.ticksPerBeat = options.ticksPerBeat;
    this.callback = options.callback;
    this.onFinish = options.onFinish;
    this.onprogress = options.onprogress;
    this.onerror = options.onerror;
    this.isPaused = false;
  }

  setData(voiceStructArrays, scoreDur, playbackDur) {
    const ticksPerMillisecond = (this._player.BPM * this.ticksPerBeat) / 60000;
    const scaleDuration = (duration) => {
      return Math.round((parseInt(duration)/scoreDur)*playbackDur)*ticksPerMillisecond;
    };
    const file = new midigen.File({ticks: this.ticksPerBeat});
    const channel = 0;
    // Unless we include this 'dummy' track some issues occur with playback
    // of single lines that begin with an offset/gap - it seems to miss out
    // the first note, but the playback cursor starts from the beginning
    // then 'jumps' to the second note when it starts playing. This doesn't
    // solve another, similar problem that occurs, which is that when we
    // have a single line that has notes from the very start, with no initial
    // gap, the first note will again not be audible. If we have multiple such
    // lines, however, only the first note of the top line will be heard.
    // These problems may all  be related to a known issue with MIDI.js, see
    // more here: https://github.com/mudcube/MIDI.js/pulls.
    if (voiceStructArrays.length == 1) {
      const track = new midigen.Track();
      file.addTrack(track);
      const duration = scaleDuration(scoreDur);
      track.addNote(channel, "", duration, 0, 0);
    }
    voiceStructArrays.map((voiceStruct) => {
      const noteStructs = voiceStruct.noteStructs;
      const track = new midigen.Track();
		  const deltaTime = scaleDuration(voiceStruct.offset);
      file.addTrack(track);
      // If we have an offset add a 'silent' note.
      // See https://github.com/dingram/jsmidgen/issues/22.
      if (deltaTime > 0) {
        track.addNote(channel, "", deltaTime)
      }
      noteStructs.forEach((struct, i) => {
        const pitches = struct.pitches;
        const duration = scaleDuration(struct.duration);
        const velocity = dynamicToVelocity[struct.dynamic] || Utils.getRandElem(Tables.velocityScale);
        const p = keyToMidi(pitches);
        !(p instanceof Array) ?
          track.addNote(channel, p, duration, 0, velocity)
          : track.addChord(channel, p, duration, 0, velocity);
      });
    });
    const bytes = file.toBytes();
    this.data = "data:audio/midi;base64,"+btoa(bytes);
    return this;
  }

  onAudioProcess(data) {
    const pcnt = data.now / data.end;
    if (this.isPlaying() && pcnt<=1) {
      if (pcnt==1) {
        this.onFinish && this.onFinish();
        this.stop();
      }
      else {
        this.callback && this.callback(pcnt);
      }
    }
  }

  getCurrentTime() {
    return this._player && this._player.currentTime;
  }

  getEndTime() {
    return this._player && this._player.endTime;
  }

  play() {
    // See https://github.com/mudcube/MIDI.js/issues/167#issuecomment-620501622
    // for why we need to do this (we get silence otherwise).
    !window.MIDI && (window.MIDI = MIDI);
    //this.stop(); // This errors with midicube (fork of original), but not mudcube (the original).
    const base64 = this.data;
    try {
      MIDI.loadPlugin({
        soundfontUrl: this.soundfontUrl,
        instrument: this.instrument,
        onsuccess: () => {
          this._player.loadFile(base64, () => this._player.start());
          this._player.setAnimation((data) => this.onAudioProcess(data));
        },
        onprogress: this.onprogress,
        onerror: this.onerror
      });
    }
    catch(event) {
      this.onerror && this.onerror(event);
    }
    return this;
  }

  isPlaying() {
    return this._player.playing;
  }

  pause() {
    this._player.pause();
    this.isPaused = true;
    return this;
  }
  
  resume() {
    this._player.resume();
    this.isPaused=false;
    return this;
  }
  
  stop() {
    this._player.stop();
    this._player.clearAnimation();
    this.isPaused = false;
    return this;
  }
  
}

export { MidiPlayer, PlaybackCursor }
