import React from "react";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const RadioButton = (props) => {
  let { id, value, checked, label, onChange } = props;
  return (
    <div className="form-check form-check-inline">
      <input className="form-check-input" type="radio" value={value} id={id} onChange={onChange} checked={checked}/>
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  );
}

const RadioButtonGroupOLD = (props) => {
  const { items } = props;
  const radios = items.map(item => {
    let { id, value, checked, label, onChange } = item;
    return <RadioButton { ...{ id, value, checked, label, onChange } }/>
  });
  return (
    <div className="form-group">
      { radios }
    </div>
  );
}

const RadioButtonGroup = (props) => {
  const { items } = props;
  const style = { color: "#2196f3" };
  const radios = items.map(item => {
    let { id, value, checked, label, onChange } = item;
    return <FormControlLabel control={<Radio style={ style }/>} { ...{ id, value, checked, label, onChange } }/>
  });
  return (
    <FormControl>
      <RadioGroup row style={ {marginBottom: "0.75rem"} }>{ radios }</RadioGroup>
    </FormControl>
  );
}

export { RadioButton, RadioButtonGroup };