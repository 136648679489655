import React from "react";
import { PageTopBar } from "./layout";

const Overview = () => {
  return (
    <section id="overview">
      <PageTopBar title="1. Overview"/>
      <p>Welcome to the User Guide for the Cage Concert Project Solo for Piano App.</p>
      <img src="/img/user-guide/quickstart.png" id="quickstart-img" style={ { maxWidth: "52%" } }/>
      <p>Designed to be used by anyone interested in Cage’s music - from students and amateur musicians, to professional performers, composers and musicologists - the Solo for Piano App provides users a way of directly interacting with Cage's notations, to try out possibilities for performance, and to generate music which may be used in performance.</p>
      <p>The app presents Cage's notations in an uncluttered, responsive, dynamic user interface, allowing the user to rapidly create realisations through familiar modes of interaction (button clicks, mouse events, gestures, etc.). Each notation has been carefully transcribed from the original score into digital format, with the accompanying instructions in each case serving as the algorithmic basis for generating realisations. The app thus also has the additional role of clarifying, both graphically and musically, a work which has seemed to many impenetrable, revealing a lucidity of vision and intentionality entirely consistent with the composer's more familiar output.</p>
      <p>As a means to gain a better understanding of Cage’s score, and as a tool for generating realisations for performance, it is hoped above all that the Solo for Piano app is used in a spirit of adventure and curiosity, inspiring many more possibilities for performance than the app is directly able to offer.</p>
      <p>Currently the app makes available the first two 'alphabets'of notations (A-Z, AA-AZ). Further notations will be added in the near future until the full set of 84 notations (including repetitions) has been implemented.</p>
      <p>This guide is divided into two main sections:</p>
      <ul>
        <li>A general introduction to the app's user interface, its various components and their functions.</li>
        <li>A detailed 'walkthrough' section, describing a typical app session.</li>
      </ul>
    </section>
  );
}

export default Overview;