import React from "react";

const FourOhFour = { fontSize: "6rem", fontWeight: 300, lineHeight: 1.2 };

export default function NotFound() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <span style={ FourOhFour }>404</span>
          <div style={ { marginBottom: "1.5rem" } }>Sorry, the page you requested was not found.</div>
          <a href="/app" class="btn btn-link">Back to Cage Concert Solo App</a>
        </div>
      </div>
    </div>
  )
}