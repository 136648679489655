import React from "react";
import { PageTopBar } from "./layout";

const BugsAndFeatures = () => {
  return (
    <section id="bugs-features">
      <PageTopBar title="5. Bug Reports and Feature Requests"/>
      <p>To submit a bug report or feature request please email <a href="mailto:support@cageconcert.org">support@cageconcert.org</a>. When sending such a report please be careful to describe the error that occurred in as much detail as possible, noting both the context (the notation, and any interaction that might have taken place), and any error messages which might have appeared.</p>
      <p>The app is designed to handle bugs and errors gracefully, and in general they should not interfere with its overall functionality. If an error occurs which renders the entire app unresponsive, however, it is possible to recover from this by simply reloading the page (if necessary also clearing the browser cache).</p>
    </section>
  );
}

export default BugsAndFeatures;