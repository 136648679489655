import React, { Fragment } from "react";
import { Utils } from "../../utils";
import GlyphIconButton from "../widgets/glyphicon-button";
import TimeField from "../widgets/time-field";
import PlaybackControls from "./playback-controls";
import { useSelector, useDispatch } from "react-redux";


const PerformanceModeControls = () => {
  
  const { duration, showTimeGrid, timeGridIntervalSize, showDynamics, hasFixedDynamics } = useSelector(state => ({
    duration: state.realisation.duration,
    showTimeGrid: state.realisation.showTimeGrid,
    timeGridIntervalSize: state.realisation.timeGridIntervalSize,
    showDynamics: state.realisation.showTimeGrid,
    hasFixedDynamics: state.notation.parameters.hasFixedDynamics || false
  }));
  
  const dispatch = useDispatch();

  const dur = Utils.msToTime(duration);
  const intervalSize = Utils.msToTime(timeGridIntervalSize);
  
  const changeDuration = (values) => dispatch({ type: "SET_DURATION", payload: values.ms });
  const toggleTimeGrid = () => dispatch({ type: "SHOW_TIME_GRID" });
  const changeNumTimeGridDivisions = (values) => dispatch({ type: "SET_TIMEGRID_INTERVAL_SIZE", payload: values.ms });
  const toggleDynamics = () => dispatch({ type: "SHOW_DYNAMICS" });

  const timeGridBtnState = showTimeGrid ? " active" : "";

  const timeGridSegmentSizeCtrl = <TimeField
    className="form-control"
    id="time-grid-segment-size"
    title="Time Grid Segment Size (hh:mm:ss)"
    time={intervalSize}
    onChange={changeNumTimeGridDivisions}/>

  const dynamicsBtnState = hasFixedDynamics ? " disabled" : showDynamics ? " active" : "";

  const dynamicsBtnIconStyle = {
    fontFamily: "gonville",
    fontWeight: "bold",
    fontSize: "16px",
    textTransform: "lowercase"
  };
  
  return (
    <div className="btn-group" id="performance-mode-controls">
      <TimeField className="form-control" id="duration" title="Set Duration (hh:mm:ss)" time={dur} onChange={changeDuration}/>
      <GlyphIconButton classNames={timeGridBtnState} id="toggle-timegrid" title="Show Time Grid" icon="th" onClick={toggleTimeGrid}/>
      { showTimeGrid && timeGridSegmentSizeCtrl }
      <a className={ "btn flat-button"+dynamicsBtnState } id="toggle-dynamics" title="Show Dynamics" onClick={toggleDynamics}>
        <i style={ dynamicsBtnIconStyle }>ff</i>
      </a>
    </div>
  );
}

const ViewControls = (props) => {
  const { displayMode } = props;
  return (
    <Fragment>
      <div className="btn-group" id="view-controls">
        { displayMode==="Performance" && <PerformanceModeControls /> }
      </div>
      { displayMode==="Performance" && <PlaybackControls /> }
    </Fragment>
  );
}

export default ViewControls;
