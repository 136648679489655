import { Utils } from "../utils";
import { createSelector } from 'reselect'

const initialState = {
  key: Utils.guid(),
  type: "A",
  page: 1,
  order: 1,
  instructions: "",
  comments: "",
  directions: "",
  editMode: "",
  data: null
};

const notationReducer = (state=initialState, action) => {
  switch (action.type) {
    case "CHANGE_EDIT_MODE":
      return { ...state, editMode: action.payload };
    case "SET_NOTATION_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

// Selectors

const getParameters = (state) => state.notation.parameters;

export const getScoreOptions = createSelector(
  getParameters,
  (parameters) => {
    const { metrics={}, transcriptionScoreType="simple", isGrandStaff=false, showStaveConnectors } = parameters;
    return {
      type: transcriptionScoreType,
      isGrandStaff: isGrandStaff,
      showStaveConnectors: showStaveConnectors,
      metrics: { width: 1800, ...metrics.transcription }
    }
  }
);

export default notationReducer;