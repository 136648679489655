import React, { useState } from "react";
import { Box, Container, Drawer, IconButton, List, MenuItem, Link, Divider } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Overview from "./overview";
import UserInterface from "./user-interface";
import Walkthrough from "./walkthrough";
//import Configuration from "./configuration";
import MinimalConfig from "./minimal-config";
import BugsAndFeatures from "./bugs-and-features";
import GlossaryOfTerms from "./glossary";
import { withRouter, Route } from "react-router-dom";
import { classes } from "jsnetworkx";

const UserGuideSideBarAnchors = [
  { href: "/guide/overview/",       title: "Overview" },
  { href: "/guide/user-interface/", title: "The User Interface" },
  { href: "/guide/walkthrough/",    title: "A Typical App Session" },
  { href: "/guide/user-config/",    title: "Configuration" },
  { href: "/guide/bugs-features/",  title: "Bug Reports" },
  { href: "/guide/glossary/",       title: "Glossary" }
];

const UserGuideSideBarOLD = (props) => {
  let { currentPage } = props;
  const items = UserGuideSideBarAnchors.map((anchorDef, i) => {
    let { href, title } = anchorDef;
    let text = `${i+1}. ${title}`;
    let className = currentPage===href && "user-guide-menu-current-page";
    return (
      <li className={className}><a href={href}>{text}</a></li>
    )
  });
  return (
    <div id="user-guide-menu-container">
      <ul id="user-guide-menu">
        { items }
      </ul>
    </div>
  );
}

const drawerWidth = 240;

const styles = ({ palette }) => ({
  tempDrawer: {
    display: {
      xs: 'block',
      sm: 'none'
    },
    '& .MuiDrawer-paper': {
      paddingTop: "80px",
      boxSizing: 'border-box',
      width: drawerWidth
    },
  },
  permDrawer: {
    display: {
      xs: 'none',
      sm: 'block'
    },
    '& .MuiDrawer-paper': {
      paddingTop: "80px",
      boxSizing: 'border-box',
      width: drawerWidth
    },
  },
  userGuidePageArticle: {
    paddingRight: "70px",
    paddingBottom: "50px",
    paddingLeft: `${drawerWidth+30}px`,
  }
});

const useStyles = makeStyles(styles);

const UserGuideSideBar = (props) => {
  const { currentPage } = props;

  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const linkStyle = { color: "inherit", textDecoration: "none" };

  const items = UserGuideSideBarAnchors.map((anchorDef, i) => {
    let { href, title } = anchorDef;
    let text = `${i+1}. ${title}`;
    return (
      <MenuItem selected={href===currentPage}>
        <Link href={href} style={ linkStyle }>{ text }</Link>
      </MenuItem>
    )
  });

  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        classNam={classes.tempDrawer}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <List>{ items }</List>
      </Drawer>
      <Drawer
        variant="permanent"
        className={classes.permDrawer}
        open
      >
        <List>{ items }</List>
      </Drawer>
    </Box>
  );
}

const UserGuideOLD = (props) => {

  let { match, location } = props;
  let { path } = match;
  let { pathname } = location;

  return (
    <div id="user-guide-page" className="container-fluid">
      <div className="row">
        <UserGuideSideBar { ...{ currentPage: pathname } }/>
        <article className="col-12">
          <div id="user-guide-content">
            <Route path={`${path}overview/`} component={ Overview }></Route>
            <Route path={`${path}user-interface/`} component={ UserInterface }></Route>
            <Route path={`${path}walkthrough/`} component={ Walkthrough }></Route>
            <Route path={`${path}user-config/`} component={ MinimalConfig }></Route>
            <Route path={`${path}bugs-features/`} component={ BugsAndFeatures }></Route>
            <Route path={`${path}glossary/`} component={ GlossaryOfTerms }></Route>
          </div>
        </article>
      </div>
    </div>
  );

}

const UserGuide = (props) => {

  let { match, location } = props;
  let { path } = match;
  let { pathname } = location;

  const classes = useStyles();

  return (
    <Box id="user-guide-page">
      <UserGuideSideBar { ...{ currentPage: pathname } }/>
      <article className={classes.userGuidePageArticle}>
        <Box id="user-guide-content">
          <Route path={`${path}overview/`} component={ Overview }></Route>
          <Route path={`${path}user-interface/`} component={ UserInterface }></Route>
          <Route path={`${path}walkthrough/`} component={ Walkthrough }></Route>
          <Route path={`${path}user-config/`} component={ MinimalConfig }></Route>
          <Route path={`${path}bugs-features/`} component={ BugsAndFeatures }></Route>
          <Route path={`${path}glossary/`} component={ GlossaryOfTerms }></Route>
        </Box>
      </article>
    </Box>
  );

}


export default withRouter(UserGuide);