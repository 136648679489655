import Component from './component';

/**
 * Base class for the container component.
 * @class CC.Core.Container
 * @extends CC.Core.Component
 */
export default class Container extends Component {

  constructor(options) {
    super(options);
    this.items = options.items || []; // options.items || {};
    this.parent = options.parent;
    this.isLeaf = false;
  }

  /**
   * Returns the items in the container.
   * @returns {Array} The items.
   */
  getItems() {
    return this.items;
  }

  getItem(i) {
    return this.getItems()[i];
  }

  nth(i) {
    return this.getItem(i);
  }

  indexOf(item) {
    var items = this.getItems();
    return items.indexOf(item);
  }

  /** @memberof Container.prototype */
  add(item, i) {
    if (!i) {
	  this.append(item);
	}
	else {
      this.items[i] = item;
	}
    return this.getItems();
  }

  /** @memberof Container.prototype */
  remove(item) {
    var index = this.indexOf(element);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
	return this.getItems();
  }

  /** @memberof Container.prototype */
  append(item) {
    this.items.push(item);
    return this.getItems();
  }

  /** @memberof Container.prototype */
  prepend(item) {
    this.items.unshift(item);
    return this.getItems();
  }

  /** @memberof Container.prototype */
  length() {
    return this.getItems().length;
  }

  /** @memberof Container.prototype */
  numItems() {
    return this.length();
  }

  /** @memberof Container.prototype */
  getParent() {
    return this.parent;
  }

  /** @memberof Container.prototype */
  each(func) {}

  /** @memberof Container.prototype */
  map(func) {}

  /** @memberof Container.prototype */
  empty() {
    this.items = [];
    return this;
  }

  copy() {}

  /** @memberof Container.prototype */
  flatten() {}

}
