import React from "react";
import { PageTopBar } from "./layout";

const Walkthrough = () => {
  let toc = [
    [{text: "Loading a Notation", href: "#loading-a-notation"}, []],
    [{text: "Generating Realisations", href: "#generating-realisations"}, []],
    [
      {text: "Using the Realisation Toolbar", href: "#realisation-toolbar"}, [
        {text: "Saving and Printing Realisations", href: "#saving-and-printing"},
        {text: "Updating", href: "#updating"},
        {text: "Zooming", href: "#zooming"},
        {text: "Display Modes", href: "#display-modes"},
        {text: "Setting a Duration", href: "#set-duration"},
        {text: "Time Grid", href: "#timegrid"},
        {text: "Dynamics", href: "#dynamics"},
        {text: "MIDI Playback", href: "#midi"},
        {text: "Note Respell Menu", href: "#note-respell-menu"}
      ]
    ],
    [{text: "Display Bar", href: "#display-bar"}, []]
  ];
  return (
    <section id="walkthrough">
      <PageTopBar title="3. A Typical App Session" toc={ toc }/>
      <p>The following sections together comprise a 'walkthrough' guide for a typical session using the Solo app.</p>
      <section>
        <h4 id="loading-a-notation">3.1. Loading a Notation</h4>
        <p>Opening the Solo app in the browser will load the very first notation in the score, Notation A on page 1. To load a new notation use the Notation Selector on the left of the main menu bar. Previously-loaded notations may be reloaded from the Recent Notations submenu of the Options Menu, located on the right-hand side of the main menu bar.</p>
        <h4 id="generating-realisations">3.2. Generating Realisations</h4>
        <p>A new realisation for a given notation is generated automatically every time the notation is loaded into the application. Some notations - for example Notation C, and Notation F - have fixed realisations, however, in which case the realisation will always be the same (in such cases the realisation can be modified by the addition of random dynamics - for more on this see the section below, <a href="#performance-mode">Performance Mode</a>).</p>
        <p>A completely new realisation can be generated for the current notation using the Refresh button on the toolbar at the top of the Realisation Panel. This will run the same algorithm used to generate the automatic realisation generated at load-time. For many notations the realisation can also be updated from within the transcription through mouse/touch interaction. Such an update might be 'global', as in the case of a Refresh button update, or be more fine grained, offering the possibility to update a single element or selection of elements in the realisation. In all cases, however, such 'point and click' updates afford a greater degree of control over the process of generating a realisation than the Refresh button.</p>
        <h4 id="realisation-toolbar">3.3. Using the Realisation Toolbar</h4>
        <p>The toolbar at the top of the Realisation Panel provides a range of useful functions for interacting with the realisation.</p>
        <div className="img-panel">
          <img src="/img/user-guide/realisation-toolbar.png" />
        </div>
        <p>Controls in the realisation toolbar are arranged into logical groups, related by functionality. The following sections describe each of these controls in detail.</p>
        <section>
          <h5 id="saving-and-printing">3.3.1. Saving and Printing Realisations</h5>
          <p>Click the 'Save' button (second from the left on the toolbar) to save the current realisation. Realisations are saved to the browser's local storage, and so are available for reloading into the app even after refreshing the page (clearing the browser's stored data will, however, remove any saved realisations).</p>
          <p>To load a previously saved realisation click the 'Load' icon to the far left of the toolbar. If the notation has no saved realisations a message indicating so will appear briefly, otherwise a dialog will be opened displaying a list of saved realisations. Realisations are listed by name and date on which saved. Select a realisation from the list, and load it by clicking the 'Load Selected' button.</p>
          <p>To print a realisation simply click the 'Print' icon on the toolbar, which will open the standard print dialog for the browser in which the app is running. Then select the appropriate method in the dialog to save as PDF (for example under Chrome change the print destination to 'Save as PDF'). Printing will always occur at the current zoom level. Although the app always displays the realisation on a single system, printing will distribute it over as many systems and across as many pages as are necessary to accommodate the chosen zoom level. Printing is available in both display modes (see below). If dynamics and/or a time grid have been added these will be retained in the printed PDF.</p>
          <h5 id="updating">3.3.2. Updating</h5>
          <p>Use the Refresh button to generate a new realisation, as described above. Note that updating is only possible in Analysis mode, and in Performance mode the button is disabled.</p>
          <h5 id="zooming">3.3.3. Zooming</h5>
          <p>The realisation 'score' may be zoomed using the zoom controls. These consist of zoom in, zoom out, and zooming by some percentage of the score's initial width (between 10% and 3200%, inclusive).</p>
          <div className="img-panel">
            <img src="/img/user-guide/zoom-controls.png" style={ { maxWidth: "70%" } }/>
          </div>
          <p>Note that 'zooming' here means increasing or decreasing the width of the score; notes and other elements with a point of occurrence in time are repositioned proportionally to the new width, but do not themselves change in size in any way. Clefs and other 'out of time' elements will remain unchanged in position.</p>
          <p>For space-time notations the initial width of the realisation ('100% zoom') will in general correspond to that of the transcription. Elsewhere the realisation will occupy just as much space in the horizontal direction as results in a legible score, with note collisions reduced to a minimum. For very 'busy' realisations this is often not possible, however, and zooming becomes a useful tool in such cases for increasing readability.</p>
          <p>For true space-time notations, such as Notation C, zooming the realisation will simultaneously cause the transcription to also zoom, since the points of occurrence of 'events' in the two scores are linked. In such circumstances the two scores can also be scrolled simultaneously.</p>
          <h5 id="display-modes">3.3.4. Display Modes</h5>
          <p>The realisation may be presented in different 'display modes', with the Display Mode dropdown selector used to switch between them.</p>
          <div className="img-panel">
            <img src="/img/user-guide/performance-mode-controls.png" />
          </div>
          <p>There are two available modes:</p>
          <ul>
            <li><h6>Analysis</h6>
              <p>In this mode (the default) the realisation is displayed in its 'raw' form, without consideration for playability (although it might well still be perfectly performable). In general in this mode notes are rendered with the same stave layout and clefs as the transcription. For example, in Notation C, whose realisation is a virtual duplicate of the transcription, the 'impractical' distribution of notes in the original is retained, irrespective of ledger lines.</p>
              <p>Although certainly impractical from the point of view of performance, such a presentation makes the relationship between the transcription and the realisation very clear. Even in cases of extreme unreadability, however, it is still possible to determine the pitch of a note by clicking it, which will display the pitch and time onset of the note at the top right of the panel (for more details on this see the section below, <a>Display Bar</a>).</p>
            </li>
            <li id="performance-mode"><h6>Performance</h6>
              <p>In contrast to Analysis mode, the Performance display mode presents the realisation in such a way as to make it performable, or as close to that as possible. Notes are distributed across two staves, treble and bass, as in traditional piano music, with staves mapping to two hands in the standard way (in a small number of cases, due to the requirements of Cage's instructions, the distribution is across <em>four</em> staves, with two staves per hand - see Notation E, for example). The relationship between the transcription and the realisation in Performance Mode may, due to the differing layout and distribution of events, therefore often not be immediately apparent.</p>
              <p>Redistribution of notes in Performance Mode is in all cases determined by the following pair of rules:</p>
              <ul>
                <li>For a treble stave in the transcription, any note <em>below</em> A3 is shifted to the <em>bass</em> stave.</li>
                <li>For a bass stave in the transcription, any note <em>above</em> E4 is shifted to the <em>treble</em> stave.</li>  
              </ul>
              <br />
              <p>Note that in Performance Mode it is not possible to update the realisation, either through the refresh button (which is disabled) or through any mouse/touch events.</p>
            </li>
          </ul>
          <p>The following subsections (3.3.5 to 3.3.9) relate to Performance Mode only.</p>
          <h5 id="set-duration">3.3.5. Setting a Duration</h5>
          <p>Use the Duration control to set the duration for the realisation, measured in hours, minutes, seconds format (hh:mm:ss). The duration may be set to any length of time, in increments of 1/10th of a second, with a lower limit of 1/10th of a second. There is no effective upper limit.</p>
          <h5 id="timegrid">3.3.6. Time Grid</h5>
          <div className="img-panel">
            <img src="/img/user-guide/time-grid.png" />
          </div>
          <p>The realisation may be divided into a grid of equal time segments using the Time Grid control. The grid 'chunk' size can be set to any length of time, relative to the overall duration, in hh:mm:ss format. Changing the duration of the chunk will automatically update the time grid rendered in the realisation score.</p>
          <h5 id="dynamics">3.3.7. Dynamics</h5>
          <p>Display of dynamics is controlled by toggling the Dynamics toolbar button. By default dynamics are not shown. The distribution of dynamics is entirely random, in the range <em>ppp</em> to <em>fff</em>, inclusive. Since the realisation is a space-time score, with notes often sitting in close proximity to one another, it is likely some collisions of dynamic text will occur. Zooming out the realisation is a quick way to remedy this, but a more satisfactory solution is to drag individual dynamics into positions where they are more legible. To move a dynamic simply click and drag it to the desired position. On clicking a dynamic a dotted line or 'drag filament' will appear, connecting the dynamic to its note. The filament will remain connected to the note during the drag process.</p>
          <h5 id="midi">3.3.8. MIDI Playback</h5>
          <p>In the majority of cases MIDI playback of a realisation is possible, using the playback controls at the far right of the realisation toolbar (Play/Pause and Stop). For notations involving non-determinate noise sounds playback is disabled. Playback will occur at the rate determined by the Duration control on the toolbar (see the section below, <a href="#set-duration">Setting a Duration</a>).</p>
          <p>Note that MIDI playback is only offered as a convenience, to give some idea of what a realisation might sound like in performance. It is in no way intended as a definitive interpretation of the what the realisation should 'sound' like.</p>
          <h5 id="note-respell-menu">3.3.9. Note Respell Menu</h5>
          <p>Right-clicking (Ctrl-click on macOS) any note in the realisation whilst in Performance Mode will bring up the Note Respell Menu.</p>
          <div className="img-panel">
            <img src="/img/user-guide/note-respell-menu.png" />
          </div>
          <p>The Note Respell Menu is a context menu which allows for the spelling of a note to be changed - for example Bb to A#, D to Ebb, etc. For any given note the menu will be populated with all available enharmonic spellings for that note (including the current one). By default the only accidentals used within the realisation are sharps. The Note Respell Menu is provided as a way of changing this on a note-per-note basis, given that alternative spellings may be desired. Selecting any enharmonic equivalent from the list provided will immediately update the selected note to use the chosen spelling.</p>
        </section>
        <h4 id="display-bar">3.4. Display Bar</h4>
        <div className="img-panel">
          <img src="/img/user-guide/display-bar.png" />
        </div>
        <p>A small region at the top of the realisation panel - the Display Bar - is reserved for displaying, on the left side, the title of the realisation, and on the right information on any currently selected note. A realisation may be given a title when it is saved, but prior to that will simply be 'Untitled'. In Analysis mode a selected note will have its pitch displayed, while in Performance mode (since a duration is specified) its time onset will also be shown.</p>
      </section>
    </section>
  );
}

export default Walkthrough;