/*
Notation Container Component.
*/

import React from "react";
import { Utils } from "../utils";
import TranscriptionContainer from './transcription-container';
import RealisationContainer from './realisation-container';
import NotationInstructions from "./notation-instructions";
import { connect } from 'react-redux';

const Separator = () => {
  return (
    <hr style={ {borderTop: "1px solid rgba(0,0,0,0)", margin: "5px 0"} }/>
  );
}

class NotationContainerBase extends React.Component {

  constructor(props) {
    super(props);
    let { module, notation } = props;
    this.notation = new module({
      model: Utils.omit(notation, "key")
    });
  }

  componentDidMount() {
    this.notation.setElement( document.querySelector("#notation") );
  }

  componentWillUnmount() {
    this.notation.remove();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isSpaceTime, isRealised } = this.props;
    isSpaceTime && !(isRealised===prevProps.isRealised) && this.addScrollSync();
  }

  // The synced scrolling implementation below was adapted from here: https://stackoverflow.com/a/31084338/795131.
  addScrollSync() {
    // Testing this out it was discovered that scrolling by clicking directly within the scrollbar, or by clicking
    // one of the scrollbar arrows, resulted in a very short scroll. It's not clear to me why this problem occurs, but it
    // seems to be something to do with the interdependency of the scroll events between the divs. The following variation
    // on the original implementation solves this issue (found here: https://codereview.stackexchange.com/q/102159). It
    // seems this will only work with two divs. The code is ugly, due to being non-DRY, but it does work, and I can't see
    // more elegant solution right at the moment.
    var $transcription = $("#transcription");
    var $realisation = $("#realisation");
    var scrollTranscription = [];
    var scrollRealisation = [];

    $transcription.scroll(e => {
      if ( !scrollRealisation.pop() ) {
        scrollTranscription.push( true );
        $realisation.scrollLeft(e.target.scrollLeft);
      }
    });

    $realisation.scroll(e => {
      if ( !scrollTranscription.pop() ) {
        scrollRealisation.push( true );
        $transcription.scrollLeft(e.target.scrollLeft);
      }
    });
  }

  render() {
    let { notation, isRealised, notationInstructionsLocation } = this.props;
    let { instructions } = notation;
    let hasTopMargin = !(notationInstructionsLocation==="main-panel") ? " mt-3" : "";
    return (
      <div id="notation" className={"container"+hasTopMargin}>
        <NotationInstructions instructions={ instructions }/>
        <TranscriptionContainer notation={ this.notation } />
        <Separator />
        { isRealised && <RealisationContainer notation={ this.notation } /> }
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    notation : state.notation,
    key : state.notation.key,
    isRealised : state.realisation.data!==null,
    notationInstructionsLocation : state.settings.notationInstructionsLocation,
    isSpaceTime: state.notation.parameters.isSpaceTime,
  }
}

const NotationContainer = connect(mapStateToProps)(NotationContainerBase);

export default NotationContainer;