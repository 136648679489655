export const DEFAULT_SETTINGS = {
  defaultNotation: { type: "A", page: 1, order: 1 },
  defaultNotationSelectionType: "selected",
  notationInstructionsLocation: "main panel",
  fontForDynamics: "Gonville",
  timeFormat: "hh:mm:ss",
  defaultRealisationDuration: "00:00:15",
  defaultTimeGridSegmentSize: "00:00:05",
  timeGridStyle: "dashed",
  timeGridColor: "black",
  //realisationMode: "single notes",
  followScoreOnPlayback: true
};

const initialState = {
  ...DEFAULT_SETTINGS,
  ...JSON.parse(localStorage.getItem("settings"))
}

const settingsReducer = (state=initialState, action) => {
  switch (action.type) {
    case "SAVE_SETTINGS":
      return { ...state, ...action.payload };
    case "RESTORE_DEFAULT_SETTINGS":
      return DEFAULT_SETTINGS;
    default:
      return state;
  }
};

export default settingsReducer;