import { createSelector } from 'reselect'

const initialState = {
  title: "Untitled",
  currentZoomLevel: 100, // Percentage.
  displayMode: "Analysis", // One of "Analysis" or "Performance".
  duration: 15000, // Duration in milliseconds.
  showPerformanceModeToolbar: false,
  showTimeGrid: false,
  timeGridIntervalSize: 5000,
  showDynamics: false,
  isPlaying: false,
  isPaused: false,
  percentPlayed: 0,
  data: null
};

const audioCleanups = {}; // Just a placeholder to avoid an error...

const realisationReducerInner = (state=initialState, action) => {
  switch (action.type) {
    case "SET_TITLE":
      return { ...state, title: action.payload};
    case "SET_REALISATION_DATA":
      return { ...state, data: action.payload };
    case "UPDATE_REALISATION_DATA":
      let { callback, options } = action.payload;
      let data = callback(state.data, options);
      return { ...state, data: data };
    case "CHANGE_ZOOM_LEVEL":
      return { ...state, currentZoomLevel: action.payload, ...audioCleanups };
    case "CHANGE_DISPLAY_MODE":
      return { ...state, displayMode: action.payload };
    case "SET_DURATION":
      return { ...state, duration: action.payload, ...audioCleanups};
    case "SHOW_PERFORMANCE_MODE_TOOLBAR":
      return { ...state, showPerformanceModeToolbar: !state.showPerformanceModeToolbar};
    case "SHOW_TIME_GRID":
      return { ...state, showTimeGrid: !state.showTimeGrid, ...audioCleanups};
    case "SET_TIMEGRID_INTERVAL_SIZE":
      return { ...state, timeGridIntervalSize: action.payload, ...audioCleanups};
    case "SHOW_DYNAMICS":
      return { ...state, showDynamics: !state.showDynamics, ...audioCleanups};
    case "SET_CURRENT_SELECTION":
      return { ...state, currentSelection: action.payload };
    case "IS_PLAYING":
      return { ...state, isPlaying: action.payload };
    case "IS_PAUSED":
      return { ...state, isPaused: !state.isPaused };
    default:
      return state;
  }
};

const realisationReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return realisationReducerInner(state, action);
};

// Selectors

const getParameters = (state) => state.notation.parameters;

export const getScoreOptions = createSelector(
  getParameters,
  (parameters) => {
    const { metrics={}, isGrandStaff=false, hasGrandStaffRealisation, hasFixedDynamics=false } = parameters;
    const { width=1800 } = { ...metrics.transcription };
    return {
      isGrandStaff: 'hasGrandStaffRealisation' in parameters ? hasGrandStaffRealisation : isGrandStaff,
      metrics: metrics.realisation || { width: width },
      showDynamics: hasFixedDynamics
    }
  }
);

export default realisationReducer;