import ScoreObject from './score-object';

export default class System extends ScoreObject {

  constructor(options) {
    super(options);
    this.hasStaveConnectors = true;
    this.staves = [];
  }

  getStaves() {
    return this.staves;
  }

  getStaveCount() {
    return this.getStaves().length;
  }

  addStave(stave) {
    return this.getStaves().push(stave);
  }

  drawConnectors(ctx) {
    var staves = this.getStaves();
    var left = new Vex.Flow.StaveConnector(staves[0]._vfstave, staves[staves.length-1]._vfstave)
      .setType(Vex.Flow.StaveConnector.type.SINGLE_LEFT);
    left.setContext(ctx).draw();
    if (!staves[0].hideEndBarline) {
      var right = new Vex.Flow.StaveConnector(staves[0]._vfstave, staves[staves.length-1]._vfstave)
        .setType(Vex.Flow.StaveConnector.type.SINGLE_RIGHT);
      right.setContext(ctx).draw();
    }
  }

};
