import ScoreObject from './score-object';
import Note from './note';
import Dynamic from './dynamic';

/**
 * Class representing a single voice in a stave. A voice contains note
 * objects, and can have an offset, meaning that the first note in the
 * voice occurs at that point. Currently it is the responsibility of the
 * voice object to draw its notes, though this will change shortly.
 * @class CC.Core.Voice
 * @extends CC.Core.ScoreObject
 * @param {Object} options - Voice options.
 * @param {Array.<CC.Core.Note>} options.notes - Array of notes to add to the voice.
 * @param {string} options.offset - Time offset of the voice.
 */
export default class Voice extends ScoreObject {

  constructor(options) {
    super(options);
    this.notes = [];
    this.offset = "0";
  }

  static build(data) {
    var voice = new Voice(data);
    /*
    data.noteStructs.forEach(function(noteOpts) {
      var note = new Note(noteOpts);
      voice.addNote(note);
    }, this);
    */
    var notes = voice.createNotes(data);
    voice.addNotes(notes);
    return voice;
  }

  getIndex() {
    var voices = this.getStave().getVoices();
    return voices.indexOf(this);
  }

  // By default this returns all the notes in the voice, but we should implement it so that
  // we can get an arbitrary note slice by passing in start and end (or amount) options.
  getNotes() {
    return this.notes;
  }

  getNthNote(index) {
    return this.getNotes()[index];
  }

  getOffset() {
    return this.offset;
  }

  setOffset(val) {
    this.offset = val;
    return val;
  }

  getNoteOnsets() {
    var notes = this.getNotes();
    return notes.map(function(note) {
      return note.getOnset();
    });
  }

  getNoteDurations() {
    var notes = this.getNotes();
    return notes.map(function(note) {
      return note.getDuration();
    });
  }

  getDuration() {
    return this.getNoteDurations().reduce(function(a,b) {
      return a+b;
    });
  }

  getStave() {
    return this.stave;
  }

  setStave(stave) {
    this.stave = stave;
    //this._vfvoice.setStave(stave._vfstave);
    return this;
  }

  getNoteFromID(id) {
    var notes = this.getNotes();
    var match = null;
    var id = id.substring(3); // Since VexFlow adds a 'vf-' prefix to the id we need to strip it.
    for (var i = 0; i < notes.length; i++) {
      var note = notes[i];
      if (note.getID() === id) {
        match = note;
      }
    }
    return match;
  }

  getNoteCount() {
    return this.getNotes().length;
  }

  getMaxNote() {}

  getMinNote() {}

  getRange() {}

  toNoteStrings() {
    var notes = this.getNotes();
    return notes.map(function(note) {
      return note.serialize().pitches;
    });
  }

  _createNotes(clef, noteStructs) {
    //var noteFactory = new NoteFactory();
    return noteStructs.map(function(struct) {
      // Each struct must have at least 'pitches' and 'duration' keys.
      // We just create a basic note right now, rather than using a factory,
      // as we're currently implemeting the different 'types' of note - which
      // are mostly differentiated purely by visual appearance - through the
      // 'type' initialization option. Defining different classes for these
      // types seems like overkill right now.
      var note = new Note({ //noteFactory.createNote({
        pitches: struct.pitches,
        duration: struct.duration,
        clef: clef,
        type: struct.type || "note",
        technique: struct.technique,
        graceNotes: struct.graceNotes,
        dynamicX: struct.dynamicX,
        dynamicY: struct.dynamicY
      });
      if (struct.dynamic) {
        var dynamic = new Dynamic({text: struct.dynamic});
        note.setDynamic(dynamic);
      }
      // Copy any extra keys to the __data__ struct.
      var noteOptions = ["pitches", "duration", "dynamic", "type", "technique", "graceNotes"];
      for (var key in struct) {
        if (struct.hasOwnProperty(key) && noteOptions.indexOf(key)==-1) {
          note.__data__[key] = struct[key];
        }
      }
      return note;
    });
  }

  createNotes(options) {
    var clef = options.clef || "treble";
    var notes = this._createNotes(clef, options.noteStructs);
    if (options.hasOwnProperty("offset") && parseInt(options.offset)>0) {
      this.setOffset(options.offset);
    }
    return notes;
  }

  addNotes(notes) {
    this.notes = notes;
    this.notes.forEach(function(note) {
      note.voice = this;
    }, this);
    return this;
  }

  removeNote(note) {
    var notes = this.getNotes();
    notes.splice(note.getIndex(), 1);
    note.remove();
    return notes.length;
  }

  mapNotes(fn) {
    var notes = this.getNotes();
    return notes.map(fn);
  }

  eachNote(fn) {
    var notes = this.getNotes();
    notes.forEach(fn);
    return notes;
  }

  empty() {}

  hasOffset() {
    return parseInt(this.getOffset())>0;
  }

  findVoiceMaxLine() {
    var notes = this.getNotes();
    var line = Math.max.apply(Math, notes.map(function(note) {
    var index = note.numPitches()-1;
      return note.getLine(index);
    }));
    return line;
  }

  findVoiceMinLine() {
    var notes = this.getNotes();
    var line = Math.min.apply(Math, notes.map(function(note) {
      return note.getLine();
    }));
    return line;
  }

  drawDynamics() {
    this.eachNote(function(note) {
      if (note.showDynamic==true) {
        note.drawDynamic();
      }
    });
  }

  coalesceDynamics() {
    var notes = this.getNotes();
    for (var i = 1; i < notes.length; i++) {
      var thisExpr = notes[i-1].getDynamicText();
      var thatExpr = notes[i].getDynamicText();
      if (thisExpr == thatExpr) {
        notes[i].showDynamic = false;
      }
    }
  }

  drawTechniques() {
    this.eachNote(note => {
      var technique = note.getTechnique();
      if (technique!=="normal") {
        note.drawTechnique();
      }
    });
  }

  drawClusters() {
    this.getNotes().forEach(note => {
      var type = note.type;
      if (type=="cluster") {
        note.drawCluster();
      }
    });
  }

  drawGraceNotes() {
    this.getNotes().forEach(note => {
      let { graceNotes } = note;
      if (graceNotes && Array.isArray(graceNotes)) {
        note.drawGraceNotes();
      }
    });
  }

  serialize() {
    var noteStructs = this.getNotes().map(note => {
      return note.serialize();
    });
    return {
      noteStructs: noteStructs,
      offset: this.getOffset(),
    }
  }

  addOffsetPadding(notes, offset) {
    var clef = this.getStave().getClef();
    var pitch;
    if (clef=="treble") {
      pitch = "B/4";
    }
    else if (clef=="bass") {
      pitch = "D/3";
    }
    else {
      // So we're assuming here that the stave is a 'noise' stave, which has a
      // single line and no clef. But we need to check that properly.
      pitch = "F/5";
    }
    var note = new Vex.Flow.StaveNote({
      keys: [pitch],
      duration: offset,
      clef: clef,
    });
    // Set the style to 'transparent'. For more on the value of rgba see http://stackoverflow.com/a/6047978/795131
    // This method will still leave any ledger lines behind, which is why we set the pitch to the stave's central note.
    var rgba = "rgba(0,0,0,0)";
    note.setStyle({fillStyle: rgba, strokeStyle: rgba});
    notes.unshift(note);
  }

  format(options) {

    const opts = { formatter: new Vex.Flow.Formatter(), formatToGrandStaff: false, ...options };
    const { formatter, formatToGrandStaff } = opts;

    this._vfvoice = new Vex.Flow.Voice({
      num_beats: 4,
      beat_value: 4,
      resolution: Vex.Flow.RESOLUTION
    }).setMode(Vex.Flow.Voice.Mode.SOFT);

    // We 'draw' the notes before the voice, because the VexFlow Voice actually
    // handles the drawing of the notes. All the note.render() method does is
    // set up the VexFlow Note object.
    var vfnotes = this.notes.map(function(note) {
      note.render(); // This creates the VexFlow Note.
      return note._vfnote;
    });

    // If we have an offset add the 'padding' note.
    if (this.hasOffset()) {
      this.addOffsetPadding(vfnotes, this.offset);
    }

    // Add the VexFlow Notes to the VexFlow Voice.
    this._vfvoice.addTickables(vfnotes);

    // N.B: We have to do the formatting AFTER we add the VexFlow Notes, otherwise
    // we get a VexFlow error, "Can't call getBoundingBox on an unformatted note."
    formatter.joinVoices([this._vfvoice]);
    !formatToGrandStaff && formatter.formatToStave([this._vfvoice], this.stave._vfstave);

    return this;
  }

  render(ctx) {
    //var ctx = this.getRenderContext();

    //this.format();

    this._vfvoice.draw(ctx, this.getStave()._vfstave);

    this.drawDynamics();
    this.drawTechniques();
    this.drawClusters();
    this.drawGraceNotes();
    return this;
  }

// The additions and modifications below are required if we want to call
// the _vfnote.draw method. Under raw VexFlow this is called by the voice.draw
// method, and so it's not possible to call it directly. The modified render
// method below effectively reimplements the VexFlow voice.draw method, which in
// fact doesn't do very much (see vexflow/src/voice.js). We also need to make
// some small changes under note.js, namely adding setStave and setRenderContext
// methods, and updating the render method so that it draws the dynamic and
// performance technique indications, if present.
/*
  getBoundingBox() {
    return this._vfvoice.getBoundingBox();
  }

  setBoundingBox(bbox) {
    this._vfvoice.boundingBox = bbox;
    return this;
  }

  mergeBBoxes(box1, box2) {
    var newX = box1.x < box2.x ? box1.x : box2.x;
    var newY = box1.y < box2.y ? box1.y : box2.y;
    var newW = (box1.x + box1.w) < (box2.x + box2.w) ? (box2.x + box2.w) - box1.x : (box1.x + box1.w) - Math.min(box1.x, box2.x);
    var newH = (box1.y + box1.h) < (box2.y + box2.h) ? (box2.y + box2.h) - box1.y : (box1.y + box1.h) - Math.min(box1.y, box2.y);
    return {
      x: newX,
      y: newY,
      w: newW,
      h: newH
    };
  }

  render(ctx) {
    var stave = this.getStave();
    var boundingBox = null;
    var notes = this.getNotes();
    for (var i = 0; i < notes.length; ++i) {
      var note = notes[i];

      // Set the stave;
      note.setStave(stave);

      if (i === 0) boundingBox = note.getBoundingBox();
      if (i > 0 && boundingBox) {
        var noteBBox = note.getBoundingBox();
        if (noteBBox) this.mergeBBoxes(boundingBox, noteBBox);
      }

      note.setRenderContext(ctx).render();
    }

    this.setBoundingBox(boundingBox);
    return this;
  }
*/

};
