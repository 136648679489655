import ScoreObject from './score-object';

const circleSizes = {
  "large": 16,
  "medium": 10,
  "small": 7,
   "very small": 4
};

export default class GeneralizedEvent extends ScoreObject {

  constructor(options) {

    super(options);

    this.x = options.x;
    this.y = options.y;
    this.duration = options.duration;
    this.type = options.type || "notehead"; // One of 'notehead' or 'circle'.
    this.size = options.size || "medium"; // One of 'large', 'medium', 'small' or 'very small'.

  }

  /*
  getEventCenterXY() {
    var xBegin = this.x;
    var xEnd = xBegin + this._vfnotehead.getGlyph().head_width;
    return {
      x: xBegin + ((xEnd-xBegin)/2),
      y: this.y
    };
  }
  */

  getEventCenterXY() {
    var x = this.x;
    if (this.type==="notehead") {
      var xBegin = this.x;
      var xEnd = xBegin + this._vfnotehead.getGlyph().head_width;
      x = xBegin + ((xEnd-xBegin)/2);
    }
    return {
      x: x,
      y: this.y
    };
  }

  /*
  render() {
    var ctx = this.getRenderContext();
    var x = this.x;
    var y = this.y;
    if (this.type==="notehead") {
      this._vfnotehead = new Vex.Flow.NoteHead({
        duration: this.duration.toString(),
      });
      this._vfnotehead.setX(x);
      this._vfnotehead.setY(y);
      this._vfnotehead.setContext(ctx).draw();
    }
    else if (this.type==="circle") {
      var radius;
      switch (this.size) {
        case "large":
        radius = 16;
        break;
        case "medium":
        radius = 10;
        break;
        case "small":
        radius = 7;
        break;
        case "very small":
        radius = 4;
        break;
      }
      ctx.circle(x, y, radius, {
        fill: "black",
        "stroke-width": 1,
        stroke: "black"
      });
    }
    return this;
  }
  */

  render() {
    var ctx = this.getRenderContext();
    var { x, y, type, duration, size } = this;
    if (type==="notehead") {
      this._vfnotehead = new Vex.Flow.NoteHead({
        duration: duration.toString(),
      });
      this._vfnotehead.setX(x);
      this._vfnotehead.setY(y);
      this._vfnotehead.setContext(ctx).draw();
    }
    else if (type==="circle") {
      const radius = circleSizes[size];
      ctx.circle(x, y, radius, {
        fill: "black",
        "stroke-width": 1,
        stroke: "black"
      });
    }
    return this;
  }

  // Possible idea for allowing the transcription init to be removed
  // completely (only AO has it now, and it has special cell rendering
  // code, for horizontal and vertical lines in the grid... something
  // like this could allow it to be removed).
  /*
  render() {
    const ctx = this.getRenderContext();
    const { x, y, type, height, duration, size, referenceFrame } = this;
    const { x: frameX, y: frameY, width: frameWidth, height: frameHeight } = referenceFrame;
    const eventX = frameX + (frameWidth * x);
    const eventY = frameY + (frameHeight * y);
    switch (type) {
      case "notehead":
        this._vfnotehead = new Vex.Flow.NoteHead({
          duration: duration.toString(),
        });
        this._vfnotehead.setX(eventX);
        this._vfnotehead.setY(eventY);
        this._vfnotehead.setContext(ctx).draw();
        break;
      case "circle":
        const radius = circleSizes[size];
        ctx.circle(eventX, eventY, radius, {
          fill: "black",
          "stroke-width": 1,
          stroke: "black"
        });
        break;
      case "horizontalLine":
        let eventWidth = duration * frameWidth;
        ctx.line(eventX, eventY, eventX + eventWidth, eventY);
        break;
      case "verticalLine":
        let eventHeight = height * frameHeight;
        ctx.line(eventX, eventY-eventHeight, eventX, eventY);
    }
    return this;
  }
  */
  

};
