import { Utils } from '../utils'
import { Graphics } from './graphics';

// Score segmentation class.

// Segment.

class Segment {

	constructor(options) {
		this.start = options.start;
		this.end = options.end;
		this.offset = options.offset || 0;
		this.segmentation = options.segmentation;
		this.items = [];
		this.colour = options.colour;
	}

	add(item) {
		this.items.push(item);
		return this;
	}

	getItems() {
		return this.items;
	}

	numItems() {
		return this.getItems().length;
	}

	getDuration() {
		return this.end-this.start;
	}

	getTrueDuration() {
		var start = this.start;
		var lastNoteOnset = this.getLatestNote().getOnset();
		var lastNoteDuration = parseInt(this.getLatestNote().getDuration());
		return lastNoteOnset+lastNoteDuration-start
	}

	getOffset() {
		return this.offset;
	}

	setOffset() {
		this.offset = this.getEarliestNote().getOnset()-this.start;
	}

	getOnsets(options) {
		var options = options || {relative: false};
		var start = options.relative ? this.start : 0;
		var onsets = this.items.map(function(note) {
			return note.getOnset()-start;
		});
		return onsets;
	}

	getStartX() {
		var score = this.segmentation.score;
		return score.xForTimePoint(this.start);
	}

	getEndX() {
		var score = this.segmentation.score;
		return score.xForTimePoint(this.end);
	}

	getWidth() {
		var startX = this.getStartX();
		var endX = this.getEndX();
		return endX-startX;
	}

	sort() {
		var items =  this.getItems();
		items.sort(function(a, b) {
			return a.getOnset()-b.getOnset();
		});
		return this;
	}

	getEarliestNote() {
		var items =  this.getItems();
		var earliestNote = items[0];
		for (var i=0; i<items.length; i++) {
			var currNote = items[i];
			if (currNote.getOnset()<earliestNote.getOnset()) {
				earliestNote = currNote;
			}
		}
		return earliestNote;
	}

	getLatestNote() {
		var items =  this.getItems();
		var latestNote = items[items.length-1];
		for (var i=0; i<items.length; i++) {
			var currNote = items[i];
			if (currNote.getOnset()>latestNote.getOnset()) {
				latestNote = currNote;
			}
		}
		return latestNote;
	}
/*
	serialize() {
		var data = this.items.map(function(item) {
			return item.serialize();
		});
		return data;
	}
*/
	serialize(options) {
		var options = Utils.merge({
			trim: false
		}, options, {});
		var data = this.items.map(item => {
			var noteData = item.serialize();
			var end = this.end;
			var duration = parseInt(noteData.duration);
			var onset = item.getOnset();
			if (options.trim && onset+duration>end) {
				noteData.duration = (end-onset).toString();
			}
			return noteData;
		});
		return data;
	}

	render() {
		var colour = this.colour;
		this.items.forEach(function(note) {
			var elem = $(note.getElem());
			Graphics.setElemColor(elem, colour);
		});
		return this;
	}

}

// Segmentation.

export default class Segmentation {

	constructor(options) {
		this.score = options.score;
		this.segmentationPoints = options.segmentationPoints || [];
		this.segments = [];
	}

	add(segment) {
		this.segments.push(segment);
		return this;
	}

	getSegments() {
		return this.segments;
	}

	mergeSegments() {
		var segments = this.getSegments();
		var currTime = 0;
		var merged = [];
		segments.forEach(function(seg) {
			var onsetArray = seg.getItems().map(function(note) {
				var onset = (note.getOnset()-seg.start)+currTime;
				var noteData = [onset, note.serialize()];
				if (note.__data__.isRepeatNote) noteData[1].isRepeatNote=true;
				merged.push(noteData);
			});
			currTime += seg.getDuration(); //seg.end;
		});
		return merged;
	}

	sort() {
		this.getSegments().forEach(function(seg) {
			seg.sort();
		})
		return this;
	}

	getSegment(index) {
		return this.segments[index];
	}

	numSegments() {
		return this.getSegments().length;
	}

	getSegmentationPoints() {
		return this.segmentationPoints;
	}

	getTotalDuration() {
		var totalDur = 0;
		this.getSegments().forEach(function(seg) {
			totalDur = seg.getDuration();
		});
		return totalDur;
	}

	_getNotesBetween(voice, start, end, fn) {
		if (start>=end) {
			throw new Error("Start must be greater than end.");
		}
		var fn = fn || Utils.identity; // Callback defaults to identity.
		var notes = voice.getNotes();
		var notesBetween = [];
		for (var i=0; i<notes.length; i++) {
			var currNote = notes[i];
			var noteOnset = currNote.getOnset();
			if (noteOnset>=start && noteOnset<=end && fn(currNote)) {
				notesBetween.push(currNote);
			}
			else if (noteOnset>end) {
				break;
			}
		}
		return notesBetween;
	}

	build() {
		var segPoints = this.getSegmentationPoints();
		var segments = this.getSegments();
		segPoints.forEach((pair, segIndex) => {
			var start = pair[0];
			var end = pair[1];
			var seg = new Segment({
				start: start,
				end: end,
				duration: end-start,
				segmentation: this,
				colour: segIndex%2==0 ? "green" : "red" // If our segment index is odd colour the notes green, otherwise red.
			});
			this.add(seg);
			this.score.getVoices().forEach(voice => {
				var notes = this._getNotesBetween(voice, start, end); //, fn);
				notes.forEach(note => {
					seg.add(note);
				});
			});
			if (seg.numItems()>0) {
				seg.setOffset();
			}
		});
		return this;
	}

	serialize() {
		var data = this.segments.map(function(seg) {
			return seg.serialize();
		});
		return data;
	}

	render() {
		this.segments.forEach(function(segment) {
			segment.render();
		});
		return this;
	}

}
