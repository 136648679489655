/*
Display Bar Component.
*/

import React from "react";
import { useSelector } from "react-redux";


const TitlePanel = (props) => {
  let { title } = props;
  let style = {fontSize: ".95rem", color: "#a1a1a1", pointerEvents: "auto"};
  return (
    <div id="title" className="panel panel-default static-text-box" style={ style }>{ title }</div>
  );
}

export default function DisplayBar(props) {
  let { top } = props;
  let { title, displayMode, currentSelection } = useSelector(state => ({
    title: state.realisation.title,
    displayMode: state.realisation.displayMode,
    currentSelection: state.realisation.currentSelection
  }));
  let { pitch, onset } = currentSelection || {};
  displayMode==="Performance" && pitch && (pitch = pitch+",");
  let style = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between", //"start",
    position: "absolute",
    top: top+"px",
    left: "0",
    width: "100%",
    background: "transparent",
    pointerEvents: "none",
    marginTop: "0px",
    marginLeft: "0px",
    padding: "0px 4px 0px 4px"
  };
  return (
    <div style={ style } >
      <TitlePanel title={ title }/>
      {
        pitch &&
        <div id="curr-selection-info" className="ml-auto" style={ { fontSize: ".95rem", pointerEvents: "auto" } }>
          <span id="curr-selection-pitch" className="panel panel-default static-text-box" style={ {color: "#a1a1a1"} }>{ "Pitch: "+pitch }</span>
          { displayMode==="Performance" && <span id="curr-selection-onset" className="panel panel-default static-text-box" style={ {color: "#a1a1a1"} }>{ "Onset: "+onset }</span> }
        </div>
      }
    </div>
  );
}
