// This is an implementation of the memento pattern, which we should be
// able to use to store the original 'analysis' version of the data, which
// can then be restored. Or just use Derick Bailey's Backbone.Memento.
// For more on the memento pattern in Javascript see 'Pro JavaScript Development',
// page 181-3, and http://www.dofactory.com/javascript/memento-design-pattern.
const Memento = (function() {

  function Memento() {
    this.storage = {};
  }

  Memento.prototype.store = function(key, obj) {
    this.storage[key] = JSON.stringify(obj);
  };

  // Probably don't need this.
  Memento.prototype.get = function(key) {
    return this.storage[key];
  };

  Memento.prototype.restore = function(key) {
    var output = {};
    if (this.storage.hasOwnProperty(key)) {
      output = JSON.parse(this.storage[key]);
    }
    return output;
  };

  return Memento;

}());

export { Memento };
