/**
 * Class representing a musical pitch. A pitch is an object defined by its class
 * (as in 'pitch class') and octave. A pitch can be created from a string using the
 * static 'fromString' method, converted to a string with 'toString', and converted
 * to a MIDI key value with 'toMidi'.
 * @class CC.Core.Pitch
 */
export default class Pitch {

  constructor(options) {
    this.class = options.class;
    this.octave = options.octave;
  }

  getClass() {
    return this.class;
  }

  getOctave() {
    return this.octave;
  }

  getEnharmonicEquivalents() {}

  /**
   * Converts the pitch object to its string representation, for example "A#/4".
   * @returns {string} The pitch in string format.
   */
  toString() {
    return this.getClass()+"/"+this.getOctave();
  }

  /**
   * Static method for creating a pitch object from a string representation.
   * @static
   * @param {string} string - String to convert to a pitch object.
   * @returns {Object.<CC.Core.Pitch>} A CC.Core.Pitch object.
   */
  static fromString(string) {
    var [ pc, octave ] = string.split("/");
    return new Pitch({
      class: pc,
      octave: octave
    })
  }

  /**
   * Converts the pitch object to its MIDI key representation.
   * @returns {number} The pitch as a MIDI key value.
   */
  toMidi() {
    var noteString = this.getClass().toUpperCase();
    var octave = parseInt(this.getOctave());
    var intVal = Vex.Flow.keyProperties.note_values[noteString].int_val;
    return octave*12+12+intVal;
  }

  // This should be a static method.
  getInterval(from, to) {}

}
