import React from "react";
import { toast, ToastContainer, Slide } from "react-toastify";
import { Utils } from "../../utils";


const showToast = (function() {
  let id = Utils.guid();
  return (type, msg) => {
    toast(msg, {
      type: type,
      toastId: id,
      hideProgressBar: true,
      transition: Slide,
      className: "shadow-6"
    })
  }
}());

export const Notify = () => {}

Notify.prototype.warn = (msg) => showToast("warning", msg);
Notify.prototype.success = (msg) => showToast("success", msg);
Notify.prototype.error = (msg) => showToast("error", msg);

const Toast = () => {
  return (
    <ToastContainer></ToastContainer>
  );
}

export default Toast;
